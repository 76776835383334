import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Navbar } from "reactstrap";
import { Menu } from "@mui/material";
import {
  BarChart,
  List,
  PlaylistAdd,
  PersonAdd,
  ViewColumn,
  ArrowDownward,
} from "@mui/icons-material";
import { VariablesContext } from "./chosenVariablesContext";

const NavBar = ({
  authLevel,
  setShowEntryForm,
  setShowDriverForm,
  setShowUploadForm,
}) => {
  const { chosenVariables, handleViewSelect } = useContext(VariablesContext);
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickListItem = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowUpload = () => {
    handleClose();
    setShowUploadForm(true);
  };

  const exitEvent = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div>
      {authLevel && (
        <>
          {authLevel === "client" ? (
            <>
              <span className="no_hover_header thin_text_reduce">
                Transpo Tracker
              </span>
              <span className="no_hover_header desktop_text">
                Event Transpo Tracker
              </span>
            </>
          ) : (
            <>
              {chosenVariables.event_id ? (
                <>
                  {location.pathname === "/graph" ||
                  location.pathname === "/log" ? (
                    <div>
                      <span
                        onClick={(e) => handleViewSelect(e)}
                        id="manager"
                        className="desktop_text nav_header"
                      >
                        Switch to Manager
                      </span>
                      <span
                        onClick={(e) => handleViewSelect(e)}
                        id="manager"
                        className="thin_text_reduce no_hover_header"
                      >
                        To Manager
                      </span>
                    </div>
                  ) : (
                    <div>
                      <span
                        onClick={(e) => handleViewSelect(e)}
                        id="tracker"
                        className="desktop_text nav_header"
                      >
                        Switch to Tracker
                      </span>
                      <span
                        onClick={(e) => handleViewSelect(e)}
                        id="tracker"
                        className="thin_text_reduce no_hover_header"
                      >
                        To Tracker
                      </span>
                    </div>
                  )}
                </>
              ) : (
                <span className="no_hover_header">Event Transpo Manager</span>
              )}
            </>
          )}
          <Navbar className="navbar_icons">
            {location.pathname === "/graph" || location.pathname === "/log" ? (
              <>
                <span className="nav_icon">
                  <Link to="log">
                    <List
                      style={{ fontSize: 30 }}
                      color="action"
                      className="navbar_icon"
                    />
                  </Link>
                </span>
                <span className="nav_icon">
                  <Link to="graph">
                    <BarChart
                      style={{ fontSize: 30 }}
                      color="action"
                      className="navbar_icon"
                    />
                  </Link>
                </span>
                {authLevel !== "client" && (
                  <span className="nav_icon">
                    <PlaylistAdd
                      style={{ fontSize: 30 }}
                      color="action"
                      className="navbar_icon"
                      onClick={() => setShowEntryForm(true)}
                    />
                  </span>
                )}
              </>
            ) : (
              <>
                <span className="nav_icon">
                  <Link to="assignment-list">
                    <List
                      style={{ fontSize: 30 }}
                      color="action"
                      className="navbar_icon"
                    />
                  </Link>
                </span>
                <span className="nav_icon">
                  <Link to="route-view">
                    <ViewColumn
                      style={{ fontSize: 30, marginBottom: -1 }}
                      color="action"
                      className="navbar_icon"
                    />
                  </Link>
                </span>
                {authLevel !== "staff" && (
                  <span className="nav_icon">
                    <PersonAdd
                      style={{ fontSize: 30 }}
                      color="action"
                      className="navbar_icon"
                      onClick={() => setShowDriverForm(true)}
                    />
                  </span>
                )}
              </>
            )}
            <span className="nav_icon">
              <ArrowDownward
                style={{ fontSize: 30 }}
                color="action"
                onClick={handleClickListItem}
                className="navbar_icon"
              />
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <div>
                  {authLevel === "superuser" && (
                    <Link
                      to="events"
                      onClick={handleClose}
                      className="menu_link"
                    >
                      Events
                    </Link>
                  )}
                  {(authLevel === "admin" || authLevel === "superuser") && (
                    <>
                      <Link
                        to="dates&shuttles"
                        onClick={handleClose}
                        className="menu_link"
                      >
                        Dates & Shuttles
                      </Link>
                      <Link
                        to="routes"
                        onClick={handleClose}
                        className="menu_link"
                      >
                        Routes
                      </Link>
                      <Link
                        to="locations"
                        onClick={handleClose}
                        className="menu_link"
                      >
                        Locations
                      </Link>
                      {(location.pathname === "/route-view" ||
                        location.pathname === "/assignment-list") && (
                        <Link
                          to="#"
                          onClick={handleShowUpload}
                          className="menu_link"
                        >
                          Upload
                        </Link>
                      )}
                    </>
                  )}
                  <Link to="" onClick={exitEvent} className="menu_link">
                    Exit Event
                  </Link>
                </div>
              </Menu>
            </span>
          </Navbar>
        </>
      )}
    </div>
  );
};

export default NavBar;
