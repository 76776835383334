import React, { useState, useContext, useEffect } from "react";
import { VariablesContext } from "../chosenVariablesContext";
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Select,
} from "@mui/material";
import { Close, DeleteOutlined } from "@mui/icons-material";
import useApi from "../api/api";
import { NotificationManager } from "react-notifications";
import ConfirmDelete from "../hooks/confirmDelete";
import ConfirmCancel from "../hooks/confirmCancel";

const ManagePlaces = () => {
  const api = useApi();

  const { chosenVariables, getPlaces, routes, places } =
    useContext(VariablesContext);

  const [alterItemsObj, setAlterItemsObj] = useState({});

  const {
    deleteModal,
    objectToDelete,
    setObjectToDelete,
    handleShowDeleteModal,
  } = ConfirmDelete();

  const confirmActions = () => {
    setAlterItemsObj({});
  };

  const {
    cancelModal,
    showCancelModal,
    setShowCancelModal,
    handleShowCancelModal,
  } = ConfirmCancel(confirmActions);

  const [place, setPlace] = useState({
    name: "",
    event_id: chosenVariables.event_id,
  });

  const [showForm, setShowForm] = useState(false);
  const [chosenRoute, setChosenRoute] = useState("");

  const handleCloseForm = () => {
    setShowForm(false);
    setPlace({ name: "", event_id: chosenVariables.event_id });
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        handleCloseForm();
        setObjectToDelete(false);
        setShowCancelModal(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  });

  const handleRouteSelect = ({ target: { value } }) => {
    setChosenRoute(value);
  };

  place.route_id = chosenRoute;

  const handleNewPlaceChange = ({ target: { id, value } }) => {
    setPlace({
      ...place,
      [id]: value,
    });
  };

  const handlePlacesUpdate = ({ target: { id, value } }, place) => {
    setAlterItemsObj({
      ...alterItemsObj,
      [place.id]: {
        ...place,
        [id]: value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post("places", place);
      NotificationManager.success(
        `${place.name} successfully added.`,
        "Success",
        1000
      );
      document.getElementById("name").value = "";
      document.getElementById("name").select();
      setPlace({
        name: "",
        event_id: chosenVariables.event_id,
      });
      getPlaces();
    } catch (error) {
      if (error?.response?.data?.errors?.name) {
        NotificationManager.error(
          `${place.name} already exists on this route.`,
          "Error",
          5000
        );
      } else {
        NotificationManager.error(
          "Something went wrong. Location not added.",
          "Error",
          5000
        );
      }
    }
  };

  const handleUpdateItems = (e) => {
    e.preventDefault();
    Promise.all(
      Object.values(alterItemsObj).map(async (place) => {
        try {
          await api.put(`places/${place.id}`, { place });
          NotificationManager.success(
            "Location successfully updated.",
            "Success",
            1000
          );
        } catch (error) {
          if (error.response.data.errors.name) {
            NotificationManager.error(
              `There is already a location named ${place.name} on Route ${place.route.name} ${place.route.description}.`,
              "Failure",
              5000
            );
          } else if (error.response.data.errors.route) {
            NotificationManager.error(
              `${place.name} was not assigned to a route. All locations must be assigned to a route`,
              "Failure",
              5000
            );
          } else {
            NotificationManager.error(
              `Something went wrong. ${place.name} not updated.`,
              "Error",
              5000
            );
          }
        } finally {
          getPlaces();
          setAlterItemsObj({});
        }
      })
    );
  };

  const form = () => {
    return (
      <div
        className="modal"
        style={
          routes.find((one) => one.id === place?.route_id)?.color && {
            borderColor: routes.find((one) => one.id === place?.route_id)
              ?.color,
          }
        }
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">Add New Location</Typography>
          <div>
            <Close
              className="route_icon"
              onClick={handleCloseForm}
              style={{ marginTop: "0", color: "black" }}
            />
          </div>
        </div>
        <form className="drop_downs" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputLabel>Name:</InputLabel>
              <TextField
                variant="standard"
                id="name"
                fullWidth
                required
                onChange={handleNewPlaceChange}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Route:</InputLabel>
              <Select
                variant="standard"
                id="id"
                native
                onChange={handleRouteSelect}
                fullWidth
                required
                value={chosenRoute}
              >
                <option aria-label="None" value="">
                  Choose Route
                </option>
                {routes &&
                  routes.map((route) => (
                    <option key={route.id} value={route.id}>
                      {route.name} {route.description}
                    </option>
                  ))}
              </Select>
            </Grid>
          </Grid>
          <div className="submit_button" style={{ textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!place.name || !chosenRoute}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      {(showForm || objectToDelete || showCancelModal) && (
        <div className="overlay"></div>
      )}
      {showForm && form()}
      {objectToDelete && deleteModal(objectToDelete, "Location", "places")}
      {showCancelModal && cancelModal()}
      <div
        className="skinny_stack"
        style={{
          justifyContent: "space-between",
          marginTop: "1.5rem",
        }}
      >
        <Typography variant="h5">Manage Locations</Typography>
        {Object.keys(alterItemsObj).length === 0 ? (
          <span style={{ display: "flex" }} className="skinny_stack">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowForm(true)}
              style={{ height: 38, whiteSpace: "nowrap", marginLeft: 5 }}
            >
              Add Location
            </Button>
          </span>
        ) : (
          <span style={{ display: "flex" }} className="skinny_stack">
            <Button
              color="secondary"
              variant="contained"
              onClick={handleShowCancelModal}
              style={{ margin: "0 5px", height: 38 }}
            >
              Discard
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleUpdateItems}
              style={{ margin: "0 0 0 5px", height: 38 }}
            >
              Update
            </Button>
          </span>
        )}
      </div>
      <Typography style={{ margin: "15px 0 10px 0" }} variant="h6">
        {places.length} locations
      </Typography>
      <Table size="small" style={{ overflowX: "scroll" }}>
        <TableBody>
          {places.map((place) => {
            const placeToRender = alterItemsObj[place.id] || place;
            return (
              <TableRow
                key={placeToRender.id}
                style={
                  alterItemsObj[placeToRender.id] && {
                    backgroundColor: "lightgray",
                  }
                }
              >
                <TableCell>
                  <TextField
                    variant="standard"
                    id="name"
                    required
                    fullWidth
                    onChange={(e) => handlePlacesUpdate(e, placeToRender)}
                    inputProps={{ style: { fontSize: 14 } }}
                    value={placeToRender.name}
                  />
                </TableCell>
                <TableCell>
                  {
                    <Select
                      variant="standard"
                      id="route_id"
                      native
                      onChange={(e) => handlePlacesUpdate(e, placeToRender)}
                      fullWidth
                      required
                      value={placeToRender?.route_id}
                      style={
                        routes.find((one) => one.id === placeToRender?.route_id)
                          ?.color
                          ? {
                              color: routes.find(
                                (one) => one.id === placeToRender?.route_id
                              )?.color,
                              fontWeight: "900",
                              fontSize: 14,
                            }
                          : {
                              color: "lightgray",
                              fontSize: 14,
                            }
                      }
                    >
                      {routes &&
                        routes.map((route) => (
                          <option key={route.id} value={route.id}>
                            {route.name} {route.description}
                          </option>
                        ))}
                    </Select>
                  }
                </TableCell>
                <TableCell
                  style={{
                    display: "flex",
                    height: 32,
                    justifyContent: "center",
                  }}
                >
                  <span className="action_icon">
                    <DeleteOutlined
                      onClick={() => handleShowDeleteModal(place)}
                      style={{ color: "black" }}
                    />
                  </span>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default ManagePlaces;
