import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

const ConfirmCancel = (confirmActions) => {
  const [showCancelModal, setShowCancelModal] = useState(false);

  const cancel = () => {
    confirmActions();
    setShowCancelModal(false);
  };

  const handleShowCancelModal = () => {
    setShowCancelModal(true);
  };

  const cancelModal = () => {
    return (
      <div className="modal">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">Discard Changes</Typography>
          <div>
            <Close
              className="route_icon"
              onClick={() => setShowCancelModal(false)}
              style={{ marginTop: "0", color: "black" }}
            />
          </div>
        </div>
        <p style={{ textAlign: "center" }}>
          Are you sure you want to discard changes?
        </p>
        <div className="submit_button" style={{ textAlign: "center" }}>
          <Button color="secondary" variant="contained" onClick={cancel}>
            Confirm
          </Button>
        </div>
      </div>
    );
  };

  return {
    cancelModal,
    showCancelModal,
    setShowCancelModal,
    handleShowCancelModal,
  };
};

export default ConfirmCancel;
