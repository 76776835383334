import React, { useState, useContext, useEffect } from "react";
import { VariablesContext } from "../chosenVariablesContext";
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { DeleteOutlined, Close } from "@mui/icons-material";
import { FaRegEdit } from "react-icons/fa";
import useApi from "../api/api";
import { NotificationManager } from "react-notifications";
import EntryEditForm from "../forms/entryEditForm";
import AssignmentDisplay from "../tracker/assignmentDisplay";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const Log = ({ authLevel }) => {
  const api = useApi();
  const {
    chosenVariables,
    dateSelect,
    shuttleSelect,
    routeSelect,
    placeSelect,
    entries,
    getEntries,
    isLoading,
  } = useContext(VariablesContext);

  const [searchField, setSearchField] = useState("");
  const [entryToEdit, setEntryToEdit] = useState(false);
  const [showEntryEditForm, setShowEntryEditForm] = useState(false);
  const [showAssignmentDisplay, setShowAssignmentDisplay] = useState(false);
  const [objectToDelete, setObjectToDelete] = useState(false);

  useEffect(() => {
    const handleEsc = (event) => {
      if (
        event.keyCode === 27 &&
        (showAssignmentDisplay || showEntryEditForm || objectToDelete)
      ) {
        setShowEntryEditForm(false);
        setShowAssignmentDisplay(false);
        setObjectToDelete(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  });

  const handleShowEntryEditForm = (entry) => {
    setShowEntryEditForm(true);
    setEntryToEdit({
      ...entry,
      time: entry.time.slice(11, 16),
    });
  };

  const handleShowAssignmentDisplay = (entry) => {
    setShowAssignmentDisplay(true);
    setEntryToEdit({
      ...entry,
    });
  };

  const handleShowDeleteModal = (entry) => {
    setObjectToDelete(true);
    setEntryToEdit({
      ...entry,
    });
  };

  const handleSearchChange = ({ target: { value } }) => {
    setSearchField(value);
  };

  const filteredEntries = entries
    .filter((a) => a.shuttle_id.includes(chosenVariables.shuttle_id))
    .filter((b) => b.place_id.includes(chosenVariables.place_id))
    .filter((c) => c.route.id.includes(chosenVariables.route_id))
    .filter(
      (e) =>
        e.vehicle?.number.includes(searchField) ||
        e.time.slice(11, 16).includes(searchField) ||
        e.attendee_count.toString().includes(searchField)
    );

  const [sortField, setSortField] = useState(null);

  if (sortField === "time") {
    filteredEntries.sort((a, b) => a.time.localeCompare(b.time));
  } else if (sortField === "vehicle") {
    filteredEntries.sort((a, b) =>
      a.vehicle?.number.localeCompare(b.vehicle?.number)
    );
  } else if (sortField === "shuttle") {
    filteredEntries.sort((a, b) =>
      a.shuttle.name.localeCompare(b.shuttle.name)
    );
  } else if (sortField === "route") {
    filteredEntries.sort((a, b) => a.route.name.localeCompare(b.route.name));
  } else if (sortField === "location") {
    filteredEntries.sort((a, b) => a.place.name.localeCompare(b.place.name));
  }

  let totalAttendeeCount = 0;
  if (filteredEntries.length !== 0) {
    totalAttendeeCount = filteredEntries
      .map((entry) => entry.attendee_count)
      .reduce((a, b) => a + b);
  }

  const deleteEntry = async (id) => {
    try {
      await api.delete(`entries/${id}`);
      NotificationManager.success(
        "Entry successfully deleted.",
        "Success",
        1000
      );
      setObjectToDelete(false);
      getEntries();
    } catch {
      NotificationManager.error(
        "Something went wrong. Entry not deleted.",
        "Error",
        5000
      );
    }
  };

  const sortableHeader = (type) => {
    return (
      <TableCell
        className="action_icon"
        style={
          sortField === type
            ? { fontWeight: 600, backgroundColor: "lightgray" }
            : { fontWeight: 600 }
        }
        onClick={() => setSortField(type)}
      >
        {type.charAt(0).toUpperCase() + type.slice(1)}
      </TableCell>
    );
  };

  const handleClose = () => {
    setObjectToDelete(false);
  };

  const deleteModal = () => {
    return (
      <div
        className="modal"
        style={{
          borderColor: entryToEdit.route?.color,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">Delete Entry</Typography>
          <div>
            <Close
              className="route_icon"
              onClick={() => handleClose(entryToEdit)}
              style={{ marginTop: "0", color: "black" }}
            />
          </div>
        </div>
        <p style={{ textAlign: "center" }}>
          Are you sure you want to delete this entry?
        </p>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div style={{ fontWeight: "bold" }}>
            <p>Time:</p>
            <p>Vehicle:</p>
            <p>Pax:</p>
            <p>Shuttle:</p>
            <p>Route:</p>
            <p>Location:</p>
          </div>
          <div>
            <p>{entryToEdit.formatted_time}</p>
            {entryToEdit.assignment?.vehicle?.number ? (
              <p>{entryToEdit.assignment?.vehicle?.number}</p>
            ) : (
              <p>N/A</p>
            )}
            <p>{entryToEdit.attendee_count}</p>
            <p>{entryToEdit.shuttle.name}</p>
            <p>{entryToEdit.route.name}</p>
            <p>{entryToEdit.place.name}</p>
          </div>
        </div>
        <div className="submit_button" style={{ textAlign: "center" }}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => deleteEntry(entryToEdit.id)}
          >
            Delete
          </Button>
        </div>
      </div>
    );
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const csvExport = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  const entriesToExport = filteredEntries.map((entry) => {
    if (entry.vehicle?.number) {
      return {
        Time: entry.formatted_time,
        Vehicle: `${entry.vehicle.company} ${entry.vehicle.number}`,
        Pax: entry.attendee_count,
        Shuttle: entry.shuttle.name,
        Route: entry.route.name,
        Location: entry.place.name,
      };
    } else {
      return {
        Time: entry.formatted_time,
        Vehicle: null,
        Pax: entry.attendee_count,
        Shuttle: entry.shuttle.name,
        Route: entry.route.name,
        Location: entry.place.name,
      };
    }
  });

  const totals = {
    Time: null,
    Vehicle: null,
    Pax: totalAttendeeCount,
    Shuttle: null,
    Route: null,
    Location: null,
  };

  const fullExport = entriesToExport.concat(totals);

  return (
    <div>
      {authLevel !== "client" && (
        <>
          {showEntryEditForm && (
            <EntryEditForm
              entry={entryToEdit}
              setEntryToEdit={setEntryToEdit}
              setShowEntryEditForm={setShowEntryEditForm}
            />
          )}
          {showAssignmentDisplay && (
            <AssignmentDisplay
              entry={entryToEdit}
              setShowAssignmentDisplay={setShowAssignmentDisplay}
              authLevel={authLevel}
            />
          )}
          {objectToDelete && deleteModal(objectToDelete)}
          {(showEntryEditForm || showAssignmentDisplay || objectToDelete) && (
            <div className="overlay"></div>
          )}
        </>
      )}
      <Typography variant="h5">Ridership Logs</Typography>
      <div className="drop_downs">
        <Grid container spacing={3}>
          {dateSelect(true)}
          {shuttleSelect(true)}
          {routeSelect(true)}
          {placeSelect(true)}
          <Grid item xs={12} md={3} style={{ marginBottom: "1rem" }}>
            <InputLabel>Search (vehicle/time/pax):</InputLabel>
            <TextField
              variant="standard"
              type="text"
              fullWidth
              onChange={handleSearchChange}
              value={searchField}
            />
          </Grid>
        </Grid>
      </div>
      {filteredEntries.length > 0 && !isLoading ? (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">
              {totalAttendeeCount}{" "}
              {parseInt(totalAttendeeCount) > 1 ? (
                <>attendees</>
              ) : (
                <>attendee</>
              )}{" "}
              moved in {filteredEntries.length}{" "}
              {filteredEntries.length > 1 ? <>trips</> : <>trip</>}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                csvExport(
                  fullExport,
                  `${chosenVariables.event_date_name}_entries`
                )
              }
              style={{ margin: "-2px 0 10px 10px", height: "36.5px" }}
            >
              Export<span className="no_mobile_text">&nbsp;CSV</span>
            </Button>
          </div>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {sortableHeader("time")}
                  {authLevel !== "client" && <>{sortableHeader("vehicle")}</>}
                  <TableCell style={{ fontWeight: 600 }}>Pax</TableCell>
                  {chosenVariables.shuttle_id === "" && (
                    <>{sortableHeader("shuttle")}</>
                  )}
                  {chosenVariables.route_id === "" && (
                    <>{sortableHeader("route")}</>
                  )}
                  {chosenVariables.place_id === "" && (
                    <>{sortableHeader("location")}</>
                  )}
                  {(authLevel === "superuser" ||
                    authLevel === "admin" ||
                    authLevel === "supervisor") && (
                    <TableCell style={{ fontWeight: 600 }}>Actions</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredEntries.map((entry) => {
                  return (
                    <TableRow key={entry.id} id={entry.id}>
                      <TableCell>{entry.formatted_time}</TableCell>
                      {authLevel !== "client" && (
                        <>
                          {entry?.vehicle?.number ? (
                            <TableCell
                              style={{
                                textDecoration: "underline",
                                color: "blue",
                              }}
                              className="action_icon"
                              onClick={() => handleShowAssignmentDisplay(entry)}
                            >
                              {entry.vehicle.number}
                            </TableCell>
                          ) : (
                            <TableCell></TableCell>
                          )}
                        </>
                      )}
                      <TableCell>{entry.attendee_count}</TableCell>
                      {chosenVariables.shuttle_id === "" && (
                        <TableCell>{entry.shuttle.name}</TableCell>
                      )}
                      {chosenVariables.route_id === "" && (
                        <>
                          {parseInt(entry.route.name) < 10 ? (
                            <TableCell>{entry.route.name.slice(-1)}</TableCell>
                          ) : (
                            <TableCell>{entry.route.name}</TableCell>
                          )}
                        </>
                      )}
                      {chosenVariables.place_id === "" && (
                        <TableCell>{entry.place.name}</TableCell>
                      )}
                      {(authLevel === "superuser" ||
                        authLevel === "admin" ||
                        authLevel === "supervisor") && (
                        <TableCell>
                          <span style={{ display: "flex" }}>
                            <FaRegEdit
                              onClick={() => handleShowEntryEditForm(entry)}
                              style={{
                                fontSize: 20,
                                paddingBottom: 2,
                              }}
                              className="action_icon"
                            />
                            <DeleteOutlined
                              className="action_icon"
                              onClick={() => handleShowDeleteModal(entry)}
                              style={{ color: "black" }}
                            />
                          </span>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <div align="center" style={{ fontSize: 20, marginTop: "32px" }}>
          {isLoading ? (
            <div style={{ color: "green" }}>Results are loading...</div>
          ) : (
            <div style={{ color: "red" }}>
              There is no ridership data matching the applied filters
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Log;
