import React, { useState, useContext, useEffect } from "react";
import { VariablesContext } from "../chosenVariablesContext";
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Typography,
  Checkbox,
  Select,
  FormControlLabel,
  checkboxClasses,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useApi from "../api/api";
import { NotificationManager } from "react-notifications";
import { Close } from "@mui/icons-material";

const EntryForm = ({ setShowEntryForm }) => {
  const api = useApi();
  const theme = createTheme();

  const {
    chosenVariables,
    setVariables,
    handleRouteSelect,
    handleEntryTimeChange,
    dates,
    places,
    shuttleDates,
    shuttleRoutes,
    getEntries,
  } = useContext(VariablesContext);

  const [assignments, setAssignments] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const today = dates.find(
    (each) => each.date === new Date().toLocaleDateString("en-CA")
  );

  useEffect(() => {
    if (today === undefined) {
      setIsChecked(true);
    }
  }, [today]);

  let date = "";
  let time = "";
  if (isChecked) {
    date = chosenVariables.event_date_id;
    time = chosenVariables.entry_time;
  } else if (!isChecked && today) {
    date = today.id;
    time = new Date().toTimeString().slice(0, 5);
  }

  let availableShuttles = [];

  if (date) {
    availableShuttles = shuttleDates
      .filter(({ event_date_id }) => event_date_id === date)
      .map((each) => each.shuttle);
  }

  const [entry, setEntry] = useState({
    attendee_count: "",
    assignment_id: "",
    event_date_id: date,
    time: new Date().toTimeString().slice(0, 5),
    event_id: chosenVariables.event_id,
  });

  entry.shuttle_id = chosenVariables.shuttle_id;
  entry.place_id = chosenVariables.place_id;
  entry.event_date_id = date;
  entry.time = time;

  const uniqueRouteIds = [];

  const routesByShuttle = shuttleRoutes
    .filter((each) =>
      shuttleDates
        .filter(({ event_date_id }) => event_date_id === date)
        .find((one) => one.shuttle_id === each.shuttle_id)
    )
    .filter(({ shuttle_id }) => shuttle_id.includes(entry.shuttle_id))
    .map((each) => each.route)
    .filter(({ id }) => {
      const isDuplicate = uniqueRouteIds.includes(id);
      if (!isDuplicate) {
        uniqueRouteIds.push(id);
        return true;
      }
      return false;
    });

  const handleIsCheckedChange = () => {
    if (today !== undefined) {
      setIsChecked(!isChecked);
      if (
        entry.shuttle_id !== "" &&
        !shuttleDates.find(
          (one) =>
            one.event_date_id === chosenVariables.event_date_id &&
            one.shuttle_id === chosenVariables.shuttle_id
        )
      ) {
        setVariables({
          ...chosenVariables,
          shuttle_id: "",
        });
      }
    }
    setEntry({ ...entry, assignment_id: "" });
  };

  const handleEntryChange = ({ target: { id, value } }) => {
    setEntry({
      ...entry,
      [id]: value,
    });
  };

  const handleShuttleSelect = ({ target }) => {
    if (
      target.value !== "" &&
      (chosenVariables.route_id !== "" || chosenVariables.place_id !== "") &&
      !shuttleRoutes.find(
        (one) =>
          one.shuttle_id === target.value &&
          one.route_id === chosenVariables.route_id
      )
    ) {
      setVariables({
        ...chosenVariables,
        shuttle_id: target.value,
        shuttle_name: target.options[target.selectedIndex].dataset.name,
        route_id: "",
        place_id: "",
      });
    } else {
      setVariables({
        ...chosenVariables,
        shuttle_id: target.value,
        shuttle_name: target.options[target.selectedIndex].dataset.name,
      });
    }
    setEntry({ ...entry, assignment_id: "" });
  };

  const handlePlaceSelect = ({ target }) => {
    if (target.value) {
      setVariables({
        ...chosenVariables,
        place_id: target.value,
        route_id: places.find(({ id }) => id === target.value).route_id,
      });
    } else {
      setVariables({
        ...chosenVariables,
        place_id: "",
      });
    }
    setEntry({ ...entry, assignment_id: "" });
  };

  const handleDateSelect = ({ target }) => {
    if (
      chosenVariables.shuttle_id !== "" &&
      !shuttleDates.find(
        (one) =>
          one.event_date_id === target.value &&
          one.shuttle_id === chosenVariables.shuttle_id
      )
    ) {
      setVariables({
        ...chosenVariables,
        event_date_id: target.value,
        event_date_name: target.options[target.selectedIndex].dataset.name,
        shuttle_id: "",
        route_id: "",
        place_id: "",
      });
    } else {
      setVariables({
        ...chosenVariables,
        event_date_id: target.value,
        event_date_name: target.options[target.selectedIndex].dataset.name,
      });
    }
    setEntry({ ...entry, assignment_id: "" });
  };

  useEffect(() => {
    const getAssignments = async () => {
      try {
        const response = await api.get(`assignments?event_date_id=${date}`);
        setAssignments(response.data);
      } catch (error) {
        console.warn("get assignments error", error);
      }
    };
    if (date) {
      getAssignments();
    }
  }, [date, api]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post("entries", entry);
      getEntries();
      document.getElementById("attendee_count").value = "";
      document.getElementById("assignment_id").value = "";
      if (isChecked) {
        document.getElementById("time").value = "";
      }
      setEntry({
        attendee_count: "",
        time: "",
        event_id: chosenVariables.event_id,
      });
      NotificationManager.success(`Entry successfully added.`, "Success", 1000);
    } catch (error) {
      if (error?.response.status === 499) {
        NotificationManager.error(error.response.data.errors, "Error", 5000);
      } else {
        NotificationManager.error(
          "Something went wrong. Entry not added.",
          "Error",
          5000
        );
      }
    }
  };

  return (
    <div
      className="modal"
      style={
        routesByShuttle.find(({ id }) => id === chosenVariables.route_id)
          ? {
              borderColor: routesByShuttle.find(
                ({ id }) => id === chosenVariables.route_id
              ).color,
            }
          : {}
      }
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">Add New Entry</Typography>
        <div>
          <Close
            className="route_icon"
            onClick={() => {
              setShowEntryForm(false);
            }}
            style={{ marginTop: "0", color: "black" }}
          />
        </div>
      </div>
      <form className="drop_downs" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputLabel>Shuttle:</InputLabel>
            <Select
              variant="standard"
              id="shuttle_id"
              native
              onChange={handleShuttleSelect}
              fullWidth
              required
              value={chosenVariables.shuttle_id}
            >
              <option aria-label="None" value="" data-name="">
                Choose Shuttle
              </option>
              {availableShuttles &&
                availableShuttles.map((shuttle) => (
                  <option
                    key={shuttle.id}
                    value={shuttle.id}
                    data-name={shuttle.name}
                  >
                    {shuttle.name}
                  </option>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Route:</InputLabel>
            <Select
              variant="standard"
              id="route_id"
              native
              onChange={handleRouteSelect}
              fullWidth
              value={chosenVariables.route_id}
            >
              <option aria-label="None" value="">
                Choose Route
              </option>
              {routesByShuttle &&
                routesByShuttle.map((route) => (
                  <option key={route.id} value={route.id}>
                    {route.name} {route.description}
                  </option>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Location:</InputLabel>
            <Select
              variant="standard"
              id="place_id"
              native
              onChange={handlePlaceSelect}
              fullWidth
              required
              value={chosenVariables.place_id}
            >
              <option aria-label="None" value="">
                Choose Location
              </option>
              {places &&
                places
                  .filter((each) =>
                    each.route.id.includes(chosenVariables.route_id)
                  )
                  .filter(({ route_id }) =>
                    routesByShuttle.find(({ id }) => id === route_id)
                  )
                  .map((place) => (
                    <option key={place.id} value={place.id}>
                      {place.name}
                    </option>
                  ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Attendee Count:</InputLabel>
            <TextField
              variant="standard"
              required
              type="number"
              id="attendee_count"
              fullWidth
              onChange={handleEntryChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Vehicle (optional):</InputLabel>
            <Select
              variant="standard"
              id="assignment_id"
              native
              onChange={handleEntryChange}
              fullWidth
              value={entry.assignment_id}
            >
              <option aria-label="None" value="">
                Choose Vehicle
              </option>
              {assignments &&
                assignments
                  .filter(
                    ({ shuttle_id }) =>
                      shuttle_id === chosenVariables.shuttle_id
                  )
                  .filter(
                    ({ route_id }) => route_id === chosenVariables.route_id
                  )
                  .sort((a, b) =>
                    a.vehicle.number > b.vehicle.number ? 1 : -1
                  )
                  .sort((a, b) =>
                    a.vehicle.company.localeCompare(b.vehicle.company)
                  )
                  .map((assignment) => (
                    <option key={assignment.id} value={assignment.id}>
                      {assignment.vehicle.company} {assignment.vehicle.number}
                    </option>
                  ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <ThemeProvider theme={theme}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleIsCheckedChange}
                    sx={{
                      [`&, &.${checkboxClasses.checked}`]: {
                        color: "#135413",
                      },
                    }}
                  />
                }
                label="NOT LIVE? Click if not live to input past date and/or time."
              />
            </ThemeProvider>
          </Grid>
          {isChecked && (
            <>
              <Grid item xs={12}>
                <InputLabel>Date:</InputLabel>
                <Select
                  variant="standard"
                  id="event_date_id"
                  native
                  onChange={handleDateSelect}
                  fullWidth
                  required
                  value={chosenVariables.event_date_id}
                  style={!chosenVariables.event_date_id ? { color: "red" } : {}}
                >
                  <option aria-label="None" value="" data-name="">
                    Choose Date
                  </option>
                  {dates &&
                    dates.map((date) => (
                      <option
                        key={date.id}
                        value={date.id}
                        data-name={date.date}
                      >
                        {date.date}
                      </option>
                    ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Time:</InputLabel>
                <TextField
                  variant="standard"
                  id="time"
                  type="time"
                  fullWidth
                  required
                  value={chosenVariables.entry_time}
                  onChange={handleEntryTimeChange}
                />
              </Grid>
            </>
          )}
        </Grid>
        <div className="submit_button" style={{ textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              !entry.shuttle_id || !entry.place_id || !entry.attendee_count
            }
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EntryForm;
