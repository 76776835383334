import React, { useState, useContext } from "react";
import { VariablesContext } from "../chosenVariablesContext";
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Typography,
  Checkbox,
  FormControlLabel,
  checkboxClasses,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { NotificationManager } from "react-notifications";
import useApi from "../api/api";

const DriverForm = ({ setDriverId, setShowDriverForm, setShowVehicleForm }) => {
  const api = useApi();
  const theme = createTheme();

  const { chosenVariables } = useContext(VariablesContext);
  const [driver, setDriver] = useState({
    name: "",
    phone_number: "",
    notes: "",
    event_id: chosenVariables.event_id,
  });

  const [isChecked, setIsChecked] = useState(true);
  const handleIsCheckedChange = () => {
    setIsChecked(!isChecked);
  };

  const handleDriverChange = ({ target: { id, value } }) => {
    setDriver({
      ...driver,
      [id]: value,
    });
  };

  const skip = () => {
    setShowDriverForm(false);
    setShowVehicleForm(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("drivers", driver);
      NotificationManager.success(
        `${driver.name} successfully added.`,
        "Success",
        1000
      );
      setDriverId(response.data.id);
      skip();
    } catch (error) {
      if (error?.response?.status === 499) {
        NotificationManager.error(error.response.data.errors, "Error", 5000);
      } else {
        NotificationManager.error(
          "Something went wrong. Driver not added.",
          "Error",
          5000
        );
      }
    }
  };

  return (
    <div className="modal">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">Add New Driver</Typography>
        <div>
          <Close
            className="route_icon"
            onClick={() => {
              setShowDriverForm(false);
            }}
            style={{ marginTop: "0", color: "black" }}
          />
        </div>
      </div>
      <form className="drop_downs" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputLabel>Full Name:</InputLabel>
            <TextField
              variant="standard"
              id="name"
              fullWidth
              required
              onChange={handleDriverChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Phone Number:</InputLabel>
            <TextField
              variant="standard"
              id="phone_number"
              fullWidth
              required
              onChange={handleDriverChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Notes (optional):</InputLabel>
            <TextField
              variant="standard"
              id="notes"
              fullWidth
              onChange={handleDriverChange}
            />
          </Grid>
          <Grid item xs={12}>
            <ThemeProvider theme={theme}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleIsCheckedChange}
                    sx={{
                      [`&, &.${checkboxClasses.checked}`]: {
                        color: "#135413",
                      },
                    }}
                  />
                }
                label="Local driver?"
              />
            </ThemeProvider>
          </Grid>
        </Grid>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div className="submit_button">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!driver.name || driver.phone_number.length < 10}
            >
              Submit
            </Button>
          </div>
          <div className="submit_button">
            <Button variant="contained" color="secondary" onClick={skip}>
              Skip
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DriverForm;
