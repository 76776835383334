import React, { useState, useEffect, useContext } from "react";
import { VariablesContext } from "../chosenVariablesContext";
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Typography,
  Select,
} from "@mui/material";
import useApi from "../api/api";
import { NotificationManager } from "react-notifications";
import { Close } from "@mui/icons-material";

const AssignmentForm = ({ driverId, vehicleId, setShowAssignmentForm }) => {
  const api = useApi();
  const {
    chosenVariables,
    handleDateSelect,
    handleShuttleSelect,
    handleStartTimeChange,
    handleEndTimeChange,
    dates,
    shuttlesByDate,
    routesByShuttle,
    getAssignments,
  } = useContext(VariablesContext);

  const [assignment, setAssignment] = useState({
    vehicle_id: vehicleId,
    driver_id: driverId,
    event_id: chosenVariables.event_id,
  });

  assignment.event_date_id = chosenVariables.event_date_id;
  assignment.shuttle_id = chosenVariables.shuttle_id;
  assignment.start_time = chosenVariables.start_time;
  assignment.end_time = chosenVariables.end_time;

  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  const handleAssignmentChange = ({ target: { id, value } }) => {
    setAssignment({
      ...assignment,
      [id]: value,
    });
  };

  useEffect(() => {
    const getAllDropDowns = async () => {
      try {
        const response = await api.get(
          `drivers?event_id=${chosenVariables.event_id}&event_date_id=${chosenVariables.event_date_id}&shuttle_id=${chosenVariables.shuttle_id}`
        );
        setDrivers(response.data);
        const response_2 = await api.get(
          `vehicles?event_id=${chosenVariables.event_id}&event_date_id=${chosenVariables.event_date_id}&shuttle_id=${chosenVariables.shuttle_id}`
        );
        setVehicles(response_2.data);
      } catch (error) {
        console.warn("get drivers or vehicles error", error);
      }
    };
    getAllDropDowns();
  }, [
    api,
    chosenVariables.event_id,
    chosenVariables.event_date_id,
    chosenVariables.shuttle_id,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post("assignments", assignment);
      NotificationManager.success(
        "Assignment successfully created.",
        "Success",
        1000
      );
      getAssignments();
      setShowAssignmentForm(false);
    } catch (error) {
      if (error?.response.status === 499) {
        NotificationManager.error(error.response.data.errors, "Error", 5000);
      } else {
        NotificationManager.error(
          "Something went wrong. Assignment not created.",
          "Error",
          5000
        );
      }
    }
  };

  return (
    <div
      className="modal"
      style={
        routesByShuttle.find(({ id }) => id === assignment.route_id)
          ? {
              borderColor: routesByShuttle.find(
                ({ id }) => id === assignment.route_id
              ).color,
            }
          : {}
      }
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">Add New Assignment</Typography>
        <div>
          <Close
            className="route_icon"
            onClick={() => {
              setShowAssignmentForm(false);
            }}
            style={{ marginTop: "0", color: "black" }}
          />
        </div>
      </div>
      <form className="drop_downs" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputLabel>Date:</InputLabel>
            <Select
              variant="standard"
              id="event_date_id"
              native
              onChange={handleDateSelect}
              fullWidth
              required
              value={chosenVariables.event_date_id}
              style={!chosenVariables.event_date_id ? { color: "red" } : {}}
            >
              <option aria-label="None" value="" data-name="">
                Choose Date
              </option>
              {dates &&
                dates.map((date) => (
                  <option key={date.id} value={date.id} data-name={date.date}>
                    {date.date}
                  </option>
                ))}
            </Select>
          </Grid>{" "}
          <Grid item xs={12}>
            <InputLabel>Shuttle:</InputLabel>
            <Select
              variant="standard"
              id="shuttle_id"
              native
              onChange={handleShuttleSelect}
              fullWidth
              required
              value={assignment.shuttle_id}
            >
              <option aria-label="None" value="" data-name="">
                Choose Shuttle
              </option>
              {shuttlesByDate &&
                shuttlesByDate.map((shuttle) => (
                  <option
                    key={shuttle.id}
                    value={shuttle.id}
                    data-name={shuttle.name}
                  >
                    {shuttle.name}
                  </option>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Route:</InputLabel>
            <Select
              variant="standard"
              id="route_id"
              native
              onChange={handleAssignmentChange}
              fullWidth
              required
              value={assignment.route_id}
            >
              <option aria-label="None" value="">
                Choose Route
              </option>
              {routesByShuttle &&
                routesByShuttle.map((route) => (
                  <option key={route.id} value={route.id}>
                    {route.name} {route.description}
                  </option>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Driver:</InputLabel>
            <Select
              variant="standard"
              id="driver_id"
              native
              onChange={handleAssignmentChange}
              fullWidth
              required
              value={assignment.driver_id}
            >
              <option aria-label="None" value="">
                Choose Driver
              </option>
              {drivers &&
                drivers.map((driver) => (
                  <option key={driver.id} value={driver.id}>
                    {driver.name}
                  </option>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Vehicle:</InputLabel>
            <Select
              variant="standard"
              id="vehicle_id"
              native
              onChange={handleAssignmentChange}
              fullWidth
              required
              value={assignment.vehicle_id}
            >
              <option aria-label="None" value="">
                Choose Vehicle
              </option>
              {vehicles &&
                vehicles.map((vehicle) => (
                  <option key={vehicle.id} value={vehicle.id}>
                    {vehicle.company} {vehicle.number}
                  </option>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Start Time:</InputLabel>
            <TextField
              variant="standard"
              id="start_time"
              type="time"
              fullWidth
              value={chosenVariables.start_time}
              onChange={handleStartTimeChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>End Time:</InputLabel>
            <TextField
              variant="standard"
              id="end_time"
              type="time"
              fullWidth
              value={chosenVariables.end_time}
              onChange={handleEndTimeChange}
            />
          </Grid>
        </Grid>
        <div className="submit_button" style={{ textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              !assignment.event_date_id ||
              !assignment.shuttle_id ||
              !assignment.route_id ||
              !assignment.driver_id ||
              !assignment.vehicle_id ||
              assignment.start_time === "" ||
              assignment.end_time === ""
            }
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AssignmentForm;
