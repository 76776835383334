import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Typography } from "@mui/material";

const RouteGraph = ({
  filteredEntries: entries,
  accumulated,
  xRange,
  timeSpanIntervals,
}) => {
  ChartJS.register(CategoryScale, LinearScale, BarElement);

  function getAttendeeCountOrMakeZero(x) {
    for (let each of accumulated) {
      if (x === each.x) {
        return each.y;
      }
    }
    return 0;
  }

  let totalAttendeeCount = 0;
  if (entries.length !== 0) {
    totalAttendeeCount = entries
      .map((entry) => entry.attendee_count)
      .reduce((a, b) => a + b);
  }

  return (
    <div className="graph_border">
      <Bar
        data={{
          labels: timeSpanIntervals.map((v) => v),
          datasets: [
            {
              backgroundColor: "black",
              data: timeSpanIntervals.map((v) => getAttendeeCountOrMakeZero(v)),
            },
          ],
        }}
        options={{
          scales: {
            x: {
              ticks: {
                maxTicksLimit: xRange,
              },
            },
          },
        }}
      />
      <Typography align="center">All Routes</Typography>
      <Typography align="center">
        {totalAttendeeCount}{" "}
        {parseInt(totalAttendeeCount) > 1 ? <>attendees</> : <>attendee</>}{" "}
        moved in {entries.length} {entries.length > 1 ? <>trips</> : <>trip</>}
      </Typography>
    </div>
  );
};

export default RouteGraph;
