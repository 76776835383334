import React, { useState, useEffect, useContext } from "react";
import { VariablesContext } from "../chosenVariablesContext";
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Typography,
  Select,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import useApi from "../api/api";
import { NotificationManager } from "react-notifications";

const AssignmentAddForm = ({ route, setShowAssignmentAddForm }) => {
  const api = useApi();
  const {
    chosenVariables,
    handleStartTimeChange,
    handleEndTimeChange,
    getAssignments,
    dates,
    shuttleDates,
    shuttleRoutes,
    handleDateSelect,
    setVariables,
  } = useContext(VariablesContext);

  const [assignment, setAssignment] = useState({
    route_id: route.id,
    event_id: chosenVariables.event_id,
    event_date_id: chosenVariables.event_date_id,
  });

  assignment.shuttle_id = chosenVariables.shuttle_id;
  assignment.start_time = chosenVariables.start_time;
  assignment.end_time = chosenVariables.end_time;

  const uniqueRouteIds = [];

  const shuttlesByRoute = shuttleRoutes
    .filter((each) =>
      shuttleDates
        .filter(
          ({ event_date_id }) => event_date_id === chosenVariables.event_date_id
        )
        .find((one) => one.shuttle_id === each.shuttle_id)
    )
    .filter(({ route_id }) => route_id.includes(route.id))
    .map((each) => each.shuttle)
    .filter(({ id }) => {
      const isDuplicate = uniqueRouteIds.includes(id);
      if (!isDuplicate) {
        uniqueRouteIds.push(id);
        return true;
      }
      return false;
    });

  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  const handleAssignmentChange = ({ target: { id, value } }) => {
    setAssignment({
      ...assignment,
      [id]: value,
    });
  };

  const handleShuttleSelect = ({ target }) => {
    setVariables({
      ...chosenVariables,
      shuttle_id: target.value,
      shuttle_name: target.options[target.selectedIndex].dataset.name,
    });
  };

  useEffect(() => {
    const getAllDropDowns = async () => {
      try {
        const response = await api.get(
          `drivers?event_id=${chosenVariables.event_id}&event_date_id=${chosenVariables.event_date_id}&shuttle_id=${chosenVariables.shuttle_id}`
        );
        setDrivers(response.data);
        const response_2 = await api.get(
          `vehicles?event_id=${chosenVariables.event_id}&event_date_id=${chosenVariables.event_date_id}&shuttle_id=${chosenVariables.shuttle_id}`
        );
        setVehicles(response_2.data);
      } catch (error) {
        console.warn("get drivers or vehicles error", error);
      }
    };
    getAllDropDowns();
  }, [
    api,
    chosenVariables.event_id,
    chosenVariables.event_date_id,
    chosenVariables.shuttle_id,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post("assignments", assignment);
      NotificationManager.success(
        `Assignment successfully created.`,
        "Success",
        1000
      );
      getAssignments();
      setShowAssignmentAddForm(false);
      setVariables({
        ...chosenVariables,
        shuttle_id: chosenVariables.shuttle_id,
      });
    } catch (error) {
      if (error?.response.status === 499) {
        NotificationManager.error(error.response.data.errors, "Error", 5000);
      } else {
        NotificationManager.error(
          "Something went wrong. Assignment not created.",
          "Error",
          5000
        );
      }
    }
  };

  return (
    <div className="modal" style={{ borderColor: route.color }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">
          Add New Assignment for
          <br></br>
          <span style={{ color: route?.color }}>
            {!isNaN(route.name) && <>Route</>} {route.name} {route.description}
          </span>
        </Typography>
        <div>
          <Close
            className="route_icon"
            onClick={() => setShowAssignmentAddForm(false)}
            style={{ marginTop: "0", color: "black" }}
          />
        </div>
      </div>
      <form className="drop_downs" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputLabel>Date:</InputLabel>
            <Select
              variant="standard"
              id="event_date_id"
              native
              onChange={handleDateSelect}
              fullWidth
              required
              value={chosenVariables.event_date_id}
              style={!chosenVariables.event_date_id ? { color: "red" } : {}}
            >
              <option aria-label="None" value="" data-name="">
                Choose Date
              </option>
              {dates &&
                dates.map((date) => (
                  <option key={date.id} value={date.id} data-name={date.date}>
                    {date.date}
                  </option>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Shuttle:</InputLabel>
            <Select
              variant="standard"
              id="shuttle_id"
              native
              onChange={handleShuttleSelect}
              fullWidth
              required
              value={chosenVariables.shuttle_id}
            >
              <option aria-label="None" value="" data-name="">
                Choose Shuttle
              </option>
              {shuttlesByRoute &&
                shuttlesByRoute.map((shuttle) => (
                  <option
                    key={shuttle.id}
                    value={shuttle.id}
                    data-name={shuttle.name}
                  >
                    {shuttle.name}
                  </option>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Driver:</InputLabel>
            <Select
              variant="standard"
              id="driver_id"
              native
              onChange={handleAssignmentChange}
              fullWidth
              required
            >
              <option aria-label="None" value="">
                Choose Driver
              </option>
              {drivers &&
                drivers.map((driver) => (
                  <option key={driver.id} value={driver.id}>
                    {driver.name}
                  </option>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Vehicle:</InputLabel>
            <Select
              variant="standard"
              id="vehicle_id"
              native
              onChange={handleAssignmentChange}
              fullWidth
              required
            >
              <option aria-label="None" value="">
                Choose Vehicle
              </option>
              {vehicles &&
                vehicles.map((vehicle) => (
                  <option key={vehicle.id} value={vehicle.id}>
                    {vehicle.company} {vehicle.number}
                  </option>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Start Time:</InputLabel>
            <TextField
              variant="standard"
              id="start_time"
              type="time"
              fullWidth
              value={chosenVariables.start_time}
              onChange={handleStartTimeChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>End Time:</InputLabel>
            <TextField
              variant="standard"
              id="end_time"
              type="time"
              fullWidth
              value={chosenVariables.end_time}
              onChange={handleEndTimeChange}
            />
          </Grid>
        </Grid>
        <div style={{ textAlign: "center" }}>
          <div className="submit_button">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                !chosenVariables.shuttle_id ||
                !assignment.driver_id ||
                !assignment.vehicle_id ||
                assignment.start_time === "" ||
                assignment.end_time === ""
              }
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AssignmentAddForm;
