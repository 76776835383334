import React, { useState, useContext, useEffect } from "react";
import { VariablesContext } from "../chosenVariablesContext";
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  checkboxClasses,
} from "@mui/material";
import { Close, DeleteOutlined } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useApi from "../api/api";
import { NotificationManager } from "react-notifications";
import ConfirmDelete from "../hooks/confirmDelete";
import ConfirmCancel from "../hooks/confirmCancel";

const ManageRoutes = () => {
  const api = useApi();
  const theme = createTheme();

  const {
    shuttles,
    routes,
    getRoutes,
    getPlaces,
    shuttleRoutes,
    getShuttleRoutes,
    chosenVariables,
  } = useContext(VariablesContext);

  const [alterRoutesObj, setAlterRoutesObj] = useState({});
  const [alterShuttleRoutesObj, setAlterShuttleRoutesObj] = useState({});

  const {
    deleteModal,
    objectToDelete,
    setObjectToDelete,
    handleShowDeleteModal,
  } = ConfirmDelete();

  const confirmActions = () => {
    setAlterRoutesObj({});
    setAlterShuttleRoutesObj({});
  };

  const {
    cancelModal,
    showCancelModal,
    setShowCancelModal,
    handleShowCancelModal,
  } = ConfirmCancel(confirmActions);

  const [route, setRoute] = useState({
    event_id: chosenVariables.event_id,
  });

  const [showForm, setShowForm] = useState(false);

  const handleCloseForm = () => {
    setShowForm(false);
    setRoute({
      name: "",
      color: "",
      description: "",
      event_id: chosenVariables.event_id,
    });
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        handleCloseForm();
        setObjectToDelete(false);
        setShowCancelModal(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  });

  const handleNewRouteChange = ({ target: { id, value } }) => {
    setRoute({
      ...route,
      [id]: value,
    });
  };

  const handleRoutesUpdate = ({ target: { id, value } }, route) => {
    setAlterRoutesObj({
      ...alterRoutesObj,
      [route.id]: {
        ...route,
        [id]: value,
      },
    });
  };

  const handleCheckChange = (routeID, shuttleID, state, existing) => {
    const ID = `${routeID}*${shuttleID}`;
    if ((existing && state) || (!existing && !state)) {
      delete alterShuttleRoutesObj[ID];
      setAlterShuttleRoutesObj({
        ...alterShuttleRoutesObj,
      });
    } else if (!existing && state) {
      setAlterShuttleRoutesObj({
        ...alterShuttleRoutesObj,
        [ID]: {
          event_id: chosenVariables.event_id,
          route_id: routeID,
          shuttle_id: shuttleID,
        },
      });
    } else if (existing && !state) {
      setAlterShuttleRoutesObj({
        ...alterShuttleRoutesObj,
        [ID]: {
          id: existing.id,
          route_id: routeID,
          shuttle_id: shuttleID,
        },
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post("routes", {
        route: {
          ...route,
          shuttle_routes_attributes: shuttles.map((shuttle) => ({
            shuttle_id: shuttle.id,
            event_id: shuttle.event_id,
          })),
        },
      });
      document.getElementById("name").value = "";
      document.getElementById("color").value = "";
      document.getElementById("description").value = "";
      document.getElementById("name").select();
      setRoute({
        event_id: chosenVariables.event_id,
      });
      getRoutes();
      getShuttleRoutes();
      NotificationManager.success(
        "Route successfully created.",
        "Success",
        1000
      );
    } catch (error) {
      if (error?.response?.data?.errors?.name) {
        NotificationManager.error(
          `${route.name} already exists in database.`,
          "Error",
          5000
        );
      } else {
        NotificationManager.error(
          "Something went wrong. Route not created.",
          "Error",
          5000
        );
      }
    }
  };

  const handleUpdateShuttleRoutes = (e) => {
    e.preventDefault();
    Object.values(alterShuttleRoutesObj).filter(({ event_id }) => event_id)
      .length &&
      Promise.all(
        Object.values(alterShuttleRoutesObj)
          .filter(({ event_id }) => event_id)
          .map(async (shuttle_route) => {
            try {
              await api.post("shuttle_routes", shuttle_route);
              NotificationManager.success(
                "Shuttle routes successfully added.",
                "Success",
                1000
              );
            } catch {
              NotificationManager.error(
                "Something went wrong. Shuttle routes not added.",
                "Error",
                5000
              );
            } finally {
              getShuttleRoutes();
              setAlterShuttleRoutesObj({});
            }
          })
      );
    Object.values(alterShuttleRoutesObj).filter(({ id }) => id).length &&
      Promise.all(
        Object.values(alterShuttleRoutesObj)
          .filter(({ id }) => id)
          .map(async (shuttle_route) => {
            try {
              await api.delete(`shuttle_routes/${shuttle_route.id}`);
              NotificationManager.success(
                "Shuttle routes successfully removed.",
                "Success",
                1000
              );
            } catch (error) {
              if (error?.response?.status === 499) {
                NotificationManager.error(
                  error.response.data.errors,
                  "Error",
                  10000
                );
              } else {
                NotificationManager.error(
                  "Something went wrong. Some shuttle routes not removed.",
                  "Error",
                  5000
                );
              }
            } finally {
              setTimeout(() => {
                getShuttleRoutes();
                setAlterShuttleRoutesObj({});
              }, 1000);
            }
          })
      );
    Object.keys(alterRoutesObj).length &&
      Promise.all(
        Object.values(alterRoutesObj).map(async (route) => {
          try {
            await api.put(`routes/${route.id}`, { route });
            NotificationManager.success(
              "Routes successfully updated.",
              "Success",
              1000
            );
          } catch (error) {
            if (error.response.data.errors.name) {
              NotificationManager.error(
                "There is already a route with this name.",
                "Failure",
                5000
              );
            } else {
              NotificationManager.error(
                "Something went wrong. Route not removed.",
                "Failure",
                5000
              );
            }
          } finally {
            getRoutes();
            getPlaces();
            getShuttleRoutes();
            setAlterRoutesObj({});
          }
        })
      );
  };

  const form = () => {
    return (
      <div className="modal" style={{ borderColor: route.color }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">Add New Route</Typography>
          <div>
            <Close
              className="route_icon"
              onClick={handleCloseForm}
              style={{ marginTop: "0", color: "black" }}
            />
          </div>
        </div>
        <form className="drop_downs" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputLabel>Name:</InputLabel>
              <TextField
                variant="standard"
                id="name"
                fullWidth
                required
                onChange={handleNewRouteChange}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Color (optional):</InputLabel>
              <TextField
                variant="standard"
                id="color"
                fullWidth
                onChange={handleNewRouteChange}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Description (optional):</InputLabel>
              <TextField
                variant="standard"
                id="description"
                fullWidth
                onChange={handleNewRouteChange}
              />
            </Grid>
          </Grid>
          <div className="submit_button" style={{ textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!route.name}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      {(showForm || objectToDelete || showCancelModal) && (
        <div className="overlay"></div>
      )}
      {showForm && form()}
      {objectToDelete && deleteModal(objectToDelete, "Route", "routes")}
      {showCancelModal && cancelModal()}
      <div
        className="skinny_stack"
        style={{
          justifyContent: "space-between",
          marginTop: "1.5rem",
        }}
      >
        <Typography variant="h5">Manage Routes</Typography>
        {Object.keys(alterRoutesObj).length === 0 &&
        Object.keys(alterShuttleRoutesObj).length === 0 ? (
          <span style={{ display: "flex" }} className="skinny_stack">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowForm(true)}
              style={{
                height: 38,
                whiteSpace: "nowrap",
                marginLeft: 5,
              }}
              className="skinny_stack"
            >
              Add Route
            </Button>
          </span>
        ) : (
          <span style={{ display: "flex" }} className="skinny_stack">
            <Button
              color="secondary"
              variant="contained"
              onClick={handleShowCancelModal}
              style={{ margin: "0 5px", height: 38 }}
            >
              Discard
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleUpdateShuttleRoutes}
              style={{ margin: "0 0 0 5px", height: 38 }}
            >
              Update
            </Button>
          </span>
        )}
      </div>
      <Typography style={{ margin: "15px 0" }} variant="h6">
        {routes.length} routes
      </Typography>
      <div style={{ display: "flex", overflowX: "scroll" }}>
        <Table size="small" style={{ width: "200%" }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  height: 30,
                  fontWeight: "900",
                }}
              >
                Name
              </TableCell>
              <TableCell
                style={{
                  height: 30,
                  fontWeight: "900",
                }}
              >
                Color
              </TableCell>
              <TableCell
                style={{
                  height: 30,
                  fontWeight: "900",
                }}
              >
                Description
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {routes.map((route) => {
              const routeToRender = alterRoutesObj[route.id] || route;
              return (
                <TableRow
                  key={routeToRender.id}
                  style={
                    alterRoutesObj[routeToRender.id] && {
                      backgroundColor: "lightgray",
                    }
                  }
                >
                  <TableCell>
                    <TextField
                      variant="standard"
                      id="name"
                      onChange={(e) => handleRoutesUpdate(e, routeToRender)}
                      value={routeToRender.name}
                      inputProps={{ style: { fontSize: 14 } }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="standard"
                      id="color"
                      onChange={(e) => handleRoutesUpdate(e, routeToRender)}
                      value={routeToRender.color ? routeToRender.color : ""}
                      inputProps={{
                        style: {
                          fontSize: 14,
                          fontWeight: "900",
                          color: routeToRender.color,
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="standard"
                      id="description"
                      onChange={(e) => handleRoutesUpdate(e, routeToRender)}
                      value={
                        routeToRender.description
                          ? routeToRender.description
                          : ""
                      }
                      inputProps={{ style: { fontSize: 14 } }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {shuttles.map((shuttle) => {
          return (
            <Table size="small" key={shuttle.id}>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      whiteSpace: "nowrap",
                      height: 30,
                    }}
                  >
                    {shuttle.name}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {routes.map((route) => {
                  const existing = shuttleRoutes.find(
                    (one) =>
                      one.shuttle_id === shuttle.id && one.route_id === route.id
                  );
                  const changed = Object.values(alterShuttleRoutesObj).find(
                    (one) =>
                      one.shuttle_id === shuttle.id && one.route_id === route.id
                  );
                  return (
                    <TableRow
                      key={route.id}
                      style={
                        changed && {
                          backgroundColor: "lightgray",
                        }
                      }
                    >
                      <ThemeProvider theme={theme}>
                        {(!!existing && !changed) ||
                        (!!changed && changed.event_id) ? (
                          <TableCell style={{ textAlign: "center" }}>
                            <Checkbox
                              onChange={() =>
                                handleCheckChange(
                                  route.id,
                                  shuttle.id,
                                  false,
                                  existing
                                )
                              }
                              checked={true}
                              sx={{
                                [`&, &.${checkboxClasses.checked}`]: {
                                  color: "#135413",
                                },
                              }}
                            />
                          </TableCell>
                        ) : (
                          <TableCell style={{ textAlign: "center" }}>
                            <Checkbox
                              onChange={() =>
                                handleCheckChange(
                                  route.id,
                                  shuttle.id,
                                  true,
                                  existing
                                )
                              }
                              checked={false}
                            />
                          </TableCell>
                        )}
                      </ThemeProvider>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          );
        })}
        <Table size="small" style={{ width: "30%" }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  height: 30,
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {routes.map((route) => {
              return (
                <TableRow key={route.id}>
                  <TableCell
                    style={{
                      height: 42,
                    }}
                  >
                    <span className="action_icon">
                      <DeleteOutlined
                        onClick={() => handleShowDeleteModal(route)}
                        style={{ color: "black" }}
                      />
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default ManageRoutes;
