import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import {
  AddCircleOutline,
  Close,
  ArrowBack,
  ArrowForward,
} from "@mui/icons-material";
import AssignmentCard from "./assignmentCard";

const RouteCard = ({
  route,
  routes,
  authLevel,
  routeAssignments,
  discardedRouteAssignments,
  chosenVariables,
  setVariables,
  shuttlesByRoute,
  shuttleDates,
  handleShowAssignmentDetailsEditForm,
  handleShowAddForm,
  handleShowAssignmentEditForm,
  anyFormShowing,
  objectToDelete,
  handleShowDeleteModal,
  objectToAddBack,
  handleShowAddBackModal,
}) => {
  const changeRoute = (id) => {
    setVariables({ ...chosenVariables, route_id: id });
  };

  const showRoutes = () => {
    setVariables({ ...chosenVariables, route_id: "" });
  };

  const changeShuttle = (id) => {
    setVariables({ ...chosenVariables, shuttle_id: id });
  };

  let routeName = "";
  parseInt(route.name) < 10
    ? (routeName = route.name.slice(-1))
    : (routeName = route.name);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 37) {
        routes.indexOf(route) !== 0
          ? changeRoute(routes[routes.indexOf(route) - 1].id)
          : changeRoute(routes[routes.length - 1].id);
      } else if (event.keyCode === 39) {
        routes.indexOf(route) !== routes.length - 1
          ? changeRoute(routes[routes.indexOf(route) + 1].id)
          : changeRoute(routes[0].id);
      }
    };
    !anyFormShowing &&
      chosenVariables.route_id &&
      !objectToDelete &&
      !objectToAddBack &&
      window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  });

  const discardedList = (assignmentStatus, title, verbiage) => {
    return (
      <>
        <div
          style={{
            fontWeight: 600,
            marginTop: 30,
            fontSize: 20,
            textAlign: "center",
          }}
        >
          {title}
        </div>
        {chosenVariables.shuttle_id ? (
          <>
            {discardedRouteAssignments
              .filter(({ status }) => status === assignmentStatus)
              .filter(
                ({ shuttle_id }) => shuttle_id === chosenVariables.shuttle_id,
              )
              .map((assignment) => (
                <AssignmentCard
                  key={assignment.id}
                  assignment={assignment}
                  authLevel={authLevel}
                  chosenRoute={chosenVariables.route_id}
                  handleShowAssignmentEditForm={handleShowAssignmentEditForm}
                  handleShowAssignmentDetailsEditForm={
                    handleShowAssignmentDetailsEditForm
                  }
                  handleShowDeleteModal={handleShowDeleteModal}
                  handleShowAddBackModal={handleShowAddBackModal}
                  verbiage={verbiage}
                />
              ))}
          </>
        ) : (
          <>
            {shuttleDates.map((shuttleDate) => (
              <div key={shuttleDate.id}>
                {shuttlesByRoute &&
                  shuttlesByRoute
                    .filter(({ id }) => id === shuttleDate.shuttle_id)
                    .map((shuttle) => (
                      <div key={shuttle.id} style={{ marginTop: 20 }}>
                        <div style={{ fontWeight: 600, fontSize: 17 }}>
                          {shuttle.name}
                        </div>
                        {discardedRouteAssignments
                          .filter(({ status }) => status === assignmentStatus)
                          .filter(({ shuttle_id }) => shuttle_id === shuttle.id)
                          .map((assignment) => (
                            <AssignmentCard
                              key={assignment.id}
                              assignment={assignment}
                              authLevel={authLevel}
                              chosenRoute={chosenVariables.route_id}
                              handleShowAssignmentEditForm={
                                handleShowAssignmentEditForm
                              }
                              handleShowAssignmentDetailsEditForm={
                                handleShowAssignmentDetailsEditForm
                              }
                              handleShowDeleteModal={handleShowDeleteModal}
                              handleShowAddBackModal={handleShowAddBackModal}
                              verbiage={verbiage}
                            />
                          ))}
                      </div>
                    ))}
              </div>
            ))}
          </>
        )}
      </>
    );
  };

  return (
    <div
      className={
        chosenVariables.route_id === "" ? "route_card" : "chosen_route_card"
      }
      style={{
        borderColor: route?.color,
      }}
    >
      {chosenVariables.route_id && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <ArrowBack
              style={{ margin: "0px 20px 0 0", color: "black" }}
              className="route_icon"
              onClick={
                routes.indexOf(route) !== 0
                  ? () => changeRoute(routes[routes.indexOf(route) - 1].id)
                  : () => changeRoute(routes[routes.length - 1].id)
              }
            />
            <ArrowForward
              style={{ marginTop: "0", color: "black" }}
              className="route_icon"
              onClick={
                routes.indexOf(route) !== routes.length - 1
                  ? () => changeRoute(routes[routes.indexOf(route) + 1].id)
                  : () => changeRoute(routes[0].id)
              }
            />
          </div>
          <Close
            style={{ marginTop: "0", color: "black" }}
            className="route_icon"
            onClick={() => {
              showRoutes();
            }}
          />
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          component="h6"
          className={!chosenVariables.route_id ? "route_name" : ""}
          style={
            !chosenVariables.route_id
              ? {
                  color: route?.color,
                  fontWeight: "600",
                  height: "fit-content",
                }
              : { color: route?.color, fontWeight: "600", padding: "4px" }
          }
          onClick={() => changeRoute(route.id)}
        >
          {isNaN(route.name) ? (
            <>
              {routeName} {route.description}
            </>
          ) : (
            <>
              Route {routeName} {route.description}
            </>
          )}
        </Typography>
        <div style={{ whiteSpace: "nowrap", padding: "2px", display: "flex" }}>
          <>
            {authLevel !== "staff" && (
              <AddCircleOutline
                className="route_icon"
                onClick={() => handleShowAddForm(route)}
                style={{
                  fontSize: 23,
                  margin: "0 20px 0 0",
                  color: "black",
                }}
                value={route.id}
              />
            )}
          </>
          <div>
            <div>
              {chosenVariables.shuttle_id ? (
                <>
                  {
                    routeAssignments.filter(
                      ({ shuttle_id }) =>
                        shuttle_id === chosenVariables.shuttle_id,
                    ).length
                  }
                </>
              ) : (
                <>{routeAssignments.length}</>
              )}{" "}
              active
            </div>
            <div>
              {chosenVariables.shuttle_id ? (
                <>
                  {
                    discardedRouteAssignments
                      .filter(({ status }) => status === "on_break")
                      .filter(
                        ({ shuttle_id }) =>
                          shuttle_id === chosenVariables.shuttle_id,
                      ).length
                  }
                </>
              ) : (
                <>
                  {
                    discardedRouteAssignments.filter(
                      ({ status }) => status === "on_break",
                    ).length
                  }
                </>
              )}{" "}
              on break
            </div>
            <div>
              {chosenVariables.shuttle_id ? (
                <>
                  {
                    discardedRouteAssignments
                      .filter(({ status }) => status === "released")
                      .filter(
                        ({ shuttle_id }) =>
                          shuttle_id === chosenVariables.shuttle_id,
                      ).length
                  }
                </>
              ) : (
                <>
                  {
                    discardedRouteAssignments.filter(
                      ({ status }) => status === "released",
                    ).length
                  }
                </>
              )}{" "}
              released
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          chosenVariables.route_id !== "" && routeAssignments.length > 4
            ? "chosen_route_columns"
            : ""
        }
        style={
          chosenVariables.route_id !== ""
            ? {
                columnRule: `1.5px solid ${route?.color}`,
              }
            : {}
        }
      >
        {chosenVariables.route_id && (
          <div style={{ fontWeight: 600, fontSize: 20, textAlign: "center" }}>
            Active
          </div>
        )}
        {chosenVariables.shuttle_id ? (
          <>
            {routeAssignments
              .filter(
                ({ shuttle_id }) => shuttle_id === chosenVariables.shuttle_id,
              )
              .map((assignment) => (
                <AssignmentCard
                  key={assignment.id}
                  assignment={assignment}
                  authLevel={authLevel}
                  chosenRoute={chosenVariables.route_id}
                  handleShowAssignmentEditForm={handleShowAssignmentEditForm}
                  handleShowAssignmentDetailsEditForm={
                    handleShowAssignmentDetailsEditForm
                  }
                  handleShowDeleteModal={handleShowDeleteModal}
                  verbiage={false}
                />
              ))}
          </>
        ) : (
          <>
            {shuttleDates.map((shuttleDate, i) => (
              <div key={shuttleDate.id}>
                {shuttlesByRoute &&
                  shuttlesByRoute
                    .filter(({ id }) => id === shuttleDate.shuttle_id)
                    .map((shuttle) => (
                      <div
                        key={shuttle.id}
                        style={i === 0 ? { marginTop: 10 } : { marginTop: 20 }}
                      >
                        <div
                          className="route_name"
                          style={{
                            fontWeight: 600,
                            fontSize: 17,
                            width: "fit-content",
                            marginLeft: "-2px",
                          }}
                          onClick={() => changeShuttle(shuttle.id)}
                        >
                          {shuttle.name}
                        </div>
                        {routeAssignments
                          .filter(({ shuttle_id }) => shuttle_id === shuttle.id)
                          .map((assignment) => (
                            <AssignmentCard
                              key={assignment.id}
                              assignment={assignment}
                              authLevel={authLevel}
                              chosenRoute={chosenVariables.route_id}
                              handleShowAssignmentEditForm={
                                handleShowAssignmentEditForm
                              }
                              handleShowAssignmentDetailsEditForm={
                                handleShowAssignmentDetailsEditForm
                              }
                              handleShowDeleteModal={handleShowDeleteModal}
                              verbiage={false}
                            />
                          ))}
                      </div>
                    ))}
              </div>
            ))}
          </>
        )}
        {chosenVariables.route_id && (
          <>
            {discardedList("on_break", "On Break", "Break started at")}
            {discardedList("released", "Released", "Released at")}
          </>
        )}
      </div>
    </div>
  );
};

export default RouteCard;
