import React, { useContext, useState, useEffect } from "react";
import { VariablesContext } from "../chosenVariablesContext";
import { Grid, Typography } from "@mui/material";
import RouteCard from "./routeCard";
import AssignmentDetailsEditForm from "../forms/assignmentDetailsEditForm";
import AssignmentAddForm from "../forms/assignmentAddForm";
import RemoveAssignment from "../hooks/removeAssignment";

const RouteView = ({
  authLevel,
  driverToEdit,
  setDriverToEdit,
  showAssignmentDetailsEditForm,
  setShowAssignmentDetailsEditForm,
  handleShowAssignmentDetailsEditForm,
  driverChanges,
  setDriverChanges,
  vehicleToEdit,
  setVehicleToEdit,
  vehicleChanges,
  setVehicleChanges,
  assignmentToEdit,
  handleShowAssignmentEditForm,
  anyFormShowing,
}) => {
  const {
    chosenVariables,
    setVariables,
    routesByShuttle,
    assignments,
    discardedAssignments,
    getAssignments,
    dateSelect,
    shuttleSelect,
    routeSelect,
    shuttleDates,
    shuttleRoutes,
    isLoading,
  } = useContext(VariablesContext);

  const {
    deleteModal,
    objectToDelete,
    setObjectToDelete,
    handleShowDeleteModal,
    addBackModal,
    objectToAddBack,
    setObjectToAddBack,
    handleShowAddBackModal,
  } = RemoveAssignment();

  const [routeToAddTo, setRouteToAddTo] = useState({});
  const [showAssignmentAddForm, setShowAssignmentAddForm] = useState(false);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        if (!anyFormShowing) {
          if (objectToDelete || objectToAddBack) {
            setObjectToDelete(false);
            setObjectToAddBack(false);
          } else if (showAssignmentAddForm) {
            setShowAssignmentAddForm(false);
          } else {
            setVariables({ ...chosenVariables, route_id: "" });
          }
        }
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  });

  const handleShowAddForm = (route) => {
    setShowAssignmentAddForm(true);
    setRouteToAddTo(route);
  };

  return (
    <div>
      {(objectToDelete || objectToAddBack) && <div className="overlay"></div>}
      {objectToDelete && deleteModal(objectToDelete)}
      {objectToAddBack && addBackModal(objectToAddBack)}
      {(authLevel === "superuser" ||
        authLevel === "admin" ||
        authLevel === "supervisor") && (
        <>
          {showAssignmentDetailsEditForm && (
            <AssignmentDetailsEditForm
              driver={driverToEdit}
              setDriverToEdit={setDriverToEdit}
              driverChanges={driverChanges}
              setDriverChanges={setDriverChanges}
              vehicle={vehicleToEdit}
              setVehicleToEdit={setVehicleToEdit}
              vehicleChanges={vehicleChanges}
              setVehicleChanges={setVehicleChanges}
              assignmentToEdit={assignmentToEdit}
              setShowAssignmentDetailsEditForm={
                setShowAssignmentDetailsEditForm
              }
              getAssignments={getAssignments}
            />
          )}
          {showAssignmentAddForm && (
            <AssignmentAddForm
              route={routeToAddTo}
              setShowAssignmentAddForm={setShowAssignmentAddForm}
            />
          )}
          {showAssignmentAddForm && <div className="overlay"></div>}
        </>
      )}
      <Typography variant="h5">Route View</Typography>
      <div className="drop_downs">
        <Grid container spacing={3}>
          {dateSelect(true)}
          {shuttleSelect(true)}
          {routeSelect(true)}
        </Grid>
      </div>
      {!isLoading &&
      shuttleDates.find(
        ({ event_date_id }) => event_date_id === chosenVariables.event_date_id,
      ) &&
      routesByShuttle.length > 0 ? (
        <div className="route_cards">
          {routesByShuttle
            .filter((each) => each.id.includes(chosenVariables.route_id))
            .map((route) => (
              <RouteCard
                key={route.id}
                route={route}
                routes={routesByShuttle}
                authLevel={authLevel}
                value={route.id}
                routeAssignments={assignments.filter(
                  ({ route_id }) => route_id === route.id,
                )}
                discardedRouteAssignments={discardedAssignments.filter(
                  ({ route_id }) => route_id === route.id,
                )}
                chosenVariables={chosenVariables}
                setVariables={setVariables}
                shuttlesByRoute={shuttleRoutes
                  .filter(({ route_id }) => route_id.includes(route.id))
                  .map((each) => each.shuttle)}
                shuttleDates={shuttleDates.filter(
                  ({ event_date_id }) =>
                    event_date_id === chosenVariables.event_date_id,
                )}
                handleShowAssignmentEditForm={handleShowAssignmentEditForm}
                handleShowAddForm={handleShowAddForm}
                handleShowAssignmentDetailsEditForm={
                  handleShowAssignmentDetailsEditForm
                }
                anyFormShowing={anyFormShowing}
                showAssignmentAddForm={showAssignmentAddForm}
                objectToDelete={objectToDelete}
                handleShowDeleteModal={handleShowDeleteModal}
                objectToAddBack={objectToAddBack}
                handleShowAddBackModal={handleShowAddBackModal}
              />
            ))}
        </div>
      ) : (
        <div align="center" style={{ fontSize: 20, marginTop: "32px" }}>
          {isLoading ? (
            <div style={{ color: "green" }}>Results are loading...</div>
          ) : (
            <div style={{ color: "red" }}>
              There are no routes matching the applied filters
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RouteView;
