import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Typography } from "@mui/material";
import { Close, ArrowBack, ArrowForward } from "@mui/icons-material";

const ChosenRouteGraph = ({
  route,
  filteredEntries,
  times,
  counts,
  xRange,
  timeSpanIntervals,
  yMax,
  setChosenRouteGraph,
  routes,
}) => {
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 37) {
        routes.indexOf(route) !== 0
          ? setChosenRouteGraph(routes[routes.indexOf(route) - 1])
          : setChosenRouteGraph(routes[routes.length - 1]);
      } else if (event.keyCode === 39) {
        routes.indexOf(route) !== routes.length - 1
          ? setChosenRouteGraph(routes[routes.indexOf(route) + 1])
          : setChosenRouteGraph(routes[0]);
      }
    };
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  });

  const routeEntries = filteredEntries.filter((each) =>
    each.z.includes(route.name),
  );

  const isolated = [];
  for (let i = 0; i < filteredEntries.length; i++) {
    if (filteredEntries[i].z.includes(route.name)) {
      isolated.push({ x: `${times[i]}0`, y: counts[i] });
    } else {
      isolated.push({ x: `${times[i]}0`, y: 0 });
    }
  }

  const holder = {};

  isolated.forEach(function (a) {
    if (holder.hasOwnProperty(a.x)) {
      holder[a.x] = holder[a.x] + a.y;
    } else {
      holder[a.x] = a.y;
    }
  });

  const accumulated = [];

  for (const prop in holder) {
    accumulated.push({ x: prop, y: holder[prop] });
  }

  function getAttendeeCountOrMakeZero(x) {
    for (let each of accumulated) {
      if (x === each.x) {
        return each.y;
      }
    }
    return 0;
  }

  let routeName = "";
  parseInt(route.name) < 10
    ? (routeName = route.name.slice(-1))
    : (routeName = route.name);

  let totalAttendeeCount = 0;
  if (routeEntries.length !== 0) {
    totalAttendeeCount = routeEntries
      .map((entry) => entry.y)
      .reduce((a, b) => a + b);
  }

  return (
    <div
      className="graph_modal"
      style={{
        borderColor: route?.color,
      }}
    >
      <div style={{ height: "100%" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <ArrowBack
              style={{ margin: "0px 20px 0 0", color: "black" }}
              className="route_icon"
              onClick={
                routes.indexOf(route) !== 0
                  ? () => setChosenRouteGraph(routes[routes.indexOf(route) - 1])
                  : () => setChosenRouteGraph(routes[routes.length - 1])
              }
            />
            <ArrowForward
              style={{ marginTop: "0", color: "black" }}
              className="route_icon"
              onClick={
                routes.indexOf(route) !== routes.length - 1
                  ? () => setChosenRouteGraph(routes[routes.indexOf(route) + 1])
                  : () => setChosenRouteGraph(routes[0])
              }
            />
          </div>
          <Close
            style={{ marginTop: "0", color: "black" }}
            className="route_icon"
            onClick={() => setChosenRouteGraph(false)}
          />
        </div>
        {totalAttendeeCount !== 0 ? (
          <>
            <div className="chosen_graph_center">
              <div style={{ height: "100%", width: "100%" }}>
                <Bar
                  data={{
                    labels: timeSpanIntervals.map((v) => v),
                    datasets: [
                      {
                        backgroundColor: route?.color,
                        data: timeSpanIntervals.map((v) =>
                          getAttendeeCountOrMakeZero(v),
                        ),
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        ticks: {
                          maxTicksLimit: xRange,
                        },
                      },
                      y: {
                        suggestedMax: yMax,
                      },
                    },
                  }}
                />
                {isNaN(route.name) ? (
                  <Typography align="center">
                    {routeName} {route.description}
                  </Typography>
                ) : (
                  <Typography align="center">
                    Route {routeName} {route.description}
                  </Typography>
                )}
                <Typography align="center">
                  {totalAttendeeCount}{" "}
                  {parseInt(totalAttendeeCount) > 1 ? (
                    <>attendees</>
                  ) : (
                    <>attendee</>
                  )}{" "}
                  moved in {routeEntries.length}{" "}
                  {routeEntries.length > 1 ? <>trips</> : <>trip</>}
                </Typography>
              </div>
            </div>
          </>
        ) : (
          <div className="chosen_graph_center">
            {isNaN(route.name) ? (
              <Typography align="center" style={{ padding: "20px 0" }}>
                There is no ridership data <br></br> for {routeName}{" "}
                {route.description}
              </Typography>
            ) : (
              <Typography align="center" style={{ padding: "20px 0" }}>
                There is no ridership data <br></br> for Route {routeName}{" "}
                {route.description}
              </Typography>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChosenRouteGraph;
