import React, { useState, useContext, useEffect, useRef } from "react";
import { VariablesContext } from "../chosenVariablesContext";
import { Button, Grid, Typography } from "@mui/material";
import RouteGraph from "./routeGraph";
import AllRoutesGraph from "./allRoutesGraph";
import AllPlacesGraph from "./allPlacesGraph";
import ChosenRouteGraph from "./chosenRouteGraph";
import ChosenPlaceGraph from "./chosenPlaceGraph";
import ReactToPrint from "react-to-print";

const RouteReport = () => {
  const {
    chosenVariables,
    dateSelect,
    shuttleSelect,
    entries: allEntries,
    places,
    routesByShuttle,
    isLoading,
  } = useContext(VariablesContext);

  const pdfRef = useRef();
  const [printPreview, setPrintPreview] = useState(false);

  const filteredEntries = allEntries.filter((each1) =>
    each1.shuttle_id.includes(chosenVariables.shuttle_id),
  );

  const times = filteredEntries.map((entry) => entry.time.slice(11, 15));
  const counts = filteredEntries.map((entry) => entry.attendee_count);
  const entryRoutes = filteredEntries.map((entry) => entry.route.name);
  const entryPlaces = filteredEntries.map((entry) => entry.place_id);
  const placeNames = filteredEntries.map((entry) => entry.place.name);
  const isolatedAll = [];
  const isolatedRoutes = [];
  const isolatedPlaces = [];

  for (let i = 0; i < filteredEntries.length; i++) {
    isolatedAll.push({ x: `${times[i]}0`, y: counts[i] });
    isolatedRoutes.push({
      x: `${times[i]}${entryRoutes[i]}`,
      y: counts[i],
      z: entryRoutes[i],
    });
    isolatedPlaces.push({
      x: `${times[i]}${entryPlaces[i]}`,
      y: counts[i],
      z: placeNames[i],
    });
  }

  const holderAll = {};
  const holderRoutes = {};
  const holderPlaces = {};

  isolatedAll.forEach(function (a) {
    if (holderAll.hasOwnProperty(a.x)) {
      holderAll[a.x] = holderAll[a.x] + a.y;
    } else {
      holderAll[a.x] = a.y;
    }
  });

  isolatedRoutes.forEach(function (a) {
    if (holderRoutes.hasOwnProperty(a.x)) {
      holderRoutes[a.x] = holderRoutes[a.x] + a.y;
    } else {
      holderRoutes[a.x] = a.y;
    }
  });

  isolatedPlaces.forEach(function (a) {
    if (holderPlaces.hasOwnProperty(a.x)) {
      holderPlaces[a.x] = holderPlaces[a.x] + a.y;
    } else {
      holderPlaces[a.x] = a.y;
    }
  });

  const accumulatedAll = [];
  const accumulatedRoutes = [];
  const accumulatedPlaces = [];

  for (const prop in holderAll) {
    accumulatedAll.push({ x: prop, y: holderAll[prop] });
  }

  for (const prop in holderRoutes) {
    accumulatedRoutes.push({ x: prop, y: holderRoutes[prop] });
  }

  for (const prop in holderPlaces) {
    accumulatedPlaces.push({ x: prop, y: holderPlaces[prop] });
  }

  let xMin = "";
  let xMax = "";
  if (accumulatedAll.length !== 0) {
    xMin = parseInt(accumulatedAll[0].x);
    xMax = parseInt(accumulatedAll[accumulatedAll.length - 1].x) + 1;
  }

  const xRange = xMax - xMin + 1;
  let smallGraphXRange = xMax - xMin + 1;

  if (smallGraphXRange > 16) {
    smallGraphXRange = smallGraphXRange / 2;
  }

  const timeSpanIntervals = [];
  for (let j = xMin; j < xMax; j++) {
    for (let i = 0; i < 6; i++) {
      if (j < 10) {
        timeSpanIntervals.push(`0${j}:${i}0`);
      } else {
        timeSpanIntervals.push(`${j}:${i}0`);
      }
    }
  }

  let yMaxRoutes = 0;
  let yMaxPlaces = 0;

  yMaxRoutes = Math.max.apply(
    Math,
    accumulatedRoutes.map(function (o) {
      return o.y;
    }),
  );

  yMaxPlaces = Math.max.apply(
    Math,
    accumulatedPlaces.map(function (o) {
      return o.y;
    }),
  );

  const [mode, setMode] = useState(true);
  const handleModeChange = () => {
    setMode(!mode);
  };

  const [chosenRouteGraph, setChosenRouteGraph] = useState(false, false);
  const [chosenPlaceGraph, setChosenPlaceGraph] = useState(false, false);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setChosenRouteGraph(false);
        setChosenPlaceGraph(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  });

  const printPdf = () => {
    return (
      <>
        {filteredEntries.length !== 0 && !isLoading && (
          <>
            <div className="drop_downs">
              <Grid container spacing={3}>
                {dateSelect(true)}
                {shuttleSelect(true)}
              </Grid>
            </div>
            <div style={{ marginTop: "15px" }}>
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "5px" }}
                  >
                    Print PDF
                  </Button>
                )}
                content={() => pdfRef.current}
              />
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: "5px 5px 0 5px" }}
                onClick={() => setPrintPreview(false)}
              >
                Exit Preview
              </Button>
              <Button
                onClick={handleModeChange}
                variant="contained"
                color="primary"
                style={{ marginTop: "5px" }}
              >
                {mode ? <>View Location Graphs</> : <>View Route Graphs</>}
              </Button>
            </div>
            <div ref={pdfRef} style={{ width: "40rem", margin: "40px auto" }}>
              <Typography variant="h6">
                {chosenVariables.event_name} {chosenVariables.event_date_name}{" "}
                {chosenVariables.shuttle_name} Shuttle Ridership Graphs{" "}
                {mode ? "By Route" : "By Location"}
              </Typography>
              <div className="graph">
                <RouteGraph
                  key="1"
                  filteredEntries={filteredEntries}
                  accumulated={accumulatedAll}
                  xRange={xRange}
                  timeSpanIntervals={timeSpanIntervals}
                />
              </div>
              {mode ? (
                <div className="route_cards_print">
                  {routesByShuttle.map((route, i) => (
                    <AllRoutesGraph
                      key={route.id}
                      route={route}
                      filteredEntries={isolatedRoutes}
                      times={times}
                      counts={counts}
                      xRange={smallGraphXRange}
                      timeSpanIntervals={timeSpanIntervals}
                      yMax={yMaxRoutes}
                      setChosenRouteGraph={setChosenRouteGraph}
                      printPreview={printPreview}
                      i={i}
                    />
                  ))}
                </div>
              ) : (
                <div className="route_cards_print">
                  {places &&
                    places
                      .filter(({ route_id }) =>
                        routesByShuttle.find(({ id }) => id === route_id),
                      )
                      .map((place, i) => (
                        <AllPlacesGraph
                          key={place.id}
                          place={place}
                          filteredEntries={isolatedPlaces}
                          times={times}
                          counts={counts}
                          xRange={smallGraphXRange}
                          timeSpanIntervals={timeSpanIntervals}
                          yMax={yMaxPlaces}
                          setChosenPlaceGraph={setChosenPlaceGraph}
                          printPreview={printPreview}
                          i={i}
                        />
                      ))}
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <>
      {!printPreview ? (
        <div>
          {(chosenRouteGraph || chosenPlaceGraph) && (
            <div className="overlay"></div>
          )}
          {chosenRouteGraph && (
            <ChosenRouteGraph
              key={chosenRouteGraph.id}
              route={chosenRouteGraph}
              filteredEntries={isolatedRoutes}
              times={times}
              counts={counts}
              xRange={xRange}
              timeSpanIntervals={timeSpanIntervals}
              yMax={yMaxRoutes}
              setChosenRouteGraph={setChosenRouteGraph}
              routes={routesByShuttle}
            />
          )}
          {chosenPlaceGraph && (
            <ChosenPlaceGraph
              key={chosenPlaceGraph.id}
              place={chosenPlaceGraph}
              filteredEntries={isolatedPlaces}
              times={times}
              counts={counts}
              xRange={xRange}
              timeSpanIntervals={timeSpanIntervals}
              yMax={yMaxPlaces}
              setChosenPlaceGraph={setChosenPlaceGraph}
              places={places.filter(({ route_id }) =>
                routesByShuttle.find(({ id }) => id === route_id),
              )}
            />
          )}
          <Typography variant="h5">Ridership Graphs</Typography>
          <div className="drop_downs">
            <Grid container spacing={3}>
              {dateSelect(true)}
              {shuttleSelect(true)}
            </Grid>
          </div>
          {filteredEntries.length !== 0 && !isLoading ? (
            <div>
              <div className="graph">
                <RouteGraph
                  filteredEntries={filteredEntries}
                  accumulated={accumulatedAll}
                  xRange={xRange}
                  timeSpanIntervals={timeSpanIntervals}
                />
              </div>
              <div
                style={{
                  marginBottom: 40,
                }}
              >
                <div className="no_mobile_text">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setPrintPreview(true)}
                    style={{ margin: "5px 5px 0 0" }}
                  >
                    Print Preview
                  </Button>
                </div>
                <Button
                  onClick={handleModeChange}
                  variant="contained"
                  color="primary"
                  style={{ margin: "5px 0 0 0" }}
                >
                  {mode ? <>View Location Graphs</> : <>View Route Graphs</>}
                </Button>
              </div>
              {mode ? (
                <div className="route_cards">
                  {routesByShuttle.map((route, i) => (
                    <AllRoutesGraph
                      key={route.id}
                      route={route}
                      filteredEntries={isolatedRoutes}
                      times={times}
                      counts={counts}
                      xRange={smallGraphXRange}
                      timeSpanIntervals={timeSpanIntervals}
                      yMax={yMaxRoutes}
                      setChosenRouteGraph={setChosenRouteGraph}
                      printPreview={printPreview}
                      i={i}
                    />
                  ))}
                </div>
              ) : (
                <div className="route_cards">
                  {places &&
                    places
                      .filter(({ route_id }) =>
                        routesByShuttle.find(({ id }) => id === route_id),
                      )
                      .map((place, i) => (
                        <AllPlacesGraph
                          key={place.id}
                          place={place}
                          filteredEntries={isolatedPlaces}
                          times={times}
                          counts={counts}
                          xRange={smallGraphXRange}
                          timeSpanIntervals={timeSpanIntervals}
                          yMax={yMaxPlaces}
                          setChosenPlaceGraph={setChosenPlaceGraph}
                          printPreview={printPreview}
                          i={i}
                        />
                      ))}
                </div>
              )}
            </div>
          ) : (
            <div align="center" style={{ fontSize: 20, marginTop: "32px" }}>
              {isLoading ? (
                <div style={{ color: "green" }}>Results are loading...</div>
              ) : (
                <div style={{ color: "red" }}>
                  There is no ridership data matching the applied filters
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        printPdf()
      )}
    </>
  );
};

export default RouteReport;
