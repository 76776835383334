import React, { useState, useContext, useEffect } from "react";
import { VariablesContext } from "../chosenVariablesContext";
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  AddCircleOutline,
  RemoveCircleOutline,
  DeleteOutlined,
} from "@mui/icons-material";
import { FaRegEdit, FaExchangeAlt } from "react-icons/fa";
import IsoIcon from "@mui/icons-material/Iso";
import AssignmentDetailsEditForm from "../forms/assignmentDetailsEditForm";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import RemoveAssignment from "../hooks/removeAssignment";

const AssignmentList = ({
  authLevel,
  driverToEdit,
  setDriverToEdit,
  showAssignmentDetailsEditForm,
  setShowAssignmentDetailsEditForm,
  handleShowAssignmentDetailsEditForm,
  driverChanges,
  setDriverChanges,
  vehicleToEdit,
  setVehicleToEdit,
  vehicleChanges,
  setVehicleChanges,
  assignmentToEdit,
  handleShowAssignmentEditForm,
}) => {
  const {
    chosenVariables,
    dateSelect,
    shuttleSelect,
    routeSelect,
    assignments,
    discardedAssignments,
    getAssignments,
  } = useContext(VariablesContext);

  const {
    deleteModal,
    objectToDelete,
    setObjectToDelete,
    handleShowDeleteModal,
    addBackModal,
    setObjectToAddBack,
    objectToAddBack,
    handleShowAddBackModal,
  } = RemoveAssignment();

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setObjectToDelete(false);
        setObjectToAddBack(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  });

  const [searchField, setSearchField] = useState("");

  const handleSearchChange = ({ target: { value } }) => {
    setSearchField(value.toLowerCase());
  };

  let discardedFilteredAssignments = discardedAssignments
    .filter(
      (each2) =>
        each2.driver.name?.toLowerCase().includes(searchField) ||
        each2.driver.notes?.toLowerCase().includes(searchField) ||
        each2.vehicle.company?.toLowerCase().includes(searchField) ||
        each2.vehicle.number.includes(searchField),
    )
    .filter(({ shuttle_id }) => shuttle_id.includes(chosenVariables.shuttle_id))
    .filter(({ route_id }) => route_id.includes(chosenVariables.route_id));

  let filteredAssignments = assignments
    .filter(
      (each2) =>
        each2.driver.name?.toLowerCase().includes(searchField) ||
        each2.driver.notes?.toLowerCase().includes(searchField) ||
        each2.vehicle.company?.toLowerCase().includes(searchField) ||
        each2.vehicle.number.includes(searchField),
    )
    .filter(({ shuttle_id }) => shuttle_id.includes(chosenVariables.shuttle_id))
    .filter(({ route_id }) => route_id.includes(chosenVariables.route_id));

  const [sortField, setSortField] = useState(null);

  if (sortField === "driver") {
    filteredAssignments.sort((a, b) =>
      a.driver.name.localeCompare(b.driver.name),
    );
    discardedFilteredAssignments.sort((a, b) =>
      a.driver.name.localeCompare(b.driver.name),
    );
  } else if (sortField === "vehicle") {
    filteredAssignments
      .sort((a, b) => a.vehicle.company.localeCompare(b.vehicle.company))
      .sort((a, b) => a.vehicle.number.localeCompare(b.vehicle.number));
    discardedFilteredAssignments
      .sort((a, b) => a.vehicle.company.localeCompare(b.vehicle.company))
      .sort((a, b) => a.vehicle.number.localeCompare(b.vehicle.number));
  } else if (sortField === "shuttle") {
    filteredAssignments.sort((a, b) =>
      a.shuttle.name.localeCompare(b.shuttle.name),
    );
    discardedFilteredAssignments.sort((a, b) =>
      a.shuttle.name.localeCompare(b.shuttle.name),
    );
  } else if (sortField === "route") {
    filteredAssignments.sort((a, b) =>
      a.route.name.localeCompare(b.route.name),
    );
    discardedFilteredAssignments.sort((a, b) =>
      a.route.name.localeCompare(b.route.name),
    );
  } else if (sortField === "start_time") {
    filteredAssignments.sort((a, b) =>
      a.start_time.localeCompare(b.start_time),
    );
    discardedFilteredAssignments.sort((a, b) =>
      a.start_time.localeCompare(b.start_time),
    );
  } else if (sortField === "end_time") {
    filteredAssignments.sort((a, b) => a.end_time.localeCompare(b.end_time));
    discardedFilteredAssignments.sort((a, b) =>
      a.end_time.localeCompare(b.end_time),
    );
  } else if (sortField === "local") {
    filteredAssignments.sort((a, b) =>
      a.driver.isLocal === b.driver.isLocal ? 0 : a.driver.isLocal ? -1 : 1,
    );
    discardedFilteredAssignments.sort((a, b) =>
      a.driver.isLocal === b.driver.isLocal ? 0 : a.driver.isLocal ? -1 : 1,
    );
  } else if (sortField === "ada") {
    filteredAssignments.sort((a, b) =>
      a.vehicle.isAda === b.vehicle.isAda ? 0 : a.vehicle.isAda ? -1 : 1,
    );
    discardedFilteredAssignments.sort((a, b) =>
      a.vehicle.isAda === b.vehicle.isAda ? 0 : a.vehicle.isAda ? -1 : 1,
    );
  }

  const sortableHeader = (type, title) => {
    return (
      <TableCell
        className="action_icon"
        style={
          sortField === type
            ? { fontWeight: 600, backgroundColor: "lightgray" }
            : { fontWeight: 600 }
        }
        onClick={() => setSortField(type)}
      >
        {title}
      </TableCell>
    );
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const csvExport = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  const active = [
    {
      Driver: "Active",
      Phone: null,
      Vehicle: null,
      Shuttle: null,
      Route: null,
      "Start Time": null,
      "End Time": null,
      "Local Driver": null,
      "ADA Vehicle": null,
      Notes: null,
    },
  ];

  const assignmentsToExport = filteredAssignments.map((assignment) => ({
    Driver: assignment.driver.name,
    Phone: assignment.driver.phone_number,
    Vehicle: `${assignment.vehicle.company} ${assignment.vehicle.number}`,
    Shuttle: assignment.shuttle.name,
    Route: assignment.route.name,
    "Start Time": assignment.start_time.slice(11, 16),
    "End Time": assignment.end_time.slice(11, 16),
    "Local Driver": assignment.driver.isLocal,
    "ADA Vehicle": assignment.vehicle.isAda,
    Notes: assignment.driver.notes,
  }));

  const onBreak = [
    {
      Driver: "On Break",
      Phone: null,
      Vehicle: null,
      Shuttle: null,
      Route: null,
      "Start Time": null,
      "End Time": null,
      "Local Driver": null,
      "ADA Vehicle": null,
      Notes: null,
    },
  ];

  const onBreakToExport = discardedFilteredAssignments
    .filter(({ status }) => status === "on_break")
    .map((assignment) => ({
      Driver: assignment.driver.name,
      Phone: assignment.driver.phone_number,
      Vehicle: `${assignment.vehicle.company} ${assignment.vehicle.number}`,
      Shuttle: assignment.shuttle.name,
      Route: assignment.route.name,
      "Start Time": assignment.start_time.slice(11, 16),
      "End Time": assignment.end_time.slice(11, 16),
      "Local Driver": assignment.driver.isLocal,
      "ADA Vehicle": assignment.vehicle.isAda,
      Notes: assignment.driver.notes,
    }));

  const released = [
    {
      Driver: "Released",
      Phone: null,
      Vehicle: null,
      Shuttle: null,
      Route: null,
      "Start Time": null,
      "End Time": null,
      "Local Driver": null,
      "ADA Vehicle": null,
      Notes: null,
    },
  ];

  const releasedToExport = discardedFilteredAssignments
    .filter(({ status }) => status === "released")
    .map((assignment) => ({
      Driver: assignment.driver.name,
      Phone: assignment.driver.phone_number,
      Vehicle: `${assignment.vehicle.company} ${assignment.vehicle.number}`,
      Shuttle: assignment.shuttle.name,
      Route: assignment.route.name,
      "Start Time": assignment.start_time.slice(11, 16),
      "End Time": assignment.end_time.slice(11, 16),
      "Local Driver": assignment.driver.isLocal,
      "ADA Vehicle": assignment.vehicle.isAda,
      Notes: assignment.driver.notes,
    }));

  const fullExport = active
    .concat(assignmentsToExport)
    .concat(onBreak)
    .concat(onBreakToExport)
    .concat(released)
    .concat(releasedToExport);

  const renderTable = (assignments, status) => {
    return (
      <>
        {status === "active" ? (
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">
              {filteredAssignments.length === 1
                ? "1 Active Driver"
                : `${filteredAssignments.length} Active Drivers`}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                csvExport(
                  fullExport,
                  `${chosenVariables.event_date_name}_assignments`,
                )
              }
              style={{ margin: "-2px 0 10px 10px", height: "36.5px" }}
            >
              Export<span className="no_mobile_text">&nbsp;CSV</span>
            </Button>
          </div>
        ) : (
          <>
            {status === "on_break" ? (
              <Typography variant="h6" style={{ marginTop: "20px" }}>
                {discardedFilteredAssignments.length === 1
                  ? "1 Driver On Break"
                  : `${discardedFilteredAssignments.filter(({ status }) => status === "on_break").length} Drivers On Break`}
              </Typography>
            ) : (
              <Typography variant="h6" style={{ marginTop: "20px" }}>
                {discardedFilteredAssignments.length === 1
                  ? "1 Released Driver"
                  : `${discardedFilteredAssignments.filter(({ status }) => status === "released").length} Released Drivers`}
              </Typography>
            )}
          </>
        )}
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {sortableHeader("driver", "Driver")}
                {sortableHeader("vehicle", "Vehicle")}
                {chosenVariables.shuttle_id === "" &&
                  sortableHeader("shuttle", "Shuttle")}
                {chosenVariables.route_id === "" &&
                  sortableHeader("route", "Route")}
                {sortableHeader("start_time", "Start Time")}
                {sortableHeader("end_time", "End Time")}
                {sortableHeader("local", "Local")}
                {sortableHeader("ada", "ADA")}
                <TableCell style={{ fontWeight: 600 }}>Notes</TableCell>
                {authLevel !== "staff" && (
                  <TableCell style={{ fontWeight: 600, textAlign: "center" }}>
                    Actions
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {assignments.map((assignment) => (
                <TableRow key={assignment.id} id={assignment.id}>
                  <TableCell>
                    {assignment.driver.name}
                    <div style={{ whiteSpace: "nowrap" }}>
                      <a href={`tel:+1${assignment.driver.phone_number}`}>
                        {assignment.driver.phone_number}
                      </a>
                    </div>
                  </TableCell>
                  <TableCell>
                    {assignment.vehicle.company}
                    <div>{assignment.vehicle.number}</div>
                  </TableCell>
                  {chosenVariables.shuttle_id === "" && (
                    <TableCell>{assignment.shuttle.name}</TableCell>
                  )}
                  {chosenVariables.route_id === "" && (
                    <>
                      {parseInt(assignment.route.name) < 10 ? (
                        <TableCell>{assignment.route.name.slice(-1)}</TableCell>
                      ) : (
                        <TableCell>{assignment.route.name}</TableCell>
                      )}
                    </>
                  )}
                  <TableCell>{assignment.start_time.slice(11, 16)}</TableCell>
                  <TableCell>{assignment.end_time.slice(11, 16)}</TableCell>
                  {assignment.driver.isLocal ? (
                    <TableCell>Local</TableCell>
                  ) : (
                    <TableCell></TableCell>
                  )}
                  {assignment.vehicle.isAda ? (
                    <TableCell>ADA</TableCell>
                  ) : (
                    <TableCell></TableCell>
                  )}
                  <TableCell>{assignment.driver.notes}</TableCell>
                  {authLevel !== "staff" && (
                    <>
                      {assignment.status !== "released" ? (
                        <TableCell>
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <FaRegEdit
                              className="assignment_icon"
                              onClick={() =>
                                handleShowAssignmentDetailsEditForm(assignment)
                              }
                              style={{ fontSize: 20 }}
                            />
                            <FaExchangeAlt
                              className="assignment_icon"
                              onClick={() =>
                                handleShowAssignmentEditForm(assignment)
                              }
                              style={{ fontSize: 17, margin: "0 3px 0 11px" }}
                            />
                            {assignment.status === "active" ? (
                              <RemoveCircleOutline
                                className="assignment_icon"
                                onClick={() =>
                                  handleShowDeleteModal(assignment)
                                }
                                style={{ fontSize: 20, color: "black" }}
                              />
                            ) : (
                              <IsoIcon
                                className="assignment_icon"
                                onClick={() =>
                                  handleShowDeleteModal(assignment)
                                }
                                style={{ fontSize: 20, color: "black" }}
                              />
                            )}
                          </span>
                        </TableCell>
                      ) : (
                        <TableCell>
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <FaRegEdit
                              className="assignment_icon"
                              onClick={() =>
                                handleShowAssignmentDetailsEditForm(assignment)
                              }
                              style={{ fontSize: 20 }}
                            />
                            <AddCircleOutline
                              className="assignment_icon"
                              onClick={() => handleShowAddBackModal(assignment)}
                              style={{ fontSize: 20, color: "black" }}
                            />
                            <DeleteOutlined
                              className="assignment_icon"
                              onClick={() => handleShowDeleteModal(assignment)}
                              style={{ fontSize: 20, color: "black" }}
                            />
                          </span>
                        </TableCell>
                      )}
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  return (
    <div>
      {(objectToDelete || objectToAddBack) && <div className="overlay"></div>}
      {objectToDelete && deleteModal(objectToDelete)}
      {objectToAddBack && addBackModal(objectToAddBack)}
      {(authLevel === "superuser" ||
        authLevel === "admin" ||
        authLevel === "supervisor") && (
        <>
          {showAssignmentDetailsEditForm && (
            <AssignmentDetailsEditForm
              driver={driverToEdit}
              setDriverToEdit={setDriverToEdit}
              driverChanges={driverChanges}
              setDriverChanges={setDriverChanges}
              vehicle={vehicleToEdit}
              setVehicleToEdit={setVehicleToEdit}
              vehicleChanges={vehicleChanges}
              setVehicleChanges={setVehicleChanges}
              assignmentToEdit={assignmentToEdit}
              setShowAssignmentDetailsEditForm={
                setShowAssignmentDetailsEditForm
              }
              getAssignments={getAssignments}
            />
          )}
        </>
      )}
      <Typography variant="h5">Assignment List</Typography>
      <div className="drop_downs">
        <Grid container spacing={3}>
          {dateSelect(true)}
          {shuttleSelect(true)}
          {routeSelect(true)}
          <Grid item xs={12} md={3}>
            <InputLabel>Search (driver/vehicle/notes):</InputLabel>
            <TextField
              variant="standard"
              type="text"
              fullWidth
              onChange={handleSearchChange}
              value={searchField}
            />
          </Grid>
        </Grid>
      </div>
      {filteredAssignments && renderTable(filteredAssignments, "active")}
      {discardedFilteredAssignments.filter(
        ({ status }) => status === "on_break",
      ) &&
        renderTable(
          discardedFilteredAssignments.filter(
            ({ status }) => status === "on_break",
          ),
          "on_break",
        )}
      {discardedFilteredAssignments.filter(
        ({ status }) => status === "released",
      ) &&
        renderTable(
          discardedFilteredAssignments.filter(
            ({ status }) => status === "released",
          ),
          "released",
        )}
    </div>
  );
};

export default AssignmentList;
