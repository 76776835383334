import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { VariablesContext } from "./chosenVariablesContext";
import { Close, ContentCopy } from "@mui/icons-material";
import { NotificationManager } from "react-notifications";
import useApi from "./api/api";

const EventSetter = () => {
  const api = useApi();
  const navigate = useNavigate();
  const { setVariables, setIsLoading } = useContext(VariablesContext);
  const [user, setUser] = useState({
    username: "",
    password: "Wii7e6Z39yyaBi",
  });

  const handleCodeChange = ({ target: { id, value } }) => {
    setUser({
      ...user,
      [id]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await api.post("sign_in", { user });
      if (response.data?.event_id) {
        if (response.data.auth_name === "client") {
          setVariables({
            event_id: response.data.event_id,
            event_name: response.data.event.name,
            event_date_id: "",
            shuttle_id: "",
            shuttle_name: "",
            route_id: "",
            place_id: "",
            tokens: `token=${response.data.authentication_token},username=${response.data.username}`,
            start_time: "08:00",
            end_time: "18:00",
            entry_time: "08:00",
          });
          navigate("graph");
        } else {
          setVariables({
            event_id: response.data.event_id,
            event_name: response.data.event.name,
            event_date_id: "",
            shuttle_id: "",
            shuttle_name: "",
            route_id: "",
            place_id: "",
            tokens: `token=${response.data.authentication_token},username=${response.data.username}`,
            start_time: "08:00",
            end_time: "18:00",
            entry_time: "08:00",
          });
          navigate("route-view");
        }
        window.location.reload();
      }
    } catch (error) {
      if (
        JSON.stringify(error).includes("Request failed with status code 500")
      ) {
        NotificationManager.error(
          `No event found with that code.`,
          "Error",
          5000
        );
      } else if (JSON.stringify(error).includes("Network Error")) {
        NotificationManager.error(
          `Network error. Check connection.`,
          "Error",
          5000
        );
      } else {
        NotificationManager.error(
          `Something went wrong. Request not processed.`,
          "Error",
          5000
        );
      }
    }
  };

  const testLogin = (e) => {
    user.username = "admin";
    handleSubmit(e);
  };

  const showCopy = () => {
    if (document.getElementById("click_email").style.display === "none") {
      document.getElementById("copy_email").style.display = "inline";
    }
  };

  const showClick = () => {
    navigator.clipboard.writeText("info@eventtranspo.com");
    document.getElementById("click_email").style.display = "inline";
    document.getElementById("click_email").style.background = "#135413";
    document.getElementById("copy_email").style.display = "none";
    setTimeout(() => {
      document.getElementById("click_email").style.display = "none";
    }, 1000);
  };

  const emailStyle = {
    display: "none",
    background: "black",
    color: "white",
    padding: "3px 5px",
    fontSize: "small",
    borderRadius: "3px",
    marginLeft: "2px",
    whiteSpace: "nowrap",
  };

  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setShowInfo(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  });

  const moreInfo = () => {
    return (
      <div className="info_modal">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "-30px",
          }}
        >
          <Typography variant="h5" style={{ textAlign: "left" }}>
            About Event Transpo Manager
          </Typography>
          <div>
            <Close
              className="route_icon"
              onClick={() => setShowInfo(false)}
              style={{ marginTop: "0", color: "black" }}
            />
          </div>
        </div>
        <div
          style={{
            marginTop: "50px",
            textAlign: "left",
            fontFamily: "sans-serif",
            lineHeight: "1.2rem",
          }}
        >
          The event transportation industry is full of moving pieces. This app
          is designed to tackle its issues simply yet effectively.
          <ul>
            <li style={{ marginBottom: "8px" }}>
              Organize and centralize driver management
            </li>
            <li style={{ marginBottom: "8px" }}>
              Generate timestamped ridership graphs and entry logs
            </li>
            <li style={{ marginBottom: "8px" }}>
              Dynamically control dates, shuttles, routes and locations
            </li>
            <li style={{ marginBottom: "8px" }}>
              Quickly add and edit drivers, vehicles, assignments and ridership
              entries
            </li>
            <li style={{ marginBottom: "8px" }}>
              Instantly share updates across your entire team
            </li>
            <li style={{ marginBottom: "8px" }}>
              Filter and sort all views to keep only the pertinent data in your
              sight
            </li>
            <li style={{ marginBottom: "8px" }}>
              Eradicate wrinkled, weathered, and lost paper logs
            </li>
            <li style={{ marginBottom: "8px" }}>
              Utilize multiple auth levels to share the appropriate amount of
              access to different groups
            </li>
          </ul>
          Do you have an upcoming convention, conference, or other event that
          has transportation related needs? For more information or to request a
          free tour of the app, please send us an email.
          <br></br>
          <br></br>
          <div style={{ height: "22px" }}>
            <a href="mailto:info@eventtranspo.com">info@eventtranspo.com</a>
            <ContentCopy
              className="route_icon"
              style={{ margin: "0 0 -8 15", color: "black" }}
              onClick={() => showClick()}
              onMouseOver={() => showCopy()}
              onMouseLeave={() =>
                (document.getElementById("copy_email").style.display = "none")
              }
            />
            <span id="copy_email" className="no_mobile_text" style={emailStyle}>
              Copy email
            </span>
            <span
              id="click_email"
              className="no_mobile_text"
              style={emailStyle}
            >
              Email copied!
            </span>
          </div>
        </div>
        <Button
          variant="contained"
          color="primary"
          style={{
            marginTop: "30px",
          }}
          onClick={(e) => testLogin(e)}
        >
          Login to Test Event
        </Button>
      </div>
    );
  };

  return (
    <div className="landing_page">
      {showInfo && <div className="overlay"></div>}
      <div>
        {showInfo && moreInfo()}
        <Typography variant="h5" style={{ marginBottom: "40px" }}>
          Welcome to Event Transpo Manager
        </Typography>
        <Typography variant="h6">Enter Event Code</Typography>
        <form onSubmit={handleSubmit}>
          <Grid>
            <Grid>
              <TextField
                variant="standard"
                inputProps={{ style: { textAlign: "center" } }}
                id="username"
                type="password"
                fullWidth
                required
                onChange={handleCodeChange}
              />
            </Grid>
          </Grid>
          <div className="submit_button">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={user.username.length < 3}
            >
              Login
            </Button>
          </div>
        </form>
        <Button
          variant="contained"
          color="info"
          style={{
            marginTop: "50px",
          }}
          onClick={() => setShowInfo(true)}
        >
          More Info
        </Button>
      </div>
    </div>
  );
};

export default EventSetter;
