import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import ChosenVariablesContext from "./chosenVariablesContext";
import NavBar from "./navbar";
import EventSetter from "./eventSetter";
import ManageEvents from "./admin/manageEvents";
import ManageShuttlesAndDates from "./admin/manageShuttlesAndDates";
import ManageRoutes from "./admin/manageRoutes";
import ManagePlaces from "./admin/managePlaces";
import EntryForm from "./forms/entryForm";
import DriverForm from "./forms/driverForm";
import VehicleForm from "./forms/vehicleForm";
import AssignmentForm from "./forms/assignmentForm";
import AssignmentEditForm from "./forms/assignmentEditForm";
import AssignmentUploadForm from "./forms/assignmentUploadForm";
import RouteView from "./manager/routeView";
import AssignmentList from "./manager/assignmentList";
import RouteReport from "./tracker/routeReport";
import Log from "./tracker/log";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import "./styles.css";
import useAPI from "./api/api";

const AppViews = () => {
  const [authLevel, setAuthLevel] = useState(false);
  const [driverId, setDriverId] = useState("");
  const [vehicleId, setVehicleId] = useState("");
  const api = useAPI();

  const [showEntryForm, setShowEntryForm] = useState(false);
  const [showDriverForm, setShowDriverForm] = useState(false);
  const [showVehicleForm, setShowVehicleForm] = useState(false);
  const [showAssignmentForm, setShowAssignmentForm] = useState(false);
  const [showAssignmentEditForm, setShowAssignmentEditForm] = useState(false);
  const [showAssignmentDetailsEditForm, setShowAssignmentDetailsEditForm] =
    useState(false);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const [driverToEdit, setDriverToEdit] = useState({});
  const [vehicleToEdit, setVehicleToEdit] = useState({});
  const [assignmentToEdit, setAssignmentToEdit] = useState({});

  const [driverChanges, setDriverChanges] = useState(false);
  const [vehicleChanges, setVehicleChanges] = useState(false);

  const handleShowAssignmentDetailsEditForm = (assignment) => {
    setShowAssignmentDetailsEditForm(true);
    setDriverToEdit({
      ...assignment.driver,
    });
    setVehicleToEdit({
      ...assignment.vehicle,
    });
    setAssignmentToEdit({
      ...assignment,
    });
  };

  const handleShowAssignmentEditForm = (assignment) => {
    setShowAssignmentEditForm(true);
    setAssignmentToEdit({
      ...assignment,
      start_time: assignment.start_time.slice(11, 16),
      end_time: assignment.end_time.slice(11, 16),
    });
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27 && !showInfo) {
        setShowEntryForm(false);
        setShowDriverForm(false);
        setShowVehicleForm(false);
        setShowAssignmentForm(false);
        setShowAssignmentEditForm(false);
        setShowAssignmentDetailsEditForm(false);
        setShowUploadForm(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  });

  const anyFormShowing =
    showEntryForm ||
    showDriverForm ||
    showAssignmentDetailsEditForm ||
    showVehicleForm ||
    showAssignmentForm ||
    showAssignmentEditForm ||
    showUploadForm;

  useEffect(() => {
    if (
      localStorage.getItem("tokens") &&
      localStorage.getItem("event_id") !== "null"
    ) {
      const getAuthLevel = async () => {
        try {
          const response = await api.get("users/me");
          setAuthLevel(response.data.auth_name);
        } catch {
          console.warn("get user error");
        }
      };
      getAuthLevel();
    }
  }, [api]);

  return (
    <BrowserRouter>
      <ChosenVariablesContext>
        <NavBar
          authLevel={authLevel}
          setShowEntryForm={setShowEntryForm}
          setShowDriverForm={setShowDriverForm}
          setShowUploadForm={setShowUploadForm}
        />
        {localStorage.getItem("event_id") && authLevel ? (
          <div>
            {anyFormShowing && <div className="overlay"></div>}
            {showEntryForm && <EntryForm setShowEntryForm={setShowEntryForm} />}
            {showDriverForm && (
              <DriverForm
                setDriverId={setDriverId}
                setShowDriverForm={setShowDriverForm}
                setShowVehicleForm={setShowVehicleForm}
              />
            )}
            {showVehicleForm && (
              <VehicleForm
                setVehicleId={setVehicleId}
                setShowVehicleForm={setShowVehicleForm}
                setShowAssignmentForm={setShowAssignmentForm}
              />
            )}
            {showAssignmentForm && (
              <AssignmentForm
                setShowAssignmentForm={setShowAssignmentForm}
                driverId={driverId}
                vehicleId={vehicleId}
              />
            )}
            {showAssignmentEditForm && (
              <AssignmentEditForm
                assignment={assignmentToEdit}
                setAssignmentToEdit={setAssignmentToEdit}
                setShowAssignmentEditForm={setShowAssignmentEditForm}
              />
            )}
            {showUploadForm && (
              <AssignmentUploadForm
                showInfo={showInfo}
                setShowInfo={setShowInfo}
                setShowUploadForm={setShowUploadForm}
              />
            )}
            <Routes>
              <Route path="log" element={<Log authLevel={authLevel} />} />
              <Route path="graph" element={<RouteReport />} />
              {authLevel === "client" && (
                <Route path="*" element={<Navigate to="graph" />} />
              )}
              {authLevel !== "client" && (
                <>
                  <Route path="*" element={<Navigate to="route-view" />} />
                  <Route
                    path="route-view"
                    element={
                      <RouteView
                        authLevel={authLevel}
                        driverToEdit={driverToEdit}
                        setDriverToEdit={setDriverToEdit}
                        showAssignmentDetailsEditForm={
                          showAssignmentDetailsEditForm
                        }
                        setShowAssignmentDetailsEditForm={
                          setShowAssignmentDetailsEditForm
                        }
                        handleShowAssignmentDetailsEditForm={
                          handleShowAssignmentDetailsEditForm
                        }
                        driverChanges={driverChanges}
                        setDriverChanges={setDriverChanges}
                        vehicleToEdit={vehicleToEdit}
                        setVehicleToEdit={setVehicleToEdit}
                        vehicleChanges={vehicleChanges}
                        setVehicleChanges={setVehicleChanges}
                        assignmentToEdit={assignmentToEdit}
                        handleShowAssignmentEditForm={
                          handleShowAssignmentEditForm
                        }
                        anyFormShowing={anyFormShowing}
                      />
                    }
                  />
                  <Route
                    path="assignment-list"
                    element={
                      <AssignmentList
                        authLevel={authLevel}
                        driverToEdit={driverToEdit}
                        setDriverToEdit={setDriverToEdit}
                        showAssignmentDetailsEditForm={
                          showAssignmentDetailsEditForm
                        }
                        setShowAssignmentDetailsEditForm={
                          setShowAssignmentDetailsEditForm
                        }
                        handleShowAssignmentDetailsEditForm={
                          handleShowAssignmentDetailsEditForm
                        }
                        driverChanges={driverChanges}
                        setDriverChanges={setDriverChanges}
                        vehicleToEdit={vehicleToEdit}
                        setVehicleToEdit={setVehicleToEdit}
                        vehicleChanges={vehicleChanges}
                        setVehicleChanges={setVehicleChanges}
                        assignmentToEdit={assignmentToEdit}
                        handleShowAssignmentEditForm={
                          handleShowAssignmentEditForm
                        }
                      />
                    }
                  />
                  <Route path="entry/form" element={<EntryForm />} />
                </>
              )}
              {(authLevel === "superuser" || authLevel === "admin") && (
                <>
                  <Route
                    path="dates&shuttles"
                    element={<ManageShuttlesAndDates />}
                  />
                  <Route path="routes" element={<ManageRoutes />} />
                  <Route path="locations" element={<ManagePlaces />} />
                </>
              )}
              {authLevel === "superuser" && (
                <Route path="events" element={<ManageEvents />} />
              )}
            </Routes>
          </div>
        ) : (
          <EventSetter />
        )}
      </ChosenVariablesContext>
      <NotificationContainer />
    </BrowserRouter>
  );
};

export default AppViews;
