import React, { useState, useContext, useEffect } from "react";
import { VariablesContext } from "../chosenVariablesContext";
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  checkboxClasses,
} from "@mui/material";
import { Close, DeleteOutlined } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useApi from "../api/api";
import { NotificationManager } from "react-notifications";
import ConfirmDelete from "../hooks/confirmDelete";
import ConfirmCancel from "../hooks/confirmCancel";

const ManageShuttlesAndDates = () => {
  const api = useApi();
  const theme = createTheme();

  const {
    dates,
    getDates,
    shuttles,
    getShuttles,
    shuttleDates,
    getShuttleDates,
    getShuttleRoutes,
    chosenVariables,
    setVariables,
  } = useContext(VariablesContext);

  const [alterDatesObj, setAlterDatesObj] = useState({});
  const [alterShuttlesObj, setAlterShuttlesObj] = useState({});
  const [alterShuttleDatesObj, setAlterShuttleDatesObj] = useState({});

  const {
    deleteModal,
    objectToDelete,
    setObjectToDelete,
    handleShowDeleteModal,
  } = ConfirmDelete();

  const confirmActions = () => {
    setAlterDatesObj({});
    setAlterShuttlesObj({});
    setAlterShuttleDatesObj({});
  };

  const {
    cancelModal,
    showCancelModal,
    setShowCancelModal,
    handleShowCancelModal,
  } = ConfirmCancel(confirmActions);

  const [date, setDate] = useState({
    date: "",
    event_id: chosenVariables.event_id,
  });

  const [shuttle, setShuttle] = useState({
    name: "",
    event_id: chosenVariables.event_id,
  });

  const [showDateForm, setShowDateForm] = useState(false);
  const [showShuttleForm, setShowShuttleForm] = useState(false);

  const handleCloseForm = () => {
    setShowDateForm(false);
    setShowShuttleForm(false);
    setObjectToDelete(false);
    setDate({
      date: "",
      event_id: chosenVariables.event_id,
    });
    setShuttle({
      name: "",
      event_id: chosenVariables.event_id,
    });
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        handleCloseForm();
        setObjectToDelete(false);
        setShowCancelModal(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  });

  const handleNewDateChange = ({ target: { id, value } }) => {
    setDate({
      ...date,
      [id]: value,
    });
  };

  const handleNewShuttleChange = ({ target: { id, value } }) => {
    setShuttle({
      ...shuttle,
      [id]: value,
    });
  };

  const handleDatesUpdate = ({ target: { value } }, date) => {
    setAlterDatesObj({
      ...alterDatesObj,
      [date.id]: {
        ...date,
        date: value,
      },
    });
  };

  const handleShuttlesUpdate = ({ target: { value } }, shuttle) => {
    setAlterShuttlesObj({
      ...alterShuttlesObj,
      [shuttle.id]: {
        ...shuttle,
        name: value,
      },
    });
  };

  const handleCheckChange = (date, shuttle, state, existing) => {
    const ID = `${date.id}*${shuttle.id}`;
    if ((existing && state) || (!existing && !state)) {
      delete alterShuttleDatesObj[ID];
      setAlterShuttleDatesObj({
        ...alterShuttleDatesObj,
      });
    } else if (!existing && state) {
      setAlterShuttleDatesObj({
        ...alterShuttleDatesObj,
        [ID]: {
          event_id: chosenVariables.event_id,
          event_date_id: date.id,
          event_date: date,
          shuttle_id: shuttle.id,
          shuttle: shuttle,
        },
      });
    } else if (existing && !state) {
      setAlterShuttleDatesObj({
        ...alterShuttleDatesObj,
        [ID]: {
          id: existing.id,
          event_date_id: date.id,
          event_date: date,
          shuttle_id: shuttle.id,
          shuttle: shuttle,
        },
      });
    }
  };

  const addDate = async (e) => {
    e.preventDefault();
    try {
      await api.post("event_dates", date);
      NotificationManager.success(
        "Date successfully created.",
        "Success",
        1000
      );
      document.getElementById("date").value = "";
      document.getElementById("date").select();
      setDate({
        date: "",
        event_id: chosenVariables.event_id,
      });
      getDates();
    } catch (error) {
      if (error?.response?.data?.errors?.date) {
        NotificationManager.error(
          `${date.date} already exists in database.`,
          "Error",
          5000
        );
      } else {
        NotificationManager.error(
          "Something went wrong. Date not created.",
          "Error",
          5000
        );
      }
    }
  };

  const addShuttle = async (e) => {
    e.preventDefault();
    try {
      await api.post("shuttles", shuttle);
      NotificationManager.success(
        `${shuttle.name} successfully added.`,
        "Success",
        1000
      );
      document.getElementById("name").value = "";
      document.getElementById("name").select();
      setShuttle({
        name: "",
        event_id: chosenVariables.event_id,
      });
      getShuttles();
      getShuttleDates();
    } catch (error) {
      if (error?.response?.data?.errors?.name) {
        NotificationManager.error(
          `${shuttle.name} already exists in database.`,
          "Error",
          5000
        );
      } else {
        NotificationManager.error(
          "Something went wrong. Shuttle not added.",
          "Error",
          5000
        );
      }
    }
  };

  const handleUpdateAll = () => {
    if (
      Object.values(alterShuttleDatesObj).filter(({ event_id }) => event_id)
        .length
    ) {
      Promise.all(
        Object.values(alterShuttleDatesObj)
          .filter(({ event_id }) => event_id)
          .map(async (shuttle_date) => {
            try {
              await api.post("shuttle_dates", shuttle_date);
              NotificationManager.success(
                `${shuttle_date.shuttle.name} ${shuttle_date.event_date.date} successfully added`,
                "Success",
                1000
              );
            } catch {
              NotificationManager.error(
                `Something went wrong. ${shuttle_date.shuttle.name} ${shuttle_date.event_date.date} not added`,
                "Error",
                5000
              );
            } finally {
              getShuttleDates();
              setAlterShuttleDatesObj({});
            }
          })
      );
    }
    if (Object.values(alterShuttleDatesObj).filter(({ id }) => id).length) {
      Promise.all(
        Object.values(alterShuttleDatesObj)
          .filter(({ id }) => id)
          .map(async (shuttle_date) => {
            try {
              await api.delete(`shuttle_dates/${shuttle_date.id}`);
              NotificationManager.success(
                `${shuttle_date.shuttle.name} ${shuttle_date.event_date.date} successfully removed`,
                "Success",
                1000
              );
            } catch (error) {
              if (error?.response?.status === 499) {
                NotificationManager.error(
                  error.response.data.errors,
                  "Error",
                  10000
                );
              } else {
                NotificationManager.error(
                  `Something went wrong. ${shuttle_date.shuttle.name} ${shuttle_date.event_date.date} not removed`,
                  "Error",
                  5000
                );
              }
            } finally {
              setTimeout(() => {
                getShuttleDates();
                setAlterShuttleDatesObj({});
                setVariables({
                  ...chosenVariables,
                  shuttle_id: "",
                  route_id: "",
                  place_id: "",
                });
              }, 1000);
            }
          })
      );
    }
    if (Object.keys(alterDatesObj).length) {
      Promise.all(
        Object.values(alterDatesObj).map(async (event_date) => {
          try {
            await api.put(`event_dates/${event_date.id}`, { event_date });
            NotificationManager.success(
              `${event_date.date} successfully updated.`,
              "Success",
              1000
            );
          } catch (error) {
            if (error.response.data.errors.date) {
              NotificationManager.error(
                "This date has already been added.",
                "Failure",
                5000
              );
            }
          } finally {
            getDates();
            getShuttleDates();
            setAlterDatesObj({});
          }
        })
      );
    }
    if (Object.keys(alterShuttlesObj).length) {
      Promise.all(
        Object.values(alterShuttlesObj).map(async (shuttle) => {
          try {
            await api.put(`shuttles/${shuttle.id}`, { shuttle });
            NotificationManager.success(
              `${shuttle.name} successfully updated.`,
              "Success",
              1000
            );
          } catch (error) {
            if (error.response.data.errors.name) {
              NotificationManager.error(
                "There is already a shuttle with this name.",
                "Failure",
                5000
              );
            }
          } finally {
            getShuttles();
            getShuttleDates();
            getShuttleRoutes();
            setAlterShuttlesObj({});
          }
        })
      );
    }
  };

  // const handleUpdateAll = (e) => {
  //   e.preventDefault();
  //   handleUpdateAll();
  //   deleteShuttleDates();
  //   updateDates();
  //   updateShuttles();
  // };

  const dateForm = () => {
    return (
      <div className="modal">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">Add New Date</Typography>
          <div>
            <Close
              className="route_icon"
              onClick={handleCloseForm}
              style={{ marginTop: "0", color: "black" }}
            />
          </div>
        </div>
        <form className="drop_downs">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputLabel>Date:</InputLabel>
              <TextField
                variant="standard"
                id="date"
                type="date"
                fullWidth
                required
                onChange={handleNewDateChange}
              />
            </Grid>
          </Grid>
          <div className="submit_button" style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              disabled={!date.date}
              onClick={(e) => addDate(e)}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  };

  const shuttleForm = () => {
    return (
      <div className="modal">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">Add New Shuttle</Typography>
          <div>
            <Close
              className="route_icon"
              onClick={handleCloseForm}
              style={{ marginTop: "0", color: "black" }}
            />
          </div>
        </div>
        <form className="drop_downs">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputLabel>Name:</InputLabel>
              <TextField
                variant="standard"
                id="name"
                fullWidth
                required
                onChange={handleNewShuttleChange}
              />
            </Grid>
          </Grid>
          <div className="submit_button" style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              disabled={!shuttle.name}
              onClick={(e) => addShuttle(e)}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      {(showDateForm ||
        showShuttleForm ||
        objectToDelete ||
        showCancelModal) && <div className="overlay"></div>}
      {showDateForm && dateForm()}
      {showShuttleForm && shuttleForm()}
      {objectToDelete &&
        (objectToDelete.name
          ? deleteModal(objectToDelete, "Shuttle", "shuttles")
          : deleteModal(objectToDelete, "Date", "event_dates"))}
      {showCancelModal && cancelModal()}
      <div
        className="skinny_stack"
        style={{
          justifyContent: "space-between",
          marginTop: "1.5rem",
        }}
      >
        <Typography variant="h5">Manage Dates & Shuttles</Typography>
        {Object.keys(alterDatesObj).length === 0 &&
        Object.keys(alterShuttlesObj).length === 0 &&
        Object.keys(alterShuttleDatesObj).length === 0 ? (
          <span style={{ display: "flex" }} className="skinny_stack">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowDateForm(true)}
              style={{ margin: "0 5px", height: 38, whiteSpace: "nowrap" }}
            >
              Add Date
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowShuttleForm(true)}
              style={{ margin: "0 0 0 5px", height: 38, whiteSpace: "nowrap" }}
            >
              Add Shuttle
            </Button>
          </span>
        ) : (
          <span style={{ display: "flex" }} className="skinny_stack">
            <Button
              color="secondary"
              variant="contained"
              onClick={handleShowCancelModal}
              style={{ margin: "0 5px", height: 38 }}
            >
              Discard
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleUpdateAll}
              style={{ margin: "0 0 0 5px", height: 38 }}
            >
              Update
            </Button>
          </span>
        )}
      </div>
      <Typography style={{ margin: "15px 0" }} variant="h6">
        {dates.length} dates, {shuttles.length} shuttles
      </Typography>
      <div style={{ display: "flex", overflowX: "scroll" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  height: 30,
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dates.map((date) => {
              const dateToRender = alterDatesObj[date.id] || date;
              return (
                <TableRow
                  key={dateToRender.id}
                  style={
                    alterDatesObj[dateToRender.id] && {
                      backgroundColor: "lightgray",
                    }
                  }
                >
                  <TableCell>
                    <TextField
                      variant="standard"
                      type="date"
                      onChange={(e) => handleDatesUpdate(e, dateToRender)}
                      value={dateToRender.date}
                      inputProps={{ style: { fontSize: 14 } }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell
                style={{
                  height: 32,
                }}
              ></TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {shuttles.map((shuttle) => {
          const shuttleToRender = alterShuttlesObj[shuttle.id] || shuttle;
          return (
            <Table size="small" key={shuttleToRender.id}>
              <TableHead
                style={
                  alterShuttlesObj[shuttleToRender.id] && {
                    backgroundColor: "lightgray",
                  }
                }
              >
                <TableRow>
                  <TableCell
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      variant="standard"
                      required
                      onChange={(e) => handleShuttlesUpdate(e, shuttleToRender)}
                      value={shuttleToRender.name}
                      style={{
                        height: 30,
                      }}
                      inputProps={{ style: { fontSize: 14 } }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dates.map((date) => {
                  const existing = shuttleDates.find(
                    (one) =>
                      one.shuttle_id === shuttle.id &&
                      one.event_date_id === date.id
                  );
                  const changed = Object.values(alterShuttleDatesObj).find(
                    (one) =>
                      one.shuttle_id === shuttle.id &&
                      one.event_date_id === date.id
                  );
                  return (
                    <TableRow
                      key={date.id}
                      style={
                        changed && {
                          backgroundColor: "lightgray",
                        }
                      }
                    >
                      <ThemeProvider theme={theme}>
                        {(!!existing && !changed) ||
                        (!!changed && changed.event_id) ? (
                          <TableCell
                            style={{ textAlign: "center", color: "green" }}
                          >
                            <Checkbox
                              onChange={() =>
                                handleCheckChange(
                                  date,
                                  shuttle,
                                  false,
                                  existing
                                )
                              }
                              checked={true}
                              sx={{
                                [`&, &.${checkboxClasses.checked}`]: {
                                  color: "#135413",
                                },
                              }}
                            />
                          </TableCell>
                        ) : (
                          <TableCell style={{ textAlign: "center" }}>
                            <Checkbox
                              onChange={() =>
                                handleCheckChange(date, shuttle, true, existing)
                              }
                              checked={false}
                            />
                          </TableCell>
                        )}
                      </ThemeProvider>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell
                    style={{
                      display: "flex",
                      height: 32,
                      justifyContent: "center",
                    }}
                  >
                    <span className="action_icon">
                      <DeleteOutlined
                        style={{ color: "black" }}
                        onClick={() => handleShowDeleteModal(shuttle)}
                      />
                    </span>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          );
        })}
        <Table size="small" style={{ width: "30%" }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  height: 30,
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dates.map((date) => {
              return (
                <TableRow key={date.id}>
                  <TableCell
                    style={{
                      height: 42,
                    }}
                  >
                    <span className="action_icon">
                      <DeleteOutlined
                        style={{ color: "black" }}
                        onClick={() => handleShowDeleteModal(date)}
                      />
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow key={date.id}>
              <TableCell
                style={{
                  height: 32,
                }}
              ></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default ManageShuttlesAndDates;
