import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Close, DeleteOutlined } from "@mui/icons-material";
import useApi from "../api/api";
import { NotificationManager } from "react-notifications";
import { VariablesContext } from "../chosenVariablesContext";
import ConfirmDelete from "../hooks/confirmDelete";
import ConfirmCancel from "../hooks/confirmCancel";

const ManageEvents = () => {
  const api = useApi();
  const { events, getEvents, chosenVariables, handleEventSelect } =
    useContext(VariablesContext);

  const [authLevels, setAuthLevels] = useState([]);
  const [alterCodesObj, setAlterCodesObj] = useState({});
  const [alterItemsObj, setAlterItemsObj] = useState({});

  const [event, setEvent] = useState({
    name: "",
  });

  const {
    deleteModal,
    objectToDelete,
    setObjectToDelete,
    handleShowDeleteModal,
  } = ConfirmDelete();

  const confirmActions = () => {
    setAlterItemsObj({});
  };

  const {
    cancelModal,
    showCancelModal,
    setShowCancelModal,
    handleShowCancelModal,
  } = ConfirmCancel(confirmActions);

  const [showForm, setShowForm] = useState(false);

  const handleCloseForm = () => {
    setShowForm(false);
    setEvent({ name: "" });
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        handleCloseForm();
        setObjectToDelete(false);
        setShowCancelModal(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  });

  useEffect(() => {
    const getAuthLevels = async () => {
      try {
        const response = await api.get("auth_levels");
        setAuthLevels(response.data);
      } catch {
        NotificationManager.error(
          "Something went wrong. Failed to get auth levels",
          "Error",
          3000
        );
      }
    };
    getAuthLevels();
    getEvents();
  }, [api, getEvents]);

  const handleEventChange = ({ target: { name, value } }) => {
    setEvent({
      ...event,
      [name]: value,
    });
  };

  const handleCodesChange = ({ target: { name, value } }, level) => {
    setAlterCodesObj({
      ...alterCodesObj,
      [level.id]: {
        ...level,
        [name]: value,
      },
    });
  };

  const handleEventsUpdate = ({ target: { name, value } }, event) => {
    setAlterItemsObj({
      ...alterItemsObj,
      [event.id]: {
        ...event,
        [name]: value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post("events", {
        event: {
          ...event,
          users_attributes: Object.values(alterCodesObj).map((each) => ({
            username: each.username,
            password: "Wii7e6Z39yyaBi",
            auth_level_id: each.id,
          })),
        },
      });
      NotificationManager.success(
        `${event.name} successfully added.`,
        "Success",
        1000
      );
      setAlterCodesObj({});
      document.getElementById("name").value = "";
      authLevels.map(
        (level) => (document.getElementById(level.name).value = "")
      );
      setEvent({
        name: "",
      });
      getEvents();
      setShowForm(false);
    } catch (error) {
      if (error?.response?.data?.errors?.name) {
        NotificationManager.error(
          `${event.name} already exists. Change the name to create new event.`,
          "Error",
          5000
        );
      } else if (
        error?.response?.data?.exception?.includes("UniqueViolation")
      ) {
        NotificationManager.error(
          "There are duplicate codes. Each code must be unique.",
          "Error",
          5000
        );
      } else if (error?.response?.data?.errors["users.username"]) {
        NotificationManager.error(
          "Some of these codes have already been used. Codes must be unique across the entire database.",
          "Error",
          5000
        );
      } else {
        NotificationManager.error(
          "Something went wrong. Event not added.",
          "Error",
          5000
        );
      }
    }
  };

  const handleUpdateItems = (e) => {
    e.preventDefault();
    Promise.all(
      Object.values(alterItemsObj).map(async (event) => {
        try {
          await api.put(`events/${event.id}`, { event });
          NotificationManager.success(
            `${event.name} successfully updated.`,
            "Success",
            1000
          );
        } catch (error) {
          if (error.response.data.errors.name) {
            NotificationManager.error(
              `There is already an event with the name ${event.name}.`,
              "Failure",
              5000
            );
          }
        } finally {
          getEvents();
          setAlterItemsObj({});
        }
      })
    );
  };

  const form = () => {
    return (
      <div className="modal">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">Add New Event</Typography>
          <div>
            <Close
              className="route_icon"
              onClick={handleCloseForm}
              style={{ marginTop: "0", color: "black" }}
            />
          </div>
        </div>
        <form className="drop_downs" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputLabel>Event name:</InputLabel>
              <TextField
                variant="standard"
                name="name"
                id="name"
                fullWidth
                required
                onChange={handleEventChange}
              />
            </Grid>
            {authLevels.map((level) => (
              <Grid item xs={12} key={level.name}>
                <InputLabel>
                  {level.name.charAt(0).toUpperCase() + level.name.slice(1)}{" "}
                  code:
                </InputLabel>
                <TextField
                  variant="standard"
                  id={level.name}
                  name="username"
                  onChange={(e) => handleCodesChange(e, level)}
                  fullWidth
                  required
                />
              </Grid>
            ))}
          </Grid>
          <div className="submit_button" style={{ textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                !event.name ||
                !!Object.values(alterCodesObj).find(
                  ({ username }) => username.length === 0
                ) ||
                Object.values(alterCodesObj).length < 4
              }
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      {(showForm || objectToDelete || showCancelModal) && (
        <div className="overlay"></div>
      )}
      {showForm && form()}
      {objectToDelete && deleteModal(objectToDelete, "Event", "events")}
      {showCancelModal && cancelModal()}
      <div
        className="skinny_stack"
        style={{
          justifyContent: "space-between",
          marginTop: "1.5rem",
        }}
      >
        <Typography variant="h5">Manage Events</Typography>
        {Object.keys(alterItemsObj).length === 0 ? (
          <span style={{ display: "flex" }} className="skinny_stack">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowForm(true)}
              style={{ height: 38, whiteSpace: "nowrap", marginLeft: 5 }}
            >
              Add Event
            </Button>
          </span>
        ) : (
          <span style={{ display: "flex" }} className="skinny_stack">
            <Button
              color="secondary"
              variant="contained"
              onClick={handleShowCancelModal}
              style={{ margin: "0 5px", height: 38 }}
            >
              Discard
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleUpdateItems}
              style={{ margin: "0 0 0 5px", height: 38 }}
            >
              Update
            </Button>
          </span>
        )}
      </div>
      <Typography style={{ margin: "15px 0 10px 0" }} variant="h6">
        {events.length} events
      </Typography>
      <Table size="small" style={{ overflowX: "scroll" }}>
        <TableBody>
          {events.map((event) => {
            const eventToRender = alterItemsObj[event.id] || event;
            return (
              <TableRow
                key={eventToRender.id}
                style={
                  alterItemsObj[eventToRender.id] && {
                    backgroundColor: "lightgray",
                  }
                }
              >
                {event.id === chosenVariables.event_id ? (
                  <TableCell style={{ color: "darkred", fontWeight: 600 }}>
                    Currently Selected
                  </TableCell>
                ) : (
                  <TableCell
                    onClick={() => handleEventSelect(event)}
                    className="action_icon"
                    style={{ color: "darkgreen", fontWeight: 600 }}
                    name={event.name}
                  >
                    Select event
                  </TableCell>
                )}
                <TableCell>
                  <TextField
                    variant="standard"
                    name="name"
                    required
                    fullWidth
                    onChange={(e) => handleEventsUpdate(e, eventToRender)}
                    value={eventToRender.name}
                  />
                </TableCell>
                <TableCell
                  style={{
                    display: "flex",
                    height: 32,
                    justifyContent: "center",
                  }}
                >
                  <span className="action_icon">
                    <DeleteOutlined
                      onClick={() => handleShowDeleteModal(event)}
                      style={{ color: "black" }}
                    />
                  </span>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default ManageEvents;
