import React, { useState, useEffect, useContext } from "react";
import { VariablesContext } from "../chosenVariablesContext";
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Typography,
  Select,
} from "@mui/material";
import useApi from "../api/api";
import { NotificationManager } from "react-notifications";
import { Close } from "@mui/icons-material";

const AssignmentEditForm = ({
  assignment,
  setAssignmentToEdit,
  setShowAssignmentEditForm,
}) => {
  const api = useApi();
  const {
    chosenVariables,
    getAssignments,
    dates,
    shuttleDates,
    shuttleRoutes,
  } = useContext(VariablesContext);

  const [vehicles, setVehicles] = useState([]);
  const [assignmentChanges, setAssignmentChanges] = useState(false);

  const availableShuttles = shuttleDates
    .filter(({ event_date_id }) => event_date_id === assignment.event_date_id)
    .map((each) => each.shuttle);

  const routesByShuttle = shuttleRoutes
    .filter(({ shuttle_id }) => shuttle_id === assignment.shuttle_id)
    .map((each) => each.route);

  const handleAssignmentChange = ({ target: { id, value } }) => {
    setAssignmentChanges(true);
    if (
      id === "event_date_id" &&
      assignment.shuttle_id !== "" &&
      !shuttleDates.find(
        (one) =>
          one.event_date_id === value &&
          one.shuttle_id === assignment.shuttle_id
      )
    ) {
      setAssignmentToEdit({
        ...assignment,
        [id]: value,
        shuttle_id: "",
        route_id: "",
      });
    } else if (
      id === "shuttle_id" &&
      assignment.route_id !== "" &&
      !shuttleRoutes.find(
        (one) =>
          one.shuttle_id === value && one.route_id === assignment.route_id
      )
    ) {
      setAssignmentToEdit({
        ...assignment,
        [id]: value,
        route_id: "",
      });
    } else {
      setAssignmentToEdit({
        ...assignment,
        [id]: value,
      });
    }
  };

  useEffect(() => {
    const getVehicles = async () => {
      try {
        const response = await api.get(
          `vehicles?event_id=${chosenVariables.event_id}&event_date_id=${chosenVariables.event_date_id}&shuttle_id=${assignment.shuttle_id}&assignment_id=${assignment.id}`
        );
        setVehicles(response.data);
      } catch (error) {
        console.warn("get vehicles error", error);
      }
    };
    getVehicles();
  }, [
    api,
    chosenVariables.event_id,
    chosenVariables.event_date_id,
    assignment.shuttle_id,
    assignment.id,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.put(`assignments/${assignment.id}`, assignment);
      NotificationManager.success(
        `Assignment successfully updated.`,
        "Success",
        1000
      );
      getAssignments();
      setShowAssignmentEditForm(false);
    } catch (error) {
      if (error?.response?.data?.errors?.driver_id) {
        NotificationManager.error(
          `${assignment.driver.name} has already been assigned to ${
            availableShuttles.find(({ id }) => id === assignment.shuttle_id)
              .name
          } on ${dates.find(({ id }) => id === assignment.event_date_id).date}`,
          "Error",
          5000
        );
      } else {
        NotificationManager.error(
          "Something went wrong. Assignment not updated.",
          "Error",
          5000
        );
      }
    }
  };

  return (
    <div
      className="modal"
      style={
        routesByShuttle.find(({ id }) => id === assignment.route_id)
          ? {
              borderColor: routesByShuttle.find(
                ({ id }) => id === assignment.route_id
              ).color,
            }
          : {}
      }
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">
          Edit Assignment for {assignment.driver?.name}
        </Typography>
        <div>
          <Close
            className="route_icon"
            onClick={() => {
              setShowAssignmentEditForm(false);
            }}
            style={{ marginTop: "0", color: "black" }}
          />
        </div>
      </div>
      <form className="drop_downs" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputLabel>Date:</InputLabel>
            <Select
              variant="standard"
              id="event_date_id"
              native
              onChange={handleAssignmentChange}
              fullWidth
              required
              value={assignment.event_date_id}
            >
              <option aria-label="None" value="" data-name="">
                Choose Date
              </option>
              {dates &&
                dates.map((date) => (
                  <option key={date.id} value={date.id} data-name={date.date}>
                    {date.date}
                  </option>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Shuttle:</InputLabel>
            <Select
              variant="standard"
              id="shuttle_id"
              native
              onChange={handleAssignmentChange}
              fullWidth
              required
              value={assignment.shuttle_id}
            >
              <option aria-label="None" value="" data-name="">
                Choose Shuttle
              </option>
              {availableShuttles &&
                availableShuttles.map((shuttle) => (
                  <option
                    key={shuttle.id}
                    value={shuttle.id}
                    data-name={shuttle.name}
                  >
                    {shuttle.name}
                  </option>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Route:</InputLabel>
            <Select
              variant="standard"
              id="route_id"
              native
              onChange={handleAssignmentChange}
              fullWidth
              required
              value={assignment.route_id}
            >
              <option aria-label="None" value="">
                Choose Route
              </option>
              {routesByShuttle &&
                routesByShuttle.map((route) => (
                  <option key={route.id} value={route.id}>
                    {route.name} {route.description}
                  </option>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Vehicle:</InputLabel>
            <Select
              variant="standard"
              id="vehicle_id"
              native
              onChange={handleAssignmentChange}
              fullWidth
              required
              value={assignment.vehicle_id}
            >
              <option aria-label="None" value="">
                Choose Vehicle
              </option>
              {vehicles &&
                vehicles.map((vehicle) => (
                  <option key={vehicle.id} value={vehicle.id}>
                    {vehicle.company} {vehicle.number}
                  </option>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Start Time:</InputLabel>
            <TextField
              variant="standard"
              id="start_time"
              type="time"
              fullWidth
              value={assignment.start_time}
              onChange={handleAssignmentChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>End Time:</InputLabel>
            <TextField
              variant="standard"
              id="end_time"
              type="time"
              fullWidth
              value={assignment.end_time}
              onChange={handleAssignmentChange}
            />
          </Grid>
        </Grid>
        <div style={{ textAlign: "center" }}>
          <div className="submit_button">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                !assignment.event_date_id ||
                !assignment.shuttle_id ||
                !assignment.route_id ||
                !assignment.vehicle_id ||
                assignment.start_time === "" ||
                assignment.end_time === "" ||
                !assignmentChanges
              }
            >
              Update
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AssignmentEditForm;
