import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Typography } from "@mui/material";
import { Close, ArrowBack, ArrowForward } from "@mui/icons-material";

const ChosenPlaceGraph = ({
  place,
  filteredEntries,
  times,
  counts,
  xRange,
  timeSpanIntervals,
  yMax,
  setChosenPlaceGraph,
  places,
}) => {
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 37) {
        places.indexOf(place) !== 0
          ? setChosenPlaceGraph(places[places.indexOf(place) - 1])
          : setChosenPlaceGraph(places[places.length - 1]);
      } else if (event.keyCode === 39) {
        places.indexOf(place) !== places.length - 1
          ? setChosenPlaceGraph(places[places.indexOf(place) + 1])
          : setChosenPlaceGraph(places[0]);
      }
    };
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  });
  const placeEntries = filteredEntries.filter((each) =>
    each.z.includes(place.name),
  );

  const isolated = [];
  for (let i = 0; i < filteredEntries.length; i++) {
    if (filteredEntries[i].z.includes(place.name)) {
      isolated.push({ x: `${times[i]}0`, y: counts[i] });
    } else {
      isolated.push({ x: `${times[i]}0`, y: 0 });
    }
  }

  const holder = {};

  isolated.forEach(function (a) {
    if (holder.hasOwnProperty(a.x)) {
      holder[a.x] = holder[a.x] + a.y;
    } else {
      holder[a.x] = a.y;
    }
  });

  const accumulated = [];

  for (const prop in holder) {
    accumulated.push({ x: prop, y: holder[prop] });
  }

  function getAttendeeCountOrMakeZero(x) {
    for (let each of accumulated) {
      if (x === each.x) {
        return each.y;
      }
    }
    return 0;
  }

  let totalAttendeeCount = 0;
  if (placeEntries.length !== 0) {
    totalAttendeeCount = placeEntries
      .map((entry) => entry.y)
      .reduce((a, b) => a + b);
  }

  return (
    <div
      className="graph_modal"
      style={{
        borderColor: place.route?.color,
      }}
    >
      <div style={{ height: "100%" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <ArrowBack
              style={{ margin: "0px 20px 0 0", color: "black" }}
              className="route_icon"
              onClick={
                places.indexOf(place) !== 0
                  ? () => setChosenPlaceGraph(places[places.indexOf(place) - 1])
                  : () => setChosenPlaceGraph(places[places.length - 1])
              }
            />
            <ArrowForward
              style={{ marginTop: "0", color: "black" }}
              className="route_icon"
              onClick={
                places.indexOf(place) !== places.length - 1
                  ? () => setChosenPlaceGraph(places[places.indexOf(place) + 1])
                  : () => setChosenPlaceGraph(places[0])
              }
            />
          </div>
          <Close
            style={{ marginTop: "0", color: "black" }}
            className="route_icon"
            onClick={() => setChosenPlaceGraph(false)}
          />
        </div>
        {totalAttendeeCount !== 0 ? (
          <>
            <div className="chosen_graph_center">
              <div style={{ height: "100%", width: "100%" }}>
                <Bar
                  data={{
                    labels: timeSpanIntervals.map((v) => v),
                    datasets: [
                      {
                        backgroundColor: place.route?.color,
                        data: timeSpanIntervals.map((v) =>
                          getAttendeeCountOrMakeZero(v),
                        ),
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        ticks: {
                          maxTicksLimit: xRange,
                        },
                      },
                      y: {
                        suggestedMax: yMax,
                      },
                    },
                  }}
                />
                <Typography align="center">{place.name}</Typography>
                <Typography align="center">
                  {totalAttendeeCount}{" "}
                  {parseInt(totalAttendeeCount) > 1 ? (
                    <>attendees</>
                  ) : (
                    <>attendee</>
                  )}{" "}
                  moved in {placeEntries.length}{" "}
                  {placeEntries.length > 1 ? <>trips</> : <>trip</>}
                </Typography>
              </div>
            </div>
          </>
        ) : (
          <div className="chosen_graph_center">
            <Typography align="center">
              There is no ridership data <br></br> for {place.name}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChosenPlaceGraph;
