import React, { useState, useEffect, useContext } from "react";
import { VariablesContext } from "../chosenVariablesContext";
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Typography,
  Select,
} from "@mui/material";
import useApi from "../api/api";
import { NotificationManager } from "react-notifications";
import { Close } from "@mui/icons-material";

const EntryEditForm = ({ entry, setEntryToEdit, setShowEntryEditForm }) => {
  const api = useApi();
  const [assignments, setAssignments] = useState([]);

  const { dates, getEntries, shuttleDates, shuttleRoutes, places } =
    useContext(VariablesContext);

  const availableShuttles = shuttleDates
    .filter(({ event_date_id }) => event_date_id === entry.event_date_id)
    .map((each) => each.shuttle);

  const uniqueRouteIds = [];

  const routesByShuttle = shuttleRoutes
    .filter((each) =>
      shuttleDates
        .filter(({ event_date_id }) => event_date_id === entry.event_date_id)
        .find((one) => one.shuttle_id === each.shuttle_id)
    )
    .filter(({ shuttle_id }) => shuttle_id.includes(entry.shuttle_id))
    .map((each) => each.route)
    .filter(({ id }) => {
      const isDuplicate = uniqueRouteIds.includes(id);
      if (!isDuplicate) {
        uniqueRouteIds.push(id);
        return true;
      }
      return false;
    });

  const handleEntryChange = ({ target: { id, value } }) => {
    setEntryToEdit({
      ...entry,
      [id]: value,
    });
  };

  const handleDateChange = ({ target: { id, value } }) => {
    if (
      entry.shuttle_id !== "" &&
      !shuttleDates.find(
        (one) =>
          one.event_date_id === value && one.shuttle_id === entry.shuttle_id
      )
    ) {
      setEntryToEdit({
        ...entry,
        [id]: value,
        shuttle_id: "",
        route_id: "",
        assignment_id: "",
      });
    } else {
      setEntryToEdit({
        ...entry,
        [id]: value,
        assignment_id: "",
      });
    }
  };

  const handleShuttleChange = ({ target: { id, value } }) => {
    if (
      entry.route_id !== "" &&
      !shuttleRoutes.find(
        (one) => one.shuttle_id === value && one.route_id === entry.route_id
      )
    ) {
      setEntryToEdit({
        ...entry,
        [id]: value,
        route_id: "",
        place_id: "",
        assignment_id: "",
      });
    } else {
      setEntryToEdit({
        ...entry,
        [id]: value,
        assignment_id: "",
      });
    }
  };

  const handlePlaceChange = ({ target: { id, value } }) => {
    if (value) {
      setEntryToEdit({
        ...entry,
        [id]: value,
        route_id: places.find(({ id }) => id === value).route_id,
        assignment_id: "",
      });
    } else {
      setEntryToEdit({
        ...entry,
        [id]: value,
        assignment_id: "",
      });
    }
  };

  useEffect(() => {
    const getAssignments = async () => {
      try {
        const response = await api.get(
          `assignments?event_date_id=${entry.event_date_id}`
        );
        setAssignments(response.data);
      } catch (error) {
        console.warn("get assignments error", error);
      }
    };
    getAssignments();
  }, [api, entry]);

  const handleClose = () => {
    setShowEntryEditForm(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.put(`entries/${entry.id}`, entry);
      NotificationManager.success(
        `Entry successfully updated.`,
        "Success",
        1000
      );
      getEntries();
      handleClose();
    } catch (error) {
      if (error?.response.status === 499) {
        NotificationManager.error(error.response.data.errors, "Error", 5000);
      } else {
        NotificationManager.error(
          "Something went wrong. Entry not added.",
          "Error",
          5000
        );
      }
    }
  };

  return (
    <div
      className="modal"
      style={
        routesByShuttle.find(({ id }) => id === entry.route_id)
          ? {
              borderColor: routesByShuttle.find(
                ({ id }) => id === entry.route_id
              ).color,
            }
          : {}
      }
    >
      <div className="event-form-page">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">Edit Entry</Typography>
          <div>
            <Close
              className="route_icon"
              onClick={() => {
                handleClose();
              }}
              style={{ marginTop: "0", color: "black" }}
            />
          </div>
        </div>
        <form className="drop_downs" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputLabel>Date:</InputLabel>
              <Select
                variant="standard"
                id="event_date_id"
                native
                onChange={handleDateChange}
                fullWidth
                required
                value={entry.event_date_id}
              >
                <option aria-label="None" value="" data-name="">
                  Choose Date
                </option>
                {dates &&
                  dates.map((date) => (
                    <option key={date.id} value={date.id} data-name={date.date}>
                      {date.date}
                    </option>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Shuttle:</InputLabel>
              <Select
                variant="standard"
                id="shuttle_id"
                native
                onChange={handleShuttleChange}
                fullWidth
                required
                value={entry.shuttle_id}
              >
                <option aria-label="None" value="" data-name="">
                  Choose Shuttle
                </option>
                {availableShuttles &&
                  availableShuttles.map((shuttle) => (
                    <option
                      key={shuttle.id}
                      value={shuttle.id}
                      data-name={shuttle.name}
                    >
                      {shuttle.name}
                    </option>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Route:</InputLabel>
              <Select
                variant="standard"
                id="route_id"
                native
                onChange={handleEntryChange}
                fullWidth
                value={entry.route_id}
              >
                <option aria-label="None" value="">
                  Choose Route
                </option>
                {routesByShuttle &&
                  routesByShuttle.map((route) => (
                    <option key={route.id} value={route.id}>
                      {route.name} {route.description}
                    </option>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Location:</InputLabel>
              <Select
                variant="standard"
                id="place_id"
                native
                onChange={handlePlaceChange}
                fullWidth
                required
                value={entry.place_id}
              >
                <option aria-label="None" value="">
                  Choose Location
                </option>
                {places &&
                  places
                    .filter((each) => each.route.id.includes(entry.route_id))
                    .filter(({ route_id }) =>
                      routesByShuttle.find(({ id }) => id === route_id)
                    )
                    .map((place) => (
                      <option key={place.id} value={place.id}>
                        {place.name}
                      </option>
                    ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Time:</InputLabel>
              <TextField
                variant="standard"
                id="time"
                type="time"
                fullWidth
                value={entry.time}
                onChange={handleEntryChange}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Attendee Count:</InputLabel>
              <TextField
                variant="standard"
                required
                type="number"
                id="attendee_count"
                fullWidth
                onChange={handleEntryChange}
                value={entry.attendee_count}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Vehicle (optional):</InputLabel>
              <Select
                variant="standard"
                id="assignment_id"
                native
                onChange={handleEntryChange}
                fullWidth
                value={entry.assignment_id ? entry.assignment_id : ""}
              >
                <option aria-label="None" value="">
                  Choose Vehicle
                </option>
                {assignments &&
                  assignments
                    .filter(({ shuttle_id }) => shuttle_id === entry.shuttle_id)
                    .filter(({ route_id }) => route_id === entry.route_id)
                    .sort((a, b) =>
                      a.vehicle.number > b.vehicle.number ? 1 : -1
                    )
                    .sort((a, b) =>
                      a.vehicle.company.localeCompare(b.vehicle.company)
                    )
                    .map((assignment) => (
                      <option key={assignment.id} value={assignment.id}>
                        {assignment.vehicle.company} {assignment.vehicle.number}
                      </option>
                    ))}
              </Select>
            </Grid>
          </Grid>
          <Grid>
            <div style={{ textAlign: "center" }}>
              <div className="submit_button">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={
                    !entry.event_date_id ||
                    !entry.shuttle_id ||
                    !entry.place_id ||
                    entry.time === "" ||
                    !entry.attendee_count
                  }
                >
                  Update
                </Button>
              </div>
            </div>
            <div className="submit_button"></div>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default EntryEditForm;
