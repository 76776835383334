import React, { useState, useContext } from "react";
import useApi from "../api/api";
import { NotificationManager } from "react-notifications";
import { VariablesContext } from "../chosenVariablesContext";
import { Button, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

const RemoveAssignment = () => {
  const { getAssignments, chosenVariables } = useContext(VariablesContext);

  const api = useApi();

  const [objectToDelete, setObjectToDelete] = useState(false);
  const [objectToAddBack, setObjectToAddBack] = useState(false);

  const handleShowDeleteModal = (obj) => {
    setObjectToDelete(obj);
  };

  const handleShowAddBackModal = (obj) => {
    setObjectToAddBack(obj);
  };

  const handleClose = () => {
    setObjectToDelete(false);
    setObjectToAddBack(false);
  };

  const removeAssignment = async (obj, status) => {
    try {
      await api.delete(`assignments/${obj.id}?status=${status}`);
      if (status === "on_break") {
        NotificationManager.success(
          `${obj.driver.name} put on break.`,
          "Success",
          1000
        );
      } else if (status === "released") {
        NotificationManager.success(
          `${obj.driver.name} released.`,
          "Success",
          1000
        );
      } else {
        NotificationManager.success(
          "Assignment permanently deleted.",
          "Success",
          1000
        );
      }
      getAssignments();
      setObjectToDelete(false);
    } catch {
      NotificationManager.error(
        "Something went wrong. Driver not released.",
        "Error",
        5000
      );
    }
  };

  const addBack = async (obj) => {
    obj.discarded_at = "";
    obj.status = "active";
    try {
      await api.put(`assignments/${obj.id}`, obj);
      NotificationManager.success(
        `${obj.driver.name} added back.`,
        "Success",
        1000
      );
      getAssignments();
      handleClose();
    } catch (error) {
      if (error?.response?.data?.errors?.driver_id) {
        NotificationManager.error(
          `${obj.driver.name} already has an existing assignment for ${obj.shuttle.name} on ${chosenVariables.event_date_name}.`,
          "Error",
          5000
        );
      } else {
        NotificationManager.error(
          "Something went wrong. Driver not added back.",
          "Error",
          5000
        );
      }
    }
  };

  const deleteModal = (obj) => {
    return (
      <div className="modal" style={{ borderColor: obj.route.color }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {obj.status === "released" ? (
            <Typography variant="h5">Delete Assignment</Typography>
          ) : (
            <>
              {obj.status === "on_break" ? (
                <Typography variant="h5">End Driver Break</Typography>
              ) : (
                <Typography variant="h5">Release Driver</Typography>
              )}
            </>
          )}
          <div>
            <Close
              className="route_icon"
              onClick={() => handleClose(obj)}
              style={{ marginTop: "0", color: "black" }}
            />
          </div>
        </div>
        {obj.status === "released" ? (
          <p>
            Are you sure you want to permanently delete this assignment?
            Deletion cannot be undone.
          </p>
        ) : (
          <>
            {obj.status === "on_break" ? (
              <p>Do you want to add the driver back or release them?</p>
            ) : (
              <p>
                Do you want to release the driver or put them on a break? Both
                will still be visible when you view a single route.
              </p>
            )}
          </>
        )}
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div style={{ fontWeight: "bold" }}>
            <p>Driver:</p>
            <p>Vehicle:</p>
            <p>Date:</p>
            <p>Shuttle:</p>
            <p>Route:</p>
          </div>
          <div>
            <p>{obj.driver.name}</p>
            <p>
              {obj.vehicle.company} {obj.vehicle.number}
            </p>
            <p>{chosenVariables.event_date_name}</p>
            <p>{obj.shuttle.name}</p>
            <p>{obj.route.name}</p>
          </div>
        </div>

        {obj.status === "released" ? (
          <div className="submit_button" style={{ textAlign: "center" }}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => removeAssignment(obj, "destroyed")}
            >
              Delete
            </Button>
          </div>
        ) : (
          <>
            {obj.status === "on_break" ? (
              <div
                className="submit_button"
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => addBack(obj)}
                >
                  Add Back
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => removeAssignment(obj, "released")}
                >
                  Release
                </Button>
              </div>
            ) : (
              <div
                className="submit_button"
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => removeAssignment(obj, "on_break")}
                >
                  Break
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => removeAssignment(obj, "released")}
                >
                  Release
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const addBackModal = (obj) => {
    return (
      <div className="modal">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">Add Assignment Back</Typography>
          <div>
            <Close
              className="route_icon"
              onClick={() => handleClose(obj)}
              style={{ marginTop: "0", color: "black" }}
            />
          </div>
        </div>
        <p>Are you sure you want to add this assignment back?</p>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div style={{ fontWeight: "bold" }}>
            <p>Driver:</p>
            <p>Vehicle:</p>
            <p>Date:</p>
            <p>Shuttle:</p>
            <p>Route:</p>
          </div>
          <div>
            <p>{obj.driver.name}</p>
            <p>
              {obj.vehicle.company} {obj.vehicle.number}
            </p>
            <p>{chosenVariables.event_date_name}</p>
            <p>{obj.shuttle.name}</p>
            <p>{obj.route.name}</p>
          </div>
        </div>
        <div className="submit_button" style={{ textAlign: "center" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => addBack(obj)}
          >
            Confirm
          </Button>
        </div>
      </div>
    );
  };

  return {
    deleteModal,
    objectToDelete,
    setObjectToDelete,
    handleShowDeleteModal,
    addBackModal,
    objectToAddBack,
    setObjectToAddBack,
    handleShowAddBackModal,
  };
};

export default RemoveAssignment;
