import React from "react";
import { createRoot } from "react-dom/client";
import AppViews from "./appViews";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#135413",
    },
    secondary: {
      main: "#7d1010",
    },
    default: {
      main: "#a1a1a1",
    },
    inherit: {
      main: "#a1a1a1",
    },
  },
});

createRoot(document.getElementById("root")).render(
  <ThemeProvider theme={theme}>
    <AppViews />
  </ThemeProvider>,
);
