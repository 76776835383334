import React from "react";
import {
  AddCircleOutline,
  RemoveCircleOutline,
  DeleteOutlined,
} from "@mui/icons-material";
import { FaWheelchair, FaRegEdit, FaExchangeAlt } from "react-icons/fa";
import IsoIcon from "@mui/icons-material/Iso";

const AssignmentCard = ({
  assignment,
  authLevel,
  chosenRoute,
  handleShowAssignmentEditForm,
  handleShowAssignmentDetailsEditForm,
  handleShowDeleteModal,
  handleShowAddBackModal,
  verbiage,
}) => {
  return (
    <div className="assignment_card">
      <span>{assignment.driver.name} </span>
      {authLevel !== "staff" && (
        <a href={`tel:+1${assignment.driver.phone_number}`}>
          {assignment.driver.phone_number}
        </a>
      )}
      {assignment.driver.isLocal && (
        <span
          style={{
            color: "green",
            fontWeight: "600",
          }}
        >
          {" "}
          L
        </span>
      )}
      {authLevel !== "staff" && (
        <div
          style={{
            float: "right",
          }}
        >
          {assignment.status !== "released" ? (
            <>
              <FaRegEdit
                className="assignment_icon"
                onClick={() => handleShowAssignmentDetailsEditForm(assignment)}
                style={{ fontSize: 20 }}
              />
              <FaExchangeAlt
                className="assignment_icon"
                onClick={() => handleShowAssignmentEditForm(assignment)}
                style={{ fontSize: 17, margin: "0 3px 0 11px" }}
              />
              {assignment.status === "active" ? (
                <RemoveCircleOutline
                  className="assignment_icon"
                  onClick={() => handleShowDeleteModal(assignment)}
                  style={{ fontSize: 20, color: "black" }}
                />
              ) : (
                <IsoIcon
                  className="assignment_icon"
                  onClick={() => handleShowDeleteModal(assignment)}
                  style={{ fontSize: 20, color: "black" }}
                />
              )}
            </>
          ) : (
            <>
              <FaRegEdit
                className="assignment_icon"
                onClick={() => handleShowAssignmentDetailsEditForm(assignment)}
                style={{ fontSize: 20 }}
              />
              <AddCircleOutline
                className="assignment_icon"
                onClick={() => handleShowAddBackModal(assignment)}
                style={{ fontSize: 20, color: "black" }}
              />
              <DeleteOutlined
                className="assignment_icon"
                onClick={() => handleShowDeleteModal(assignment)}
                style={{ fontSize: 20, color: "black" }}
              />
            </>
          )}
        </div>
      )}
      <div>
        <span style={{ fontWeight: "bold" }}>{assignment.vehicle.number} </span>
        <span>{assignment.vehicle.company} </span>
        <span style={{ fontWeight: "bold" }}>
          {assignment.vehicle.capacity}{" "}
        </span>
        <span>{assignment.vehicle.kind} </span>
        {assignment.vehicle.isAda && <FaWheelchair style={{ color: "red" }} />}
        {assignment.discarded_at && <span></span>}
      </div>
      {chosenRoute && (
        <>
          <span>
            {assignment.start_time.slice(11, 16)} -{" "}
            {assignment.end_time.slice(11, 16)}
          </span>
          <div>
            {assignment.discarded_at && (
              <span>
                {verbiage}{" "}
                {new Date(assignment.discarded_at).toString().slice(16, 21)}
              </span>
            )}
          </div>
          <div>{assignment.driver.notes}</div>
        </>
      )}
    </div>
  );
};

export default AssignmentCard;
