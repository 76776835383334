import React from "react";
import { Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

const AssignmentDisplay = ({ entry, setShowAssignmentDisplay, authLevel }) => {
  const handleClose = () => {
    setShowAssignmentDisplay(false);
  };

  return (
    <div
      className="modal"
      style={{
        borderColor: entry.route.color,
      }}
    >
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">Assignment Details</Typography>
          <div>
            <Close
              className="route_icon"
              onClick={() => {
                handleClose();
              }}
              style={{ marginTop: "0", color: "black" }}
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div style={{ fontWeight: "bold" }}>
            <p>Driver:</p>
            <p>
              {(authLevel === "superuser" ||
                authLevel === "admin" ||
                authLevel === "supervisor") &&
                "Phone:"}
            </p>
            <p>Vehicle:</p>
            <p>Shuttle:</p>
            <p>Route:</p>
          </div>
          <div>
            <p>{entry.driver.name} </p>
            <p>
              {(authLevel === "superuser" ||
                authLevel === "admin" ||
                authLevel === "supervisor") && (
                <a href={`tel:+1${entry.driver.phone_number}`}>
                  {entry.driver.phone_number}
                </a>
              )}
            </p>
            <p>
              {entry.vehicle.company} {entry.vehicle.number}
            </p>
            <p>{entry.shuttle.name}</p>
            <p>{entry.route.name}</p>
          </div>
        </div>
      </>
    </div>
  );
};

export default AssignmentDisplay;
