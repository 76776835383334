const BaseUrl = () => {
  let baseurl = "https://api.eventtranspo.com/api/v1";

  const subdomain = (window || self).location.host.split(".").shift(); //eslint-disable-line

  switch (subdomain) {
    case "localhost:3001":
      baseurl = "http://localhost:3000/api/v1";
      break;
    default:
      break;
  }

  return baseurl;
};

export default BaseUrl;
