import React, { useState, useCallback, useEffect, createContext } from "react";
import { Grid, InputLabel, Select } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import useApi from "./api/api";

export const VariablesContext = createContext();

const ChosenVariablesContext = ({ children }) => {
  const api = useApi();
  const [events, setEvents] = useState([]);
  const [dates, setDates] = useState([]);
  const [shuttles, setShuttles] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [places, setPlaces] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [discardedAssignments, setDiscardedAssignments] = useState([]);
  const [entries, setEntries] = useState([]);
  const [shuttleDates, setShuttleDates] = useState([]);
  const [shuttleRoutes, setShuttleRoutes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const subdomain = (window || self).location.host.split(".").shift(); //eslint-disable-line

  const [chosenVariables, setChosenVariables] = useState({
    tokens: localStorage.getItem("tokens"),
    event_id: localStorage.getItem("event_id"),
    event_name: localStorage.getItem("event_name"),
    event_date_id: localStorage.getItem("event_date_id"),
    event_date_name: localStorage.getItem("event_date_name"),
    shuttle_id: localStorage.getItem("shuttle_id"),
    shuttle_name: localStorage.getItem("shuttle_name"),
    route_id: localStorage.getItem("route_id"),
    place_id: localStorage.getItem("place_id"),
    start_time: localStorage.getItem("start_time"),
    end_time: localStorage.getItem("end_time"),
    entry_time: localStorage.getItem("entry_time"),
  });

  const setVariables = (data) => {
    localStorage.setItem("tokens", data.tokens);
    localStorage.setItem("event_id", data.event_id);
    localStorage.setItem("event_name", data.event_name);
    localStorage.setItem("event_date_id", data.event_date_id);
    localStorage.setItem("event_date_name", data.event_date_name);
    localStorage.setItem("shuttle_id", data.shuttle_id);
    localStorage.setItem("shuttle_name", data.shuttle_name);
    localStorage.setItem("route_id", data.route_id);
    localStorage.setItem("place_id", data.place_id);
    localStorage.setItem("start_time", data.start_time);
    localStorage.setItem("end_time", data.end_time);
    localStorage.setItem("entry_time", data.entry_time);
    setChosenVariables({
      tokens: localStorage.getItem("tokens"),
      event_id: localStorage.getItem("event_id"),
      event_name: localStorage.getItem("event_name"),
      event_date_id: localStorage.getItem("event_date_id"),
      event_date_name: localStorage.getItem("event_date_name"),
      shuttle_id: localStorage.getItem("shuttle_id"),
      shuttle_name: localStorage.getItem("shuttle_name"),
      route_id: localStorage.getItem("route_id"),
      place_id: localStorage.getItem("place_id"),
      start_time: localStorage.getItem("start_time"),
      end_time: localStorage.getItem("end_time"),
      entry_time: localStorage.getItem("entry_time"),
    });
  };

  const uniqueShuttleIds = [];

  const shuttlesByDate = shuttleDates
    .filter(
      ({ event_date_id }) => event_date_id === chosenVariables.event_date_id
    )
    .map((each) => each.shuttle)
    .filter(({ id }) => {
      const isDuplicate = uniqueShuttleIds.includes(id);
      if (!isDuplicate) {
        uniqueShuttleIds.push(id);
        return true;
      }
      return false;
    });

  const uniqueRouteIds = [];

  const routesByShuttle = shuttleRoutes
    .filter((each) =>
      shuttleDates
        .filter(
          ({ event_date_id }) => event_date_id === chosenVariables.event_date_id
        )
        .find((one) => one.shuttle_id === each.shuttle_id)
    )
    .filter(({ shuttle_id }) => shuttle_id.includes(chosenVariables.shuttle_id))
    .map((each) => each.route)
    .filter(({ id }) => {
      const isDuplicate = uniqueRouteIds.includes(id);
      if (!isDuplicate) {
        uniqueRouteIds.push(id);
        return true;
      }
      return false;
    });

  const getEvents = useCallback(async () => {
    try {
      const response = await api.get("events");
      setEvents(response.data);
    } catch (error) {
      console.warn("get events error", error);
    }
  }, [api]);

  const getDates = useCallback(async () => {
    try {
      const response = await api.get(
        `event_dates?event_id=${chosenVariables.event_id}`
      );
      setDates(response.data);
    } catch (error) {
      console.warn("get dates error", error);
    }
  }, [api, chosenVariables.event_id]);

  const getShuttleDates = useCallback(async () => {
    try {
      const response = await api.get(
        `shuttle_dates?event_id=${chosenVariables.event_id}`
      );
      setShuttleDates(response.data);
    } catch (error) {
      console.warn("get shuttle dates error", error);
    }
  }, [api, chosenVariables.event_id]);

  const getShuttles = useCallback(async () => {
    try {
      const response = await api.get(
        `shuttles?event_id=${chosenVariables.event_id}`
      );
      setShuttles(response.data);
    } catch (error) {
      console.warn("get shuttles error", error);
    }
  }, [api, chosenVariables.event_id]);

  const getShuttleRoutes = useCallback(async () => {
    try {
      const response = await api.get(
        `shuttle_routes?event_id=${chosenVariables.event_id}`
      );
      setShuttleRoutes(response.data);
    } catch (error) {
      console.warn("get shuttle routes error", error);
    }
  }, [api, chosenVariables.event_id]);

  const getRoutes = useCallback(async () => {
    try {
      const response = await api.get(
        `routes?event_id=${chosenVariables.event_id}`
      );
      setRoutes(response.data);
    } catch (error) {
      console.warn("get routes error", error);
    }
  }, [api, chosenVariables.event_id]);

  const getPlaces = useCallback(async () => {
    try {
      const response = await api.get(
        `places?event_id=${chosenVariables.event_id}`
      );
      setPlaces(response.data);
    } catch (error) {
      console.warn("get places error", error);
    }
  }, [api, chosenVariables.event_id]);

  const getAssignments = useCallback(async () => {
    try {
      const response = await api.get(
        `assignments?event_date_id=${chosenVariables.event_date_id}`
      );
      setAssignments(response.data);
      const response_2 = await api.get(
        `assignments/discarded?event_date_id=${chosenVariables.event_date_id}`
      );
      setDiscardedAssignments(response_2.data);
    } catch (error) {
      console.warn("get assignments error", error);
    } finally {
      setIsLoading(false);
    }
  }, [api, chosenVariables.event_date_id]);

  const getEntries = useCallback(async () => {
    try {
      const response = await api.get(
        `entries?event_date_id=${chosenVariables.event_date_id}`
      );
      setEntries(response.data);
    } catch (error) {
      console.warn("get entries error", error);
    } finally {
      setIsLoading(false);
    }
  }, [api, chosenVariables.event_date_id]);

  entries.forEach((each) => (each.formatted_time = each.time.slice(11, 16)));

  useEffect(() => {
    if (chosenVariables.event_id && chosenVariables.event_id !== "null") {
      getDates();
    }
  }, [getDates, chosenVariables.event_id]);

  useEffect(() => {
    if (chosenVariables.event_id && chosenVariables.event_id !== "null") {
      getShuttleDates();
    }
  }, [getShuttleDates, chosenVariables.event_id]);

  useEffect(() => {
    if (chosenVariables.event_id && chosenVariables.event_id !== "null") {
      getShuttles();
    }
  }, [getShuttles, chosenVariables.event_id]);

  useEffect(() => {
    if (chosenVariables.event_id && chosenVariables.event_id !== "null") {
      getShuttleRoutes();
    }
  }, [getShuttleRoutes, chosenVariables.event_id]);

  useEffect(() => {
    if (chosenVariables.event_id && chosenVariables.event_id !== "null") {
      getRoutes();
    }
  }, [getRoutes, chosenVariables.event_id]);

  useEffect(() => {
    if (chosenVariables.event_id && chosenVariables.event_id !== "null") {
      getPlaces();
    }
  }, [getPlaces, chosenVariables.event_id]);

  useEffect(() => {
    if (
      chosenVariables.event_date_id &&
      chosenVariables.event_date_id !== "undefined" &&
      (location.pathname === "/route-view" ||
        location.pathname === "/assignment-list")
    ) {
      getAssignments();
      if (!subdomain.includes("localhost")) {
        const intervalId = setInterval(() => {
          getAssignments();
        }, 15000);
        return () => clearInterval(intervalId);
      }
    }
  }, [chosenVariables.event_date_id, getAssignments, subdomain, location]);

  useEffect(() => {
    if (
      chosenVariables.event_date_id &&
      chosenVariables.event_date_id !== "undefined" &&
      (location.pathname === "/graph" || location.pathname === "/log")
    ) {
      getEntries();
      if (!subdomain.includes("localhost")) {
        const intervalId = setInterval(() => {
          getEntries();
        }, 15000);
        return () => clearInterval(intervalId);
      }
    }
  }, [chosenVariables.event_date_id, getEntries, subdomain, location]);

  const today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, 10);

  useEffect(() => {
    setTimeout(() => {
      if (!chosenVariables.event_date_id) {
        if (
          dates.length &&
          chosenVariables.event_id &&
          dates.find(({ date }) => date === today)
        ) {
          setVariables({
            ...chosenVariables,
            event_date_id: dates.find(({ date }) => date === today)?.id,
            event_date_name: dates.find(({ date }) => date === today)?.date,
          });
        } else {
          setVariables({
            ...chosenVariables,
            event_date_id: dates[0]?.id,
            event_date_name: dates[0]?.date,
          });
        }
      }
    }, 1000);
  }, [dates, chosenVariables.event_id]); //eslint-disable-line

  const handleViewSelect = ({ target: { id } }) => {
    setIsLoading(true);
    if (id === "manager") {
      navigate("route-view");
    } else if (id === "tracker") {
      navigate("graph");
    }
  };

  const handleEventSelect = ({ id, name }) => {
    setVariables({
      ...chosenVariables,
      event_id: id,
      event_name: name,
      event_date_id: "",
      shuttle_id: "",
      route_id: "",
      place_id: "",
    });
    setEntries([]);
  };

  const handleDateSelect = ({ target }) => {
    setIsLoading(true);
    if (
      chosenVariables.shuttle_id !== "" &&
      !shuttleDates.find(
        (one) =>
          one.event_date_id === target.value &&
          one.shuttle_id === chosenVariables.shuttle_id
      )
    ) {
      setVariables({
        ...chosenVariables,
        event_date_id: target.value,
        event_date_name: target.options[target.selectedIndex].dataset.name,
        shuttle_id: "",
        route_id: "",
        place_id: "",
      });
    } else if (
      chosenVariables.route_id !== "" &&
      !shuttleRoutes
        .filter((each) =>
          shuttleDates
            .filter(({ event_date_id }) => event_date_id === target.value)
            .find((one) => one.shuttle_id === each.shuttle_id)
        )
        .find(({ route_id }) => route_id.includes(chosenVariables.route_id))
    ) {
      setVariables({
        ...chosenVariables,
        event_date_id: target.value,
        event_date_name: target.options[target.selectedIndex].dataset.name,
        route_id: "",
        place_id: "",
      });
    } else {
      setVariables({
        ...chosenVariables,
        event_date_id: target.value,
        event_date_name: target.options[target.selectedIndex].dataset.name,
      });
    }
  };

  const handleShuttleSelect = ({ target }) => {
    if (
      target.value !== "" &&
      (chosenVariables.route_id !== "" || chosenVariables.place_id !== "") &&
      !shuttleRoutes.find(
        (one) =>
          one.shuttle_id === target.value &&
          one.route_id === chosenVariables.route_id
      )
    ) {
      setVariables({
        ...chosenVariables,
        shuttle_id: target.value,
        shuttle_name: target.options[target.selectedIndex].dataset.name,
        route_id: "",
        place_id: "",
      });
    } else {
      setVariables({
        ...chosenVariables,
        shuttle_id: target.value,
        shuttle_name: target.options[target.selectedIndex].dataset.name,
      });
    }
  };

  const handleRouteSelect = ({ target: { value } }) => {
    setVariables({
      ...chosenVariables,
      route_id: value,
      place_id: "",
    });
  };

  const handlePlaceSelect = ({ target }) => {
    if (target.value) {
      setVariables({
        ...chosenVariables,
        place_id: target.value,
        route_id: places.find(({ id }) => id === target.value).route_id,
      });
    } else {
      setVariables({
        ...chosenVariables,
        place_id: "",
      });
    }
  };

  const handleStartTimeChange = ({ target: { value } }) => {
    setVariables({
      ...chosenVariables,
      start_time: value,
    });
  };

  const handleEndTimeChange = ({ target: { value } }) => {
    setVariables({
      ...chosenVariables,
      end_time: value,
    });
  };

  const handleEntryTimeChange = ({ target: { value } }) => {
    setVariables({
      ...chosenVariables,
      entry_time: value,
    });
  };

  const dateSelect = (md) => {
    return (
      <Grid item xs={12} md={md ? 3 : 12}>
        <InputLabel>Date:</InputLabel>
        <Select
          variant="standard"
          id="event_date_id"
          native
          onChange={handleDateSelect}
          fullWidth
          required
          value={chosenVariables.event_date_id}
          style={chosenVariables.event_date_id ? {} : { color: "red" }}
          disabled={isLoading}
        >
          {dates.length > 0 ? (
            <>
              <option aria-label="None" value="" data-name="">
                Choose Date
              </option>
              {dates.map((date) => (
                <option key={date.id} value={date.id} data-name={date.date}>
                  {date.date}
                </option>
              ))}
            </>
          ) : (
            <option aria-label="None" value="">
              There are no dates yet for this event
            </option>
          )}
        </Select>
      </Grid>
    );
  };

  const shuttleSelect = (md) => {
    return (
      <Grid item xs={12} md={md ? 3 : 12}>
        <InputLabel>Shuttle:</InputLabel>
        <Select
          variant="standard"
          id="shuttle_id"
          native
          onChange={handleShuttleSelect}
          fullWidth
          required
          value={chosenVariables.shuttle_id}
          style={shuttlesByDate.length > 0 ? {} : { color: "red" }}
          disabled={isLoading}
        >
          {shuttlesByDate.length > 0 ? (
            <>
              <option aria-label="None" value="" data-name="">
                All Shuttles
              </option>
              {shuttlesByDate.map((shuttle) => (
                <option
                  key={shuttle.id}
                  value={shuttle.id}
                  data-name={shuttle.name}
                >
                  {shuttle.name}
                </option>
              ))}
            </>
          ) : (
            <option aria-label="None" value="">
              No shuttles match the applied filters
            </option>
          )}
        </Select>
      </Grid>
    );
  };

  const routeSelect = (md) => {
    return (
      <Grid item xs={12} md={md ? 3 : 12}>
        <InputLabel>Route:</InputLabel>
        <Select
          variant="standard"
          id="route_id"
          native
          onChange={handleRouteSelect}
          fullWidth
          required
          value={chosenVariables.route_id}
          style={routesByShuttle.length > 0 ? {} : { color: "red" }}
          disabled={isLoading}
        >
          {routesByShuttle.length > 0 ? (
            <>
              <option aria-label="None" value="">
                All Routes
              </option>
              {routesByShuttle.map((route) => (
                <option key={route.id} value={route.id}>
                  {route.name} {route.description}
                </option>
              ))}
            </>
          ) : (
            <option aria-label="None" value="">
              No routes match the applied filters
            </option>
          )}
        </Select>
      </Grid>
    );
  };

  const placeSelect = (md) => {
    return (
      <Grid item xs={12} md={md ? 3 : 12}>
        <InputLabel>Location:</InputLabel>
        <Select
          variant="standard"
          id="place_id"
          native
          onChange={handlePlaceSelect}
          fullWidth
          required
          value={chosenVariables.place_id}
          disabled={isLoading}
          style={
            places
              .filter((each) =>
                each.route.id.includes(chosenVariables.route_id)
              )
              .filter(({ route_id }) =>
                routesByShuttle.find(({ id }) => id === route_id)
              ).length > 0
              ? {}
              : { color: "red" }
          }
        >
          {places
            .filter((each) => each.route.id.includes(chosenVariables.route_id))
            .filter(({ route_id }) =>
              routesByShuttle.find(({ id }) => id === route_id)
            ).length ? (
            <>
              <option aria-label="None" value="">
                Choose Location
              </option>
              {places
                .filter((each) =>
                  each.route.id.includes(chosenVariables.route_id)
                )
                .filter(({ route_id }) =>
                  routesByShuttle.find(({ id }) => id === route_id)
                )
                .map((place) => (
                  <option key={place.id} value={place.id}>
                    {place.name}
                  </option>
                ))}
            </>
          ) : (
            <option aria-label="None" value="">
              No locations match the applied filters
            </option>
          )}
        </Select>
      </Grid>
    );
  };

  return (
    <VariablesContext.Provider
      value={{
        chosenVariables,
        events,
        dates,
        shuttles,
        shuttlesByDate,
        routes,
        routesByShuttle,
        places,
        assignments,
        discardedAssignments,
        entries,
        shuttleRoutes,
        shuttleDates,
        getEvents,
        getDates,
        getShuttles,
        getRoutes,
        getPlaces,
        getAssignments,
        getEntries,
        getShuttleDates,
        getShuttleRoutes,
        setVariables,
        handleViewSelect,
        handleEventSelect,
        handleDateSelect,
        handleShuttleSelect,
        handleRouteSelect,
        handleStartTimeChange,
        handleEndTimeChange,
        handleEntryTimeChange,
        dateSelect,
        shuttleSelect,
        routeSelect,
        placeSelect,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </VariablesContext.Provider>
  );
};

export default ChosenVariablesContext;
