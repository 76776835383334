import React, { useState, useReducer, useEffect, useContext } from "react";
import { Button, Typography } from "@mui/material";
import { Close, InsertDriveFile, Info } from "@mui/icons-material";
import { NotificationManager } from "react-notifications";
import Papa from "papaparse";
import useApi from "../api/api";
import { VariablesContext } from "../chosenVariablesContext";

const AssignmentUploadForm = ({ showInfo, setShowInfo, setShowUploadForm }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [filesToAdd, setFilesToAdd] = useState([]);
  const api = useApi();
  const { dates, shuttles, routes, chosenVariables, getAssignments } =
    useContext(VariablesContext);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27 && showInfo) {
        setShowInfo(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  });

  const initialState = { dropDepth: 0, inDropZone: false, fileList: [] };

  const reducer = (state, action) => {
    switch (action.type) {
      case "SET_DROP_DEPTH":
        return { ...state, dropDepth: action.dropDepth };
      case "SET_IN_DROP_ZONE":
        return { ...state, inDropZone: action.inDropZone };
      case "ADD_FILE_TO_LIST":
        return { ...state, fileList: state.fileList.concat(action.files) };
      case "REMOVE_FILE_FROM_LIST":
        const newFiles = [...state.fileList];
        newFiles.splice(action["i"], 1);
        filesToAdd.length === 1 && setFilesToAdd([]);
        return { ...state, fileList: newFiles };
      default:
        return state;
    }
  };

  const [data, dispatch] = useReducer(reducer, initialState);

  const removeFile = (i) => {
    dispatch({ type: "REMOVE_FILE_FROM_LIST", i });
  };

  useEffect(() => {
    const assignments = [];
    data.fileList.map((arr) => {
      Papa.parse(arr, {
        header: true,
        download: true,
        complete: function (results) {
          // const removeDuplicates = results.data.filter(
          //   (data, index, self) =>
          //     index === self.findIndex((d) => d.name === data.name)
          // );
          // if (results.data.length - removeDuplicates.length !== 0) {
          //   NotificationManager.error(
          //     `There are ${
          //       results.data.length - removeDuplicates.length
          //     } assignments with identical names. Please edit the file and try again.`,
          //     "Error",
          //     5000
          //   );
          //   data.fileList.pop();
          // } else if (results.data.some((obj) => obj.name === "")) {
          //   NotificationManager.error(
          //     `At least one driver in this file does not have a name. Please edit the file and try again.`,
          //     "Error",
          //     15000
          //   );
          // } else {
          assignments.push(results.data);
          setFilesToAdd([...assignments]);
          // }
        },
      });
      return data;
    });
  }, [data]);

  const assignmentsToAdd = [];

  filesToAdd.forEach((array) => {
    array.forEach((assignment) => {
      const matchingDate = dates.find(({ date }) =>
        assignment.date?.replaceAll("/", "-")?.includes(date.split(/-(.*)/s)[1])
      );
      const matchingShuttle = shuttles.find(
        ({ name }) => assignment.shuttle?.toLowerCase() === name.toLowerCase()
      );
      const matchingRoute = routes.find(
        (route) =>
          assignment.route?.toLowerCase() === route.name.toLowerCase() ||
          assignment.route?.toLowerCase() === route.description.toLowerCase()
      );
      assignment.event_id = chosenVariables.event_id;
      if (matchingDate?.id) {
        assignment.event_date_id = matchingDate.id;
      }
      if (matchingShuttle?.id) {
        assignment.shuttle_id = matchingShuttle.id;
      }
      if (matchingRoute?.id) {
        assignment.route_id = matchingRoute.id;
      }
      // else {
      //   NotificationManager.error(
      //     `There is no route matching the name ${assignment.route}`,
      //     "Error",
      //     5000
      //   );
      //   removeFile(0);
      // }
      if (Object.keys(assignment).length > 3) {
        assignmentsToAdd.push(assignment);
      }
    });
  });

  const addAssignments = () => {
    Promise.all(
      assignmentsToAdd.map(async (assignment) => {
        try {
          await api.post("assignments", assignment);
          NotificationManager.success(
            `${assignment.name} successfully assigned.`,
            "Success",
            5000
          );
          getAssignments();
          setShowUploadForm(false);
        } catch (error) {
          if (error?.response?.status === 499) {
            NotificationManager.error(
              error.response.data.errors,
              "Error",
              5000
            );
          } else {
            NotificationManager.error(
              `Something went wrong. ${assignment.name} not assigned.`,
              "Error",
              5000
            );
          }
        }
      })
    );
  };

  let driverAttempts = 0;
  let vehicleAttempts = 0;

  const addDrivers = () => {
    let newDrivers = 0;
    let existing = 0;
    assignmentsToAdd.forEach(async (driver) => {
      driver.isLocal = driver.local;
      driver.event_id = chosenVariables.event_id;
      try {
        const response = await api.post("drivers", driver);
        newDrivers++;
        driver.driver_id = response.data.id;
      } catch (error) {
        if (error?.response?.status === 499) {
          driver.driver_id = error.response.data.id;
          existing++;
        } else {
          NotificationManager.error(
            `Something went wrong. ${driver.name} not added.`,
            "Error",
            5000
          );
        }
      } finally {
        driverAttempts++;
        if (driverAttempts === assignmentsToAdd.length) {
          NotificationManager.info(
            `${newDrivers} new drivers added. ${existing} of the uploaded drivers already exist for this event.`,
            "Info",
            5000
          );
          if (vehicleAttempts === assignmentsToAdd.length) {
            addAssignments();
          }
        }
      }
    });
  };

  const addVehicles = () => {
    let newVehicles = 0;
    let existing = 0;
    assignmentsToAdd.forEach(async (vehicle) => {
      vehicle.isAda = vehicle.ada;
      vehicle.number = vehicle.vehicle_number;
      vehicle.event_id = chosenVariables.event_id;
      try {
        const response = await api.post("vehicles", vehicle);
        newVehicles++;
        vehicle.vehicle_id = response.data.id;
      } catch (error) {
        if (error?.response?.status === 499) {
          vehicle.vehicle_id = error.response.data.id;
          existing++;
        } else {
          NotificationManager.error(
            `Something went wrong. ${vehicle.company} ${vehicle.number} not added.`,
            "Error",
            5000
          );
        }
      } finally {
        vehicleAttempts++;
        if (vehicleAttempts === assignmentsToAdd.length) {
          NotificationManager.info(
            `${newVehicles} new vehicles added. ${existing} of the uploaded vehicles already exist for this event.`,
            "Info",
            5000
          );
          if (driverAttempts === assignmentsToAdd.length) {
            addAssignments();
          }
        }
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsFetching(true);
    addDrivers();
    addVehicles();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: "SET_DROP_DEPTH", dropDepth: data.dropDepth + 1 });
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: "SET_DROP_DEPTH", dropDepth: data.dropDepth - 1 });
    if (data.dropDepth > 0) return;
    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: true });
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let files = [...e.dataTransfer.files];

    if (files && files.length > 0) {
      const existingFiles = data.fileList.map((f) => f.name);
      files = files.filter((f) => !existingFiles.includes(f.name));

      dispatch({ type: "ADD_FILE_TO_LIST", files });
      e.dataTransfer.clearData();
      dispatch({ type: "SET_DROP_DEPTH", dropDepth: 0 });
      dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
    }
  };

  const onFileChange = (e) => {
    let files = [...e.target.files];

    if (files && files.length > 0) {
      const existingFiles = data.fileList.map((f) => f.name);
      files = files.filter((f) => !existingFiles.includes(f.name));
      dispatch({ type: "ADD_FILE_TO_LIST", files });
    }
  };

  return (
    <div className="modal" style={showInfo ? { background: "#707070" } : {}}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">Upload Assignments</Typography>
        {!showInfo && (
          <div style={{ display: "flex" }}>
            <Info
              className="route_icon"
              onClick={() => setShowInfo(true)}
              style={{ margin: "0 10px 0 0", color: "black" }}
            />
            <Close
              className="route_icon"
              onClick={() => setShowUploadForm(false)}
              style={{ marginTop: "0", color: "black" }}
            />
          </div>
        )}
      </div>
      {showInfo && (
        <div className="show_info">
          <Close
            className="route_icon"
            onClick={() => setShowInfo(false)}
            style={{ float: "right", margin: "0 -15px 0 0", color: "black" }}
          />
          <p>
            CSV file formatting is strict, download the template for an example
            of the required format.
          </p>
        </div>
      )}
      <form
        style={{
          margin: "2rem auto",
          width: "50%",
          textAlign: "center",
        }}
      >
        <div
          onDrop={(e) => handleDrop(e)}
          onDragOver={(e) => handleDragOver(e)}
          onDragEnter={(e) => handleDragEnter(e)}
          onDragLeave={(e) => handleDragLeave(e)}
        >
          <input
            type="file"
            accept=".csv"
            onChange={onFileChange}
            id="file"
            style={{
              display: "none",
            }}
          />
          <label
            htmlFor="file"
            className={showInfo ? "upload_box_show_info" : "upload_box"}
          >
            <div>Click to browse</div>
            <div className="no_mobile_text">or drag and drop</div>
          </label>
        </div>
      </form>
      <div>
        <table
          style={{
            width: "70%",
            margin: "0 auto",
          }}
        >
          <tbody>
            {data.fileList.map((file, i) => (
              <tr key={i} style={{ height: "30px" }}>
                <td>
                  <InsertDriveFile
                    style={{ margin: "0 4px -7px 0", color: "black" }}
                  />
                  {file.name}
                </td>
                <td>
                  <Close
                    className="route_icon"
                    onClick={() => removeFile(i)}
                    style={{ margin: "0 0 -7px 0", color: "red" }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div className="submit_button">
            <Button
              onClick={handleSubmit}
              disabled={isFetching || !assignmentsToAdd.length || showInfo}
              type="submit"
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </div>
          <div className="submit_button" style={{ textAlign: "center" }}>
            <Button
              href="/assignment_template.csv"
              download
              variant="contained"
              color="primary"
            >
              <span className="no_mobile_text">Download&nbsp;</span>Template
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentUploadForm;
