import React, { useState, useContext } from "react";
import { VariablesContext } from "../chosenVariablesContext";
import { Button, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import useApi from "../api/api";
import { NotificationManager } from "react-notifications";

const ConfirmDelete = () => {
  const api = useApi();
  const {
    getEvents,
    getDates,
    getShuttles,
    getRoutes,
    getPlaces,
    getShuttleDates,
    getShuttleRoutes,
    setVariables,
    chosenVariables,
  } = useContext(VariablesContext);

  const [objectToDelete, setObjectToDelete] = useState(false);

  const deleteType = async (obj, type, types) => {
    try {
      await api.delete(`${types}/${obj.id}`);
      setObjectToDelete(false);
      if (type === "Date") {
        NotificationManager.success(
          `${obj?.date} successfully deleted.`,
          "Success",
          1000
        );
      } else {
        NotificationManager.success(
          `${obj?.name} successfully deleted.`,
          "Success",
          1000
        );
      }
      switch (type) {
        case "Date":
          return (
            getDates(),
            getShuttleDates(),
            getShuttleRoutes(),
            setVariables({
              ...chosenVariables,
              event_date_id: "",
              shuttle_id: "",
              route_id: "",
              place_id: "",
            })
          );
        case "Event":
          return getEvents();
        case "Shuttle":
          return (
            getShuttles(),
            getShuttleDates(),
            getShuttleRoutes(),
            setVariables({
              ...chosenVariables,
              shuttle_id: "",
              route_id: "",
              place_id: "",
            })
          );
        case "Route":
          return (
            getRoutes(), getShuttleDates(), getShuttleRoutes()
            // fix autoformat error
          );
        case "Location":
          return getPlaces();
        default:
          break;
      }
    } catch (error) {
      if (type === "Date") {
        if (error?.response?.status === 500) {
          NotificationManager.error(
            `${
              obj.date
            } cannot be deleted. There is already data linked to this ${type.toLowerCase()}.`,
            "Error",
            5000
          );
        } else {
          NotificationManager.error(
            `Something went wrong. ${obj.date} not deleted.`,
            "Error",
            5000
          );
        }
      } else {
        if (error?.response?.status === 500) {
          NotificationManager.error(
            `${
              obj.name
            } cannot be deleted. There is already data linked to this ${type.toLowerCase()}.`,
            "Error",
            5000
          );
        } else {
          NotificationManager.error(
            `Something went wrong. ${obj.name} not deleted.`,
            "Error",
            5000
          );
        }
      }
    }
  };

  const handleShowDeleteModal = (obj) => {
    setObjectToDelete(obj);
  };

  const deleteModal = (obj, type, types) => {
    return (
      <div className="modal">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">Delete {type}</Typography>
          <div>
            <Close
              className="route_icon"
              onClick={() => setObjectToDelete(false)}
              style={{ marginTop: "0", color: "black" }}
            />
          </div>
        </div>
        <p style={{ textAlign: "center" }}>
          Are you sure you want to delete this {type.toLowerCase()}?
        </p>
        <p style={{ textAlign: "center" }}>
          {obj.name} {obj.date}
        </p>
        <div className="submit_button" style={{ textAlign: "center" }}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => deleteType(obj, type, types)}
          >
            Delete
          </Button>
        </div>
      </div>
    );
  };

  return {
    deleteModal,
    objectToDelete,
    setObjectToDelete,
    handleShowDeleteModal,
  };
};

export default ConfirmDelete;
