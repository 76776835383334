import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Typography,
  Checkbox,
  FormControlLabel,
  checkboxClasses,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useApi from "../api/api";
import { NotificationManager } from "react-notifications";
import { Close } from "@mui/icons-material";

const AssignmentDetailsEditForm = ({
  driver,
  setDriverToEdit,
  setShowAssignmentDetailsEditForm,
  driverChanges,
  setDriverChanges,
  vehicle,
  setVehicleToEdit,
  vehicleChanges,
  setVehicleChanges,
  assignmentToEdit,
  getAssignments,
}) => {
  const api = useApi();
  const theme = createTheme();

  const [isLocal, setIsLocal] = useState(driver.isLocal);
  const handleIsLocalChange = () => {
    setIsLocal(!isLocal);
    setDriverChanges(true);
  };

  const [isAda, setIsAda] = useState(vehicle.isAda);
  const handleIsAdaChange = () => {
    setIsAda(!isAda);
    setVehicleChanges(true);
  };

  const handleDriverChange = ({ target: { id, value } }) => {
    setDriverToEdit({
      ...driver,
      [id]: value,
    });
    setDriverChanges(true);
  };

  const handleVehicleChange = ({ target: { id, value } }) => {
    setVehicleToEdit({
      ...vehicle,
      [id]: value,
    });
    setVehicleChanges(true);
  };

  const handleSubmit = async (e) => {
    driver.isLocal = isLocal;
    vehicle.isAda = isAda;
    e.preventDefault();
    if (driverChanges) {
      try {
        await api.put(`drivers/${driver.id}`, driver);
        NotificationManager.success(
          `Driver successfully updated.`,
          "Success",
          1000
        );
        setDriverChanges(false);
        if (!vehicleChanges) {
          setShowAssignmentDetailsEditForm(false);
          getAssignments();
        }
      } catch (error) {
        if (error?.response?.data?.errors?.name) {
          NotificationManager.error(
            `${driver.name} already exists in database.`,
            "Error",
            5000
          );
        } else if (error?.response?.data?.errors?.number) {
          NotificationManager.error(
            `${vehicle.company} ${vehicle.number} already exists in database.`,
            "Error",
            5000
          );
        } else {
          NotificationManager.error(
            "Something went wrong. Driver not updated.",
            "Error",
            5000
          );
        }
      }
    }
    if (vehicleChanges) {
      try {
        await api.put(`vehicles/${vehicle.id}`, vehicle);
        NotificationManager.success(
          `Vehicle successfully updated.`,
          "Success",
          1000
        );
        setShowAssignmentDetailsEditForm(false);
        getAssignments();
      } catch (error) {
        if (error?.response?.data?.errors?.number) {
          NotificationManager.error(
            `${vehicle.company} ${vehicle.number} already exists in database.`,
            "Error",
            5000
          );
        } else {
          NotificationManager.error(
            "Something went wrong. Vehicle not updated.",
            "Error",
            5000
          );
        }
      }
    }
  };

  const handleClose = () => {
    setShowAssignmentDetailsEditForm(false);
    setDriverChanges(false);
    setVehicleChanges(false);
  };

  return (
    <div
      className="modal"
      style={{ borderColor: assignmentToEdit.route.color }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">Edit Driver Info</Typography>
        <div>
          <Close
            className="route_icon"
            onClick={handleClose}
            style={{ marginTop: "0", color: "black" }}
          />
        </div>
      </div>
      <form className="drop_downs" onSubmit={handleSubmit}>
        <ThemeProvider theme={theme}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputLabel>Name:</InputLabel>
              <TextField
                variant="standard"
                id="name"
                fullWidth
                required
                onChange={handleDriverChange}
                value={driver.name}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Phone Number:</InputLabel>
              <TextField
                variant="standard"
                id="phone_number"
                fullWidth
                required
                onChange={handleDriverChange}
                value={driver.phone_number}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Notes (optional):</InputLabel>
              <TextField
                variant="standard"
                id="notes"
                fullWidth
                onChange={handleDriverChange}
                value={driver.notes ?? ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isLocal}
                    onChange={handleIsLocalChange}
                    sx={{
                      [`&, &.${checkboxClasses.checked}`]: {
                        color: "#135413",
                      },
                    }}
                  />
                }
                label="Local driver?"
              />
            </Grid>
            <div style={{ margin: "15px 0 -15px 24px" }}>
              <Typography variant="h5">Edit Vehicle Info</Typography>
            </div>
            <Grid item xs={12}>
              <InputLabel>Company:</InputLabel>
              <TextField
                variant="standard"
                id="company"
                fullWidth
                required
                onChange={handleVehicleChange}
                value={vehicle.company}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Number:</InputLabel>
              <TextField
                variant="standard"
                id="number"
                fullWidth
                required
                onChange={handleVehicleChange}
                value={vehicle.number}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Type (optional):</InputLabel>
              <TextField
                variant="standard"
                id="kind"
                fullWidth
                onChange={handleVehicleChange}
                value={vehicle.kind}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Capacity (optional):</InputLabel>
              <TextField
                variant="standard"
                id="capacity"
                fullWidth
                onChange={handleVehicleChange}
                value={vehicle.capacity}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAda}
                    onChange={handleIsAdaChange}
                    sx={{
                      [`&, &.${checkboxClasses.checked}`]: {
                        color: "#135413",
                      },
                    }}
                  />
                }
                label="ADA vehicle?"
              />
            </Grid>
          </Grid>
        </ThemeProvider>
        <div className="submit_button" style={{ textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              !driver.name ||
              driver.phone_number.length < 10 ||
              !vehicle.company ||
              !vehicle.number ||
              (!driverChanges && !vehicleChanges)
            }
          >
            Update
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AssignmentDetailsEditForm;
