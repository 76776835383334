import { useMemo, useState } from "react";
import axios from "axios";
import BaseUrl from "./baseurl";

function useAPI() {
  const baseUrl = BaseUrl();
  const [loginToken] = useState(localStorage.getItem("tokens"));
  const http = useMemo(
    () =>
      axios.create({
        baseURL: baseUrl,
        headers: {
          Authorization: `Token ${loginToken}`,
        },
      }),
    [loginToken, baseUrl],
  );
  return http;
}

export default useAPI;
